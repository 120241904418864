@import '../../../styles/variables';

$scale: 0.6;
$fontSize: 16px;
$ribbonColor: rgb(250, 240, 240);
  
  .recentpdf-li:hover {
    &.light{
        background-color: $lightHover;
    }
    &.dark{
        background-color: $darkHover;
    }
    -webkit-transition: background-color 100ms linear;
    -ms-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
  }
  .recentpdf-li {
    // margin-bottom: 12px;
    text-align: center;
    cursor: pointer;
    display: inline-block;
    width: 210px * $scale + 40px;
    // border:1px solid orange;
    position: relative;
    &.light{
        background-color: $homeSidebarColor;
    }
    &.dark{
        background-color: $darkHomeSidebarColor;
    }
    padding: 10px;
    margin:10px;
    border-radius: 10px;
    
    .img-coverPage {
        margin: 0 auto;
        width: 210px * $scale;
        height: 290px * $scale;
        object-fit: contain;
        background-color: transparent;
        align-content: center;
        margin-top: 5px;
        margin-bottom: 15px;
        position: relative;

        // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.062);

        .new-cover{
            width: 200px * $scale;
            height: 270px * $scale;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    
    }

    .default-coverPage{
        background-color: white;
        color: transparent;
        text-decoration: none;
    }

    .thumbnail-progress {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -60px 0 0 -45px;
    }

    .cloud-banner-container{
        background: linear-gradient(135deg, $ribbonColor 0%, $ribbonColor 50%, transparent 50%);
        width: 36px;
        height: 36px;
        position: absolute;
        margin-top: 15px;
        margin-left: 10px;
        // box-shadow: $boxShadow;

        .cloud-banner{
            height: 18px;
            width: 18px;
            position: absolute;
            left: 2px;
    
        }
    }

    
    .doc-title{
        text-align: center;
        // overflow: hidden;
        width: 85%;
        margin: 0 auto;
        // max-width: fit-content;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        text-decoration: none;
    }

  }
.newpdf{
        background-color: transparent;
    }

.newpdf:hover{
    background-color: transparent;
}

.more{
    width: 16px;
    height: 16px;
    display: inline;
}

.uploads-container{
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: $boxShadow;
    margin-bottom: 10px;
    text-align: left;

    &.dark{
        background-color: $darkDesklampGrey;
    }
    &.light{
        background-color: $clipboardColor;
    }

    .closePopup-icon{
        height: 14px;
        width: 14px;
        position: absolute;
        right: 5px;
        padding:2px;
        border-radius: 50%;
        &:hover{
            &.dark{
                background-color: $darkHover

            }
            &.light{
                background-color: $lightHover;
            }
        }

    }

}

.upload-header{
    margin-left: 5px;
    font-weight: bold;
    margin-bottom: 15px;

    // background-color: blue;
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
}

.progress-fileName{
    font-size: 14px;
    display: inline-block;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 3px;
    width: 150px;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.progress-bar-container{
    width:100px;
    margin-left: 10px; 
    &.app{
        width: 100%;
        margin-left: 0px;
    }
    height:12px;
    background-color:lightgrey;
    display:inline-block;
    border-radius: 3px;
       

    .progress-bar-fill{
        height:100%;
        color:transparent;
        &.dark{
            background-color: $darkDesklampGreen;
        }
        &.light{
            background-color: $lightDesklampGreen;
        }
        font-size: 12px;    
        border-radius: 3px;
        
    }

    

}

.percentage{
    margin-left: 5px;
}
.upload-done-icon{
    width: 14px;
    height: 14px;
    margin-left: 4px;
}

//----------------TAG----------------------

.tag-container{
    width: fit-content;
    display: inline-block;
    margin-right: 10px;
    // border-bottom: 1px solid black;
    // display: flex;
    // align-items: center;
    padding: 5px;
    cursor: pointer;
    .logo-container{
        // text-align: center;
        .tag-logo{
            margin: 0 auto;
            width: 100px;
            height: 100px;
            display: block;
        }
    }

    .tag-label-container{
        display: flex;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .tag-title{
            margin-left: 10px;
            padding: 5px;
        }

        .more{
            vertical-align: middle;
        }
    }
}

//--------------------------------------------