/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.findArrow-zoomControl {
  width: 8px;
  height: 8px;
}

.zoom-input {
  width: 40px;
  cursor: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.zoom-input.dark {
  color: white;
}
.zoom-input.light {
  color: black;
}

.zoom-dropdown {
  margin-left: -40px;
}

.zoom-input-dropdown-container {
  padding-left: 5px;
  padding-right: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.zoom-dropdown-input.dark {
  color: white;
}
.zoom-dropdown-input.dark::-moz-placeholder {
  color: rgb(219, 219, 219);
}
.zoom-dropdown-input.dark:-ms-input-placeholder {
  color: rgb(219, 219, 219);
}
.zoom-dropdown-input.dark::placeholder {
  color: rgb(219, 219, 219);
}
.zoom-dropdown-input.light {
  color: black;
}
.zoom-dropdown-input.light::-moz-placeholder {
  color: rgb(36, 36, 36);
}
.zoom-dropdown-input.light:-ms-input-placeholder {
  color: rgb(36, 36, 36);
}
.zoom-dropdown-input.light::placeholder {
  color: rgb(36, 36, 36);
}

.zoom-rule {
  color: white;
  border: none;
  border-top: 1px solid white;
  margin-left: 10px;
  margin-right: 10px;
}

.zoom-option-label.dark {
  color: white;
}
.zoom-option-label.light {
  color: black;
}

.zoom-option-shortcut.dark {
  color: white;
}
.zoom-option-shortcut.light {
  color: black;
}

.zoom-option {
  margin-top: 4px;
  margin-bottom: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.zoom-option:hover.dark {
  background-color: #4e4e58;
}
.zoom-option:hover.light {
  background-color: #BAB6F5;
}
.zoom-option .zoom-option-label {
  float: left;
}
.zoom-option .zoom-option-shortcut {
  float: right;
}/*# sourceMappingURL=ZoomController.css.map */