.documentDeletedPageBigContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vw;
}

.documentDeletedPageContainer{

    &.dark{
        color: white;
    }


    .noAccess_header{
        font-size: 24px;
    }

    .noAccess_icon{
        width: 128px;
        height: 128px;
        margin: 10px;
    }

    .noAccess_message{
        margin: 30px;
        font-size: 18px;

    }


}