/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.recentpdf-li:hover {
  transition: background-color 100ms linear;
}
.recentpdf-li:hover.light {
  background-color: #BAB6F5;
}
.recentpdf-li:hover.dark {
  background-color: #4e4e58;
}

.recentpdf-li {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  width: 166px;
  position: relative;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}
.recentpdf-li.light {
  background-color: #e6e5ee;
}
.recentpdf-li.dark {
  background-color: #212231;
}
.recentpdf-li .img-coverPage {
  margin: 0 auto;
  width: 126px;
  height: 174px;
  -o-object-fit: contain;
     object-fit: contain;
  background-color: transparent;
  align-content: center;
  margin-top: 5px;
  margin-bottom: 15px;
  position: relative;
}
.recentpdf-li .img-coverPage .new-cover {
  width: 120px;
  height: 162px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.recentpdf-li .default-coverPage {
  background-color: white;
  color: transparent;
  text-decoration: none;
}
.recentpdf-li .thumbnail-progress {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -60px 0 0 -45px;
}
.recentpdf-li .cloud-banner-container {
  background: linear-gradient(135deg, rgb(250, 240, 240) 0%, rgb(250, 240, 240) 50%, transparent 50%);
  width: 36px;
  height: 36px;
  position: absolute;
  margin-top: 15px;
  margin-left: 10px;
}
.recentpdf-li .cloud-banner-container .cloud-banner {
  height: 18px;
  width: 18px;
  position: absolute;
  left: 2px;
}
.recentpdf-li .doc-title {
  text-align: center;
  width: 85%;
  margin: 0 auto;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-decoration: none;
}

.newpdf {
  background-color: transparent;
}

.newpdf:hover {
  background-color: transparent;
}

.more {
  width: 16px;
  height: 16px;
  display: inline;
}

.uploads-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  margin-bottom: 10px;
  text-align: left;
}
.uploads-container.dark {
  background-color: #313941;
}
.uploads-container.light {
  background-color: #e6e5ee;
}
.uploads-container .closePopup-icon {
  height: 14px;
  width: 14px;
  position: absolute;
  right: 5px;
  padding: 2px;
  border-radius: 50%;
}
.uploads-container .closePopup-icon:hover.dark {
  background-color: #4e4e58;
}
.uploads-container .closePopup-icon:hover.light {
  background-color: #BAB6F5;
}

.upload-header {
  margin-left: 5px;
  font-weight: bold;
  margin-bottom: 15px;
}

.progress-fileName {
  font-size: 14px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 0px;
  margin-bottom: 3px;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-bar-container {
  width: 100px;
  margin-left: 10px;
  height: 12px;
  background-color: lightgrey;
  display: inline-block;
  border-radius: 3px;
}
.progress-bar-container.app {
  width: 100%;
  margin-left: 0px;
}
.progress-bar-container .progress-bar-fill {
  height: 100%;
  color: transparent;
  font-size: 12px;
  border-radius: 3px;
}
.progress-bar-container .progress-bar-fill.dark {
  background-color: #59ffa0;
}
.progress-bar-container .progress-bar-fill.light {
  background-color: #7ac74f;
}

.percentage {
  margin-left: 5px;
}

.upload-done-icon {
  width: 14px;
  height: 14px;
  margin-left: 4px;
}

.tag-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  margin-right: 10px;
  padding: 5px;
  cursor: pointer;
}
.tag-container .logo-container .tag-logo {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: block;
}
.tag-container .tag-label-container {
  display: flex;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tag-container .tag-label-container .tag-title {
  margin-left: 10px;
  padding: 5px;
}
.tag-container .tag-label-container .more {
  vertical-align: middle;
}/*# sourceMappingURL=Accessories.css.map */