/* Copyright 2014 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */


.textLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 1;
  line-height: 1;
  mix-blend-mode: multiply;
  z-index: 0;
}

/*
ATTEMPTED FIX FOR PDFJS SCROLL PROBLEM ON FIND OPTION
*/
/* .markedContent {
  overflow: hidden;
} */

.annotationLayer{
  z-index:5;
}

.canvasWrapper {
  z-index: 1;
  mix-blend-mode: normal;
}

.textLayer span {
  z-index: 2;
}

.textLayer br{
    display: none;
}

.textLayer  div {height:200px;}


.textLayer .highlight {
    position: relative;
    margin: -1px;
    padding: 1px;
    background-color: rgba(180, 0, 170, 1);
    opacity: 0.2;
    border-radius: 4px;
  }
  
  .textLayer .highlight.begin {
    border-radius: 4px 0 0 4px;
  }
  
  .textLayer .highlight.end {
    border-radius: 0 4px 4px 0;
  }
  
  .textLayer .highlight.middle {
    border-radius: 0;
  }
  
  .textLayer .highlight.selected {
    background-color: rgba(0, 100, 0, 1);
  }

.textLayer ::-moz-selection {
  background: lightskyblue;
  filter: var(--reader-stheme);
}

.textLayer ::selection {
  background: lightskyblue;
  filter: var(--reader-stheme);
}

.pdfViewer.removePageBorders .page {
  margin: 0px auto 10px auto;
  border: none;
}

.page canvas {
  filter: var(--reader-theme);
}

.pdfViewer {
  background-color: var(--reader-bgcol);
}

.pdfViewer .page .loadingIcon {
  background:  url("../icons/loading_new.gif") center no-repeat;;
  background-size: 250px 250px;
  background-color: var(--reader-bgcol);
}
