.separator {
    color:transparent;
    width: 10px;
    height: 100%;
    z-index: 4;
    float:left;
    /* position: absolute; */
    
}

.separator:hover {
    cursor: col-resize;
}

