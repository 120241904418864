// Purples
$lightPurple: #e0e7ff;
$purple: #665aef;
$mediumPurple: #c7cafc;
$darkPurple: #4f46e5;

$indigo200: #c7d2fe;
$indigo700: #4338ca;

//Greys
$darkestGrey: #303030 ;
$darkerGrey: #4b4b4b ;
$darkGrey: #727272;
$mediumGrey: #c7c7c7 ;

//Reds
$darkModeRed:#ffa69e;
$lightModeRed:#db5461;

//Greens
$darkModeGreen:#59ffa0;
$lightModeGreen:#3a9434;


//Filters
$filter_lightModeRed: invert(42%) sepia(75%) saturate(708%) hue-rotate(310deg) brightness(103%) contrast(98%);
