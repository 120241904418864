@import "../../styles/variables";

.postIt {
    position: absolute;
    cursor: pointer;

}

.flexContainer {
    flex-direction: column;
    align-items: stretch;
    // padding: 10px;
    &.shadow{
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);

    }


}

.selectionHandle{
    position: relative;
    // background-color: #494c52;
    z-index: 2;
    // display: none;
    // float: right;
    cursor: all-scroll;
    // border: 1px solid blue;
    .selectionhandle-icon{
        transform: rotate(90deg);
        position: absolute;
        cursor: pointer;
        opacity:0.5;
        &:hover{
            // background-color: rgba(255, 255, 255, 0.452);
            opacity: 1;
        }
    }
}

.rectangle{
    cursor: pointer;
}

.commentBox{
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 1;
    padding-top: 0px;
    resize: none;
    mix-blend-mode: normal;
    border: none;
    // border: 1px solid black;
}

.commentBox:focus{
    outline: none;
}

.commentBox::-webkit-scrollbar {
    height:8px;
    width:9px;
  }
  
  /* Track */
 .commentBox::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  .commentBox::-webkit-scrollbar-thumb {
    background: darkgrey; 
    width:2px;
    border-radius:4px;
  }
  
  /* Handle on hover */
  .commentBox::-webkit-scrollbar-thumb:hover {
    background:rgb(92, 92, 92); 
  }

