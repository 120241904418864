@import "../../styles/variables";

.findArrow-zoomControl{
    width: 8px;
    height: 8px;
}

.zoom-input{
    &.dark{
        color: white;
    }
    &.light{
        color: black;
    }
    width: 40px;
    cursor: auto;
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.zoom-dropdown{
    margin-left:-40px;

}

.zoom-input-dropdown-container{
    padding-left:5px;
    padding-right:5px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.zoom-dropdown-input{
    &.dark{
        color: white;
        &::placeholder{
            color:rgb(219, 219, 219)
        }
    }
    &.light{
        color: black;
        &::placeholder{
            color:rgb(36, 36, 36)
        }
    }
}

.zoom-rule{
    color:white;
    border:none;
    border-top:1px solid white;
    margin-left:10px;
    margin-right:10px;
}

.zoom-option-label{
    &.dark{
        color: white;
    }
    &.light{
        color: black;
    }
}

.zoom-option-shortcut{
    &.dark{
        color: white;
    }
    &.light{
        color: black;
    }
}

.zoom-option{
    margin-top:4px;
    margin-bottom:4px;
    padding-left:20px;
    padding-right:20px;

    &:hover{
        &.dark{
            background-color: $darkHover;
        }
        &.light{
            background-color: $lightHover;
        }
    }
    .zoom-option-label{
        float: left;
    }
    .zoom-option-shortcut{
        float:right;
        // color:rgb(197, 197, 197);
    }
}
