/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.lookup-container {
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 10px;
}

.contents-container {
  padding: 3px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.lookup-box {
  overflow-y: auto;
  overflow-x: none;
  padding-top: 4px;
  padding-left: 10px;
}
.lookup-box.dark {
  background-color: #212231;
}
.lookup-box.light {
  background-color: #e6e5ee;
}
.lookup-box.none.top {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.lookup-box.left.top {
  border-top-right-radius: 5px;
}

.lookup-triangle {
  height: 0px;
  width: 0px;
  z-index: inherit;
  position: relative;
  border: 15px solid;
  border-left-color: transparent;
  border-right-color: transparent;
}
.lookup-triangle.top {
  border-bottom-color: transparent;
}
.lookup-triangle.top.dark {
  border-top-color: #212231;
}
.lookup-triangle.top.light {
  border-top-color: #e6e5ee;
}
.lookup-triangle.bottom {
  border-top-color: transparent;
}
.lookup-triangle.bottom.dark {
  border-bottom-color: #212231;
}
.lookup-triangle.bottom.light {
  border-bottom-color: #e6e5ee;
}
.lookup-triangle.left {
  left: 0%;
}
.lookup-triangle.none {
  left: 45%;
}

.lookup-text {
  font-size: 14px;
  margin-bottom: 3px;
}
.lookup-text.dark {
  color: #f2f2f7;
}
.lookup-text.light {
  color: #34353f;
}

.lookup-button {
  margin: 0px auto;
  background-color: transparent;
}
.lookup-button.dark {
  color: #f2f2f7;
}
.lookup-button.light {
  color: #34353f;
}
.lookup-button:hover {
  text-decoration: underline;
  cursor: pointer;
}
.lookup-button.activeButton {
  font-weight: bold;
}

.lookup-button-container {
  padding-bottom: 5px;
  display: flex;
}
.lookup-button-container.none.bottom {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.lookup-button-container.left.bottom {
  border-bottom-right-radius: 5px;
}
.lookup-button-container.dark {
  background-color: #212231;
}
.lookup-button-container.light {
  background-color: #e6e5ee;
}

.lookup-load {
  position: relative;
  top: 50%;
  height: 50px;
  width: 50px;
}

.lookup-section-header {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 2px;
  font-weight: bolder;
}

.lookup-section-content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 2px;
}

.loading-container {
  position: relative;
  top: 40%;
  margin: 0px auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: block;
  align-items: center;
  justify-content: center;
}

.lookup-hr {
  position: relative;
  top: 0%;
  width: 100%;
  height: 1px;
  border: none;
  margin-bottom: 5px;
}
.lookup-hr.dark {
  background-color: #f2f2f7;
}
.lookup-hr.light {
  background-color: #34353f;
}
.lookup-hr.sub {
  opacity: 0.3;
}

.lookup-definition-pos {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 6px;
}

.lookup-synonym {
  padding-right: 2px;
}

.lookup-word {
  font-size: 18px;
  display: inline;
}

.lookup-meaning-subtitle {
  padding-right: 3px;
}

.meaning-container {
  margin-left: 10px;
}

.minibold {
  font-weight: bold;
  font-style: italic;
}

.lookup-goto-button {
  height: 10px;
  width: 10px;
  font-size: 14px;
}
.lookup-goto-button.dark {
  color: #f2f2f7;
}
.lookup-goto-button.light {
  color: #34353f;
}

.phonetic {
  font-size: 14px;
  margin-left: 10px;
}
.phonetic.light {
  color: #313941;
}
.phonetic.dark {
  color: #C4C4C4;
}

.lookup-box::-webkit-scrollbar {
  height: 8px;
  width: 5px;
}

/* Track */
.lookup-box::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.lookup-box::-webkit-scrollbar-thumb {
  background: darkgrey;
  width: 2px;
  border-radius: 4px;
}

/* Handle on hover */
.lookup-box::-webkit-scrollbar-thumb:hover {
  background: #5c5c5c;
}

.oops-message {
  padding-top: 20px;
  font-size: 16px;
}
.oops-message.dark {
  color: #444758;
}
.oops-message.light {
  color: #D8D5FA;
}

.oops-image {
  position: relative;
  left: 30%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100px;
  width: 100px;
}

.oops-container {
  position: relative;
  margin-top: 50px;
}

.wiki-word {
  margin-bottom: 5px;
}/*# sourceMappingURL=Lookup.css.map */