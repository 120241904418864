/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.auth-logo {
  width: auto;
  position: absolute;
  top: 60px;
}

.dynamic-form {
  transition: 0.5s;
}

.auth-screen {
  height: 100vh;
}
.auth-screen.row:after {
  content: "";
  display: table;
  clear: both;
}
.auth-screen .column {
  height: 100%;
  background-color: #f2f2f7;
}

.auth-form-column {
  display: flex;
  align-items: center;
}
.auth-form-column .authform-container {
  margin-bottom: 0px;
  min-width: 65%;
  z-index: 2;
  position: relative;
}

.form {
  margin-top: 40px;
}

.list {
  list-style: none;
}

.list-container {
  list-style: none;
  height: 0;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.list-container.show:not(:first-child) {
  margin-top: 10px;
}

.list-container .list-item {
  padding: 2rem 0;
  width: 100%;
  background-color: lightgray;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 0.6s ease-out;
}

.list-container .list-item.show {
  opacity: 1;
}

.info-panel {
  position: absolute;
  height: 100vh;
  width: 600px;
  right: 0px;
  top: 0px;
  background-color: #e0e7ff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  transition: 0.8s;
}
.info-panel.off-screen {
  transform: translateX(700px);
}
.info-panel .info-panel-container {
  padding: 60px;
}
.info-panel .info-icon {
  margin-bottom: 20px;
}

@media only screen and (max-width: 99999px) {
  .auth-graphic-column {
    width: 60%;
  }
  .auth-form-column {
    width: 40%;
  }
  .auth-screen {
    font-size: 18px;
  }
  .authform-container {
    padding: 60px;
  }
  .auth-big-logo {
    display: block;
    height: 28px;
    left: 60px;
  }
  .auth-small-logo {
    display: none;
  }
  .authform-container {
    width: 100%;
    margin-top: 60px;
  }
}
@media only screen and (max-height: 700px) {
  .auth-logo {
    display: none;
  }
  .auth-graphic-column {
    width: 50%;
  }
  .auth-form-column {
    width: 50%;
  }
}
@media only screen and (max-width: 1200px) {
  .auth-screen {
    font-size: 18px;
  }
  .authform-container {
    padding: 0 [x];
  }
  .auth-logo {
    margin: 0 auto;
    margin-bottom: 20px;
    height: 22px;
    top: 60px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .auth-screen {
    text-align: center;
  }
  .auth-graphic-column {
    display: none;
  }
  .auth-form-column {
    width: 100%;
    text-align: center;
  }
  .authform-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 0px;
    width: 100%;
    margin-top: 60px;
  }
}
@media only screen and (max-width: 600px) {
  .auth-screen {
    font-size: 14px;
  }
  .authform-container {
    width: 80%;
    margin-top: 0px;
  }
}
.draggable-handle-auth {
  -webkit-app-region: drag;
  height: 100px;
  width: 100%;
  position: absolute;
  top: 0px;
}/*# sourceMappingURL=AuthScreen.css.map */