/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
button {
  background-color: transparent;
  border: none;
  outline: none;
}

.connection-banner {
  position: absolute;
  z-index: 15;
  height: 27px;
  bottom: 0px;
  width: 100%;
  color: white;
  margin-bottom: 0px;
  margin-top: 2px;
  padding-top: 9px;
  text-align: center;
}

.message-container {
  position: relative;
  text-align: center;
}

p.popup-app {
  color: white;
  font-weight: bold;
  position: absolute;
  right: 50%;
  top: 45%;
  transform: 50%, -50%;
  padding: 20px;
  z-index: 200;
  border-radius: 10px;
  background-color: orange;
}

.img-close-banner {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-left: 10px;
  position: absolute;
  bottom: 6px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.295);
}

.img-close-banner:hover {
  background-color: rgba(0, 0, 0, 0.623);
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.passwordInput {
  z-index: 10;
  background-color: green;
}

.initialLoading {
  width: 100px;
  height: 100px;
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px; /* apply negative top and left margins to truly center the element */
}

html::-webkit-scrollbar {
  display: none;
}

html::-webkit-scrollbar {
  color: pink;
  background: green;
}

.title-bar {
  height: 24px;
  padding: none;
  margin: 0px;
  position: absolute;
  top: 0vw;
  width: 100vw;
}

.toolbar-profile {
  position: absolute;
  bottom: 5px;
  left: 0px;
  z-index: 9;
}

.profile-initials {
  font-weight: bold;
}
.profile-initials.dark {
  color: #f2f2f7;
}
.profile-initials.light {
  color: #34353f;
}

.profile-btn {
  left: -2px;
}

.support-toolbar-btn {
  left: 5px;
}

.frill-widget-container {
  position: absolute;
  top: 10px;
  right: 5px;
}

.main-loading.dark {
  color: white;
}
.main-loading.light {
  color: black;
}

.circular-progress-container {
  position: fixed;
  top: 35%;
  left: 45%;
}

html.purple {
  background: #665aef;
  background-repeat: no-repeat;
  height: 100%;
}/*# sourceMappingURL=App.css.map */