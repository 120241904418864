/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
@-webkit-keyframes newlocmark_regular {
  0% {
    width: 70px;
  }
  90% {
    width: 70px;
  }
  100% {
    width: 50px;
  }
}
@keyframes newlocmark_regular {
  0% {
    width: 70px;
  }
  90% {
    width: 70px;
  }
  100% {
    width: 50px;
  }
}
@-webkit-keyframes newlocmark_collapsed {
  0% {
    width: 30px;
  }
  90% {
    width: 30px;
  }
  100% {
    width: 5px;
  }
}
@keyframes newlocmark_collapsed {
  0% {
    width: 30px;
  }
  90% {
    width: 30px;
  }
  100% {
    width: 5px;
  }
}
.locationmarkers-container {
  margin-top: 55px;
  z-index: 10;
}

.locationMarkerRibbon {
  text-align: left;
  background-color: #BAB6F5;
  padding-right: 20px;
  transition: width 0.3s;
  display: flex;
  flex-wrap: none;
  margin-bottom: 2px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  overflow-x: hidden;
}

.short-locMarkRibbon {
  width: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: none;
}
.short-locMarkRibbon.created-now {
  -webkit-animation-name: newlocmark_regular;
          animation-name: newlocmark_regular;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

.collapsed-locMarkRibbon {
  max-height: 22px;
  width: 5px;
  color: transparent;
  overflow: hidden;
}
.collapsed-locMarkRibbon.created-now {
  -webkit-animation-name: newlocmark_collapsed;
          animation-name: newlocmark_collapsed;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

.short-locMarkLabel {
  width: 30px;
}

.unlocked-active {
  background-color: #0CCE6B;
}

.unlocked-inactive {
  background-color: #FFB30F;
}

.typing {
  background-color: rgba(62, 62, 165, 0.616);
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  color: white;
}

.locMark-span {
  cursor: pointer;
}

.dead {
  cursor: pointer;
}

.locationMarkerRibbon:hover .locMarkButton {
  display: inline;
}

.locMark-label {
  text-align: left;
  font-weight: bold;
  margin-right: 2px;
  font-size: 15px;
  width: 50px;
  background-color: transparent;
  color: black;
}

.hide-locMarkLabel {
  color: transparent;
}

.locMarkButton {
  width: 13px;
  height: 13px;
  float: right;
  display: none;
  border-radius: 50%;
  padding: 3px;
}

.locMarkButton:hover {
  background-color: #f2f2f7;
}

.widthCalculator {
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
  white-space: nowrap;
}

.locMarkButton-pin {
  margin-left: 5px;
}/*# sourceMappingURL=LocationMarkers.css.map */