
@import '../../styles/colors';

.text {
    // font-family: 'Poppins';

    a{
        text-decoration: none;
        font-family: 'Poppins-Bold';
        color: $purple;
        cursor: pointer;

    }

    b{
        color: $purple;
        font-family: 'Poppins-Bold';
    }

    &.xs{
        font-size: 0.5em;
    }
    &.s{
        font-size: 0.85em;

        
    }
    &.l{
        font-size: 1.25em
    }
    &.xl{
        font-size: 1.5em
    }

    &.success{
        color: $lightModeGreen;
    }

    &.error{
        color: $lightModeRed
    }


}

.title{
    font-size: 2.0em; //md font-size
    font-family: 'Poppins-Medium';
    margin: 10px 0;

    .title-punctuation{
        color: $purple;
    }

}



.subtitle{
    font-family: 'Poppins';
    font-size: 1.0em;
    margin-top: 15px;
}

.caption{
    font-family: 'Poppins';

    font-size: 0.75em;
    color: $darkGrey;
    margin-top: 15px;

    a{
        text-decoration: underline;
        font-family: 'Poppins';
        color: $darkGrey;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;  
    }
}

