/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.nb-header {
  z-index: 2;
  width: 100%;
  max-height: -webkit-max-content;
  max-height: -moz-max-content;
  max-height: max-content;
  border-radius: 5px;
}
.nb-header.light {
  background-color: #e6e5ee;
}
.nb-header.dark {
  background-color: #212231;
}
.nb-header .nb-titlebar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.nb-header .nb-titlebar .nb-menu-icon {
  margin-left: 15px;
  cursor: pointer;
  width: 16px;
  height: 10.8px;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  left: 15px;
}
.nb-header .nb-titlebar .nb-menu-icon.light {
  background-color: #BAB6F5;
}
.nb-header .nb-titlebar .nb-menu-icon.dark {
  background-color: #4e4e58;
}
.nb-header .nb-titlebar .nb-public-icon {
  position: absolute;
  right: 30px;
  cursor: pointer;
  width: 11.25px;
  height: 11.25px;
  padding: 7px;
  border-radius: 5px;
}
.nb-header .nb-titlebar .nb-public-icon.light.private {
  background-color: #BAB6F5;
}
.nb-header .nb-titlebar .nb-public-icon.light.public {
  background-color: #78BC61;
}
.nb-header .nb-titlebar .nb-public-icon.dark.private {
  background-color: #4e4e58;
}
.nb-header .nb-titlebar .nb-public-icon.dark.public {
  background-color: green;
}
.nb-header.notebook-frozenTopbar {
  top: 40px;
  padding-top: 15px;
}
.nb-header.notebook-unfrozenTopbar {
  padding-top: 15px;
}

.request-bar {
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
}
.request-bar .editor-request {
  text-decoration: underline;
  margin-left: 5px;
}
.request-bar .editor-request.light {
  color: black;
}
.request-bar .editor-request.dark {
  color: white;
}
.request-bar.light {
  color: black;
  background-color: #f2f2f7;
}
.request-bar.dark {
  color: white;
  background-color: #34353f;
}

.request-box {
  position: absolute;
  z-index: 100;
  top: 20px;
}
.request-box.dark {
  background-color: white;
}

.popup-menu {
  z-index: 2;
  display: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  border-radius: 5px;
  padding-left: 15px;
  position: fixed;
  right: 10px;
  padding-right: 15px;
  margin-top: 10px;
  margin-right: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
}
.popup-menu.light {
  background-color: #f2f2f7;
}
.popup-menu.dark {
  background-color: #34353f;
}/*# sourceMappingURL=NotebookWorkspace.css.map */