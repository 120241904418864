@import "../../styles/variables";
$fontSize:15px;
// $markerHeight:15px;
$textboxColor:rgba(62, 62, 165, 0.616);
$imgSize:13px;
$regularWidth: 30px;
$shortWidth:10px;
$ribbonRadius:6px;

@keyframes newlocmark_regular {
    0%   {width: $regularWidth+40px;}
    90%   {width: $regularWidth+40px;}
    100% {width: $regularWidth+20px;}
}

@keyframes newlocmark_collapsed {
    0%   {width: $regularWidth}
    90%   {width: $regularWidth}
    100% {width: 5px}
}


.locationmarkers-container{
    margin-top:$topmenuHeight +15px;
    z-index:10;
}


.locationMarkerRibbon{
    text-align: left;
    background-color: $ribbonColor;
    padding-right:20px;
    transition:width 0.3s;
    display:flex;
    flex-wrap: none;
    margin-bottom:2px;
    border-top-right-radius:$ribbonRadius;
    border-bottom-right-radius:$ribbonRadius;
    
    padding-top:6px;
    padding-bottom:6px;
    overflow-x: hidden;  
}



.short-locMarkRibbon{
    width:$regularWidth+10;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: none;
    &.created-now{
        animation-name: newlocmark_regular;
        animation-duration: 1.5s;
    }
}

.collapsed-locMarkRibbon{
    max-height: 22px;
    width:5px;
    color:transparent;
    overflow: hidden;
    &.created-now{
        animation-name: newlocmark_collapsed;
        animation-duration: 1.5s;
    }
}

.short-locMarkLabel{
    width:$regularWidth
}

.unlocked-active{
    background-color: #0CCE6B;
}

.unlocked-inactive{
    background-color: #FFB30F;
}


.typing{
    background-color:$textboxColor;
    border-top:1px solid black;
    border-bottom:1px solid black;
    border-right:1px solid black;
    color:white;
    
}

.locMark-span{
    cursor: pointer;
}


.dead{
    cursor:pointer;
}

.locationMarkerRibbon:hover .locMarkButton{
    display:inline;
    
}

.locMark-label{
    text-align:left;
    font-weight:bold;
    margin-right:2px;
    font-size: $fontSize;
    width:$regularWidth+20;
    background-color: transparent;
    color:black
}

.hide-locMarkLabel{
    color:transparent
}

.locMarkButton{
    width:$imgSize;
    height:$imgSize;
    float: right;
    display: none;
    border-radius: 50%;
    padding:3px;
}

.locMarkButton:hover{
    background-color: $lightDesklampGrey;
}

.widthCalculator{
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
}

.locMarkButton-pin{
    margin-left: 5px
}