/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.light {
  --main-text-color: black;
}

.dark {
  --main-text-color: white;
  --check-color: $lightHover;
}

.remirror-floating-popover {
  z-index: 1;
}

.remirror-editor-wrapper {
  padding-top: 3px;
}

.remirror-editor.ProseMirror p {
  margin-bottom: 12px;
}

.remirror-editor.ProseMirror h1, .remirror-editor.ProseMirror h2 {
  margin-top: 22px;
  margin-bottom: 22px;
}

.remirror-editor.ProseMirror h3, .remirror-editor.ProseMirror h4, .remirror-editor.ProseMirror h5 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ProseMirror.remirror-editor {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 40px;
  padding-top: 0px;
  padding-bottom: 10px;
  box-sizing: border-box;
  font-family: "PT_Sans";
  line-height: initial;
}

.ProseMirror.remirror-editor {
  box-shadow: none;
}

.ProseMirror.remirror-editor.ProseMirror-focused {
  box-shadow: none;
}

.remirror-theme .ProseMirror:focus, .remirror-theme .ProseMirror:active {
  box-shadow: none;
}

.nb-header .nb-titlebar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  padding-top: 10px;
}

.remirror-toolbar button {
  border: 1px solid black;
  margin: 2px;
}

.notebook-container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: calc(100vh - 170px);
  margin-top: 15px;
  overflow: scroll;
  font-family: "PT_Sans";
}

.light .notebook-container {
  color: black;
  --dropcursor-color: black;
}

.dark .notebook-container {
  color: white;
  --dropcursor-color: white;
}

.notebook-container img {
  -webkit-user-drag: auto;
  cursor: pointer;
}

.notebook-container::-webkit-scrollbar {
  height: 8px;
  width: 9px;
  background: transparent;
}

.notebook-container::-webkit-scrollbar-track {
  border-radius: 4px;
}

.notebook-container::-webkit-scrollbar-thumb {
  background: darkgrey;
  width: 2px;
  border-radius: 4px;
}

/* Handle on hover */
.notebook-container::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}

.light .remirror-editor.ProseMirror ::-moz-selection {
  background: #B4D7FF;
  caret-color: var(--rmr-color-selection-caret);
  color: inherit;
}

.light .remirror-editor.ProseMirror ::selection, .light .remirror-editor.ProseMirror .selection {
  background: #B4D7FF;
  caret-color: var(--rmr-color-selection-caret);
  color: inherit;
}

.dark .remirror-editor.ProseMirror ::-moz-selection {
  background: #5D83AC;
  color: inherit;
}

.dark .remirror-editor.ProseMirror ::selection, .dark .remirror-editor.ProseMirror .selection {
  background: #5D83AC;
  color: inherit;
}

.remirror-collapsible-list-item-button.disabled, .remirror-collapsible-list-item-button.disabled:hover {
  background-color: var(--main-text-color);
  border: none;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  cursor: default;
}

.remirror-collapsible-list-item-button, .remirror-collapsible-list-item-button:hover {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-radius: 0%;
  border-top: 8px solid var(--main-text-color);
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  transition: none;
  background-color: transparent;
}

.remirror-collapsible-list-item-closed .remirror-collapsible-list-item-button {
  background-color: transparent;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: none;
  border-radius: 0%;
  border-left: 8px solid var(--main-text-color);
}

[data-checked] {
  text-decoration: line-through;
}

.remirror-editor input[type=checkbox] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  -moz-appearance: none;
       appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.05em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.remirror-editor input[type=checkbox]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  /* Windows High Contrast Mode */
}

.remirror-editor input[type=checkbox]:checked::before {
  transform: scale(1);
}

.remirror-editor input[type=checkbox]:disabled {
  --form-control-color: var(--form-control-disabled);
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.light .remirror-editor input[type=checkbox]::before {
  background-color: steelblue;
}

.dark .remirror-editor input[type=checkbox]::before {
  background-color: deepSkyBlue;
}

.light .remirror-list-spine {
  position: absolute;
  top: 4px;
  bottom: 0px;
  left: -20px;
  width: 16px;
  cursor: pointer;
  transition: border-left-color 0.25s ease;
  border-left-color: var(--rmr-color-border);
  border-left-style: solid;
  border-left-width: 1px;
}

.dark .remirror-list-spine {
  position: absolute;
  top: 4px;
  bottom: 0px;
  left: -20px;
  width: 16px;
  cursor: pointer;
  transition: border-left-color 0.25s ease;
  border-left-color: white;
  border-left-style: solid;
  border-left-width: 1px;
}

.remirror-editor.ProseMirror ol {
  list-style-type: decimal;
}

.remirror-editor.ProseMirror ol ol {
  list-style-type: lower-alpha;
}

.remirror-editor.ProseMirror ol ol ol {
  list-style-type: lower-roman;
}

.remirror-editor.ProseMirror ol ol ol ol {
  list-style-type: decimal;
}

.remirror-editor.ProseMirror ol ol ol ol ol {
  list-style-type: lower-alpha;
}

.remirror-editor.ProseMirror ol ol ol ol ol ol {
  list-style-type: lower-roman;
}

.light mark.internal-link {
  color: #665AEF;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.dark mark.internal-link {
  color: #BAB6F5;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.light .remirror-editor.ProseMirror h1, .light .remirror-editor.ProseMirror h2, .light .remirror-editor.ProseMirror h3, .light .remirror-editor.ProseMirror h4, .light .remirror-editor.ProseMirror h5 {
  color: black;
  font-family: "PT_Sans";
}

.dark .remirror-editor.ProseMirror h1, .dark .remirror-editor.ProseMirror h2, .dark .remirror-editor.ProseMirror h3, .dark .remirror-editor.ProseMirror h4, .dark .remirror-editor.ProseMirror h5 {
  color: white;
  font-family: "PT_Sans";
}

.light .remirror-theme a {
  color: #665AEF;
  cursor: pointer;
}

.dark .remirror-theme a {
  color: #BAB6F5;
  cursor: pointer;
}

.remirror-theme span.text-extract {
  cursor: pointer;
  border-radius: 2px;
  padding-bottom: 3px;
}

.dark span.text-extract {
  background-color: #444758;
  color: white;
}

.light span.text-extract {
  background-color: #f2f2f7;
  color: black;
}/*# sourceMappingURL=NotebookEditor.css.map */