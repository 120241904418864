.PromoCodeInfoPanel {
  font-family: "Poppins";
}

.BulletPoint {
  /* Create two equal columns that floats next to each other */
  /* Clear floats after the columns */
}
.BulletPoint .bullet-number {
  height: 16px;
  width: auto;
  vertical-align: middle;
  margin-right: 10px;
}
.BulletPoint p {
  display: inline-block;
}
.BulletPoint .col {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.BulletPoint .row:after {
  content: "";
  display: table;
  clear: both;
}/*# sourceMappingURL=PromoCode.css.map */