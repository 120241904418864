@font-face {
    font-family: 'PT_Sans';
    src: local('PT_Sans'), url('../../../common-components/src/styles/fonts/PT_Sans/PTSans-Regular.ttf');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: local('Poppins'), url(./fonts/Poppins/Poppins-Medium.ttf) format('truetype');;
}

@font-face {
    font-family: 'Poppins-Bold';
    src: local('Poppins'), url(./fonts/Poppins/Poppins-Bold.ttf) format('truetype');;
}