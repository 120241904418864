@import "../../styles/variables";
$sidebarWidth: 300px;
$iconWidth: 24px;
$fontSize: 16px;
$themeRadius:10px;

$activeColor: rgb(100, 123, 235);
$transActiveColor: #445cda;

$activeColorDark: #525252;
$transActiveColorDark: transparentize($activeColorDark, 0.6);

.page-heading{
    font-weight: bold;
    font-size: 24px;
    display: block;
    padding-top: 10px;
}

.page-description{
    font-size: 20px;
    display: block;
    padding-top: 10px;
}

.selector{
    font-size: larger;
}

.return-page-container{
  position: relative;
  &.loading-home-container{
      height: 100vh;
  }
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.welcome-div {
  // margin-top: 50px;
  margin-left: $sidebarWidth;
  &.dark {
    color: $darkTextColor;
  }

  &.light{
    color: $textColor;
  }
}



.homeTopbar{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
//   position: fixed;
  &.light{
    background-color: $readerBgColor;
  }
  &.dark{
    background-color: $darkReaderBgColor;
  }
  z-index: 80;
  margin: 0px auto;
  top: 0px;

}

.nextPrev{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 40px;
}

.nextprev-button{
  height: 30px;
  width: 30px;
  border-radius: 5px;
  &:hover{
    &.dark{
      background-color: $darkHover;
    }
    &.light{
      background-color: $lightHover;
    }
    }
}
.prev{
  cursor: pointer;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.next-img{
  margin: 0px auto;
  height: 20px;
  width: 20px;
}

.prev-img{
  margin: 0px auto;
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
}


.next{
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}



.home-greeting{
  font-size: 38px;
  padding: 0px;
  margin-bottom: -30px;
}

.home-subtitle{
  font-size: 30px;
  font-weight: lighter;
}



.welcome {

    padding: 16px;
    padding-bottom: 0px;
    padding-left: 50px;
    text-align: left;

    .home-container{
        margin-top: 20px;
        text-align: left;
        padding-top: 20px;

        .renderDocsArray{
            margin-top: 10px;
        }
    }
  
    .img-newpdf{
      box-shadow:none;
      background-color: transparent;
    }
  
}

.skeleton {
  opacity: .5;
  animation: skeleton-loading 1s linear infinite alternate;
  border-radius: 1rem;

}
.skeleton-rect {
  opacity: .5;
  animation: skeleton-loading 1s linear infinite alternate;
}


.skeleton-noani {
  opacity: 1;
  background-color: hsl(200, 20%, 85%);
}

.skeleton-text {
  width: 100%;
  height: 1rem;
  margin-bottom: .25rem;
  border-radius: .25rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 85%);
  }
}


.bug-note {
  text-align: left;
  font-size: 12px;
  padding: 10px;
  margin-left: 10px;
}

.hide {
  display: none;
}

.sidebar-home {
  // background-color:$menuBgColor;
  
  // transition: background-image $transitiontime;
  backdrop-filter: blur(24px);
  margin:10px;
//   border:1px solid black;
  width: $sidebarWidth;
  border-radius:10px;
  position: fixed;
  height: calc(90%);
  padding-top: 50px;
  left: 0;
  align-items: left;
  text-align: left;
  

  .greeting-container{
    margin-left: 14px;
    // margin:0 auto;
    text-align: left;
    font-size: 18px;
    &.dark{
      color: $lightDesklampGrey;
    }
    &.light{
      color: $darkDesklampGrey;
    }
  }


  .sidebar-logo{
    // margin-left: 14px;
    width: 50%;
    background-color: transparent;
    // border-radius: 10px;
    display:block;
    margin:0 auto;
  }

  .active-sidebar{
      font-weight: bold;
  }

  &.light{
    background-color: $homeSidebarColor;

    .active-sidebar{
    //   background-color: #0071bc28;
        // background-color: $textboxColor;
        background-color: $lightHover;
    }
  }

  &.dark{
    background-color: $darkHomeSidebarColor;

    .active-sidebar{
      background-color: $darkHover;
    }
  }


  .search-container{
      &.light{
        background-color:$readerBgColor;
        // border:1px solid $activeTabBgColor;
      }

      &.dark{
        background-color:$darkReaderBgColor;
        // border: 1px solid $activeColorDark
      }
      &.focus{
        box-shadow: $boxShadow;
      }
      
      border-radius: 5px;
      margin-bottom:20px;
  }

  .search-sidebar{

      width: 100%;
      padding: 4px;
      padding-left: 5px;
      border:none;
      &.light{
        background: url('../../icons/search_light.png') no-repeat scroll 7px 7px;
        padding-left:30px;
        color: $darkDesklampGrey;
        &::placeholder{
          color: $darkDesklampGrey
        }
      }
      &.dark{
        background: url('../../icons/search_dark.png') no-repeat scroll 7px 7px;
        padding-left:30px;
      }

      &:focus{
          border:none;
      }

      &::placeholder{
          color:rgb(221, 220, 220);
      }
  }
}

.sidebar-pages{
    margin-top:40px;
}

.icon-enclosure {
  margin-bottom: 10px;
  padding: 5px;
  //padding-left: 20px;
  margin:10px;
  border-radius:5px;
  cursor:pointer;

  .sidebarLabel {
    font-size: $fontSize;
    display: inline;
    vertical-align: middle;
    
    padding-left: 20px;
    user-select: none;

    &.dark{
      color: $readerBgColor;
    }

    &.light{
      //color: rgb(61, 61, 61);
      color:  $darkDesklampGrey;
    }
  }
  

  .sidebarIcon {
    width: $iconWidth*1.3;
    height: $iconWidth*1.3;
    vertical-align: middle;
  }
}

.prefs-container {
  text-align: left;
  padding: 40px;
  user-select: none;
  // height:100vw;

  .header-line{
    margin-top:-7px;
    margin-bottom: 25px;
  }

  &.light{
    color: $textColor;
    .header-line{
      border:1px solid #34353f;
    }
    
  }
  &.dark{
    color: $darkTextColor;
    .header-line{
      border: 1px solid #f2f2f775;
    }
  }


  h3 {
    font-size: $headingSize;
    padding: 0px;
    margin-top: 0px;
    margin-bottom:30px;
  }

  h4{
    font-size: $subheadingSize;
    display: inline-block;
    margin-right:10px;
    margin-bottom:0px;
  }

  

  .theme-container{
    display:inline-block;
    text-align: left;
    font-weight: bold;
    border:0.25px solid rgba(128, 128, 128, 0.384);
    margin-right: 25px;
    border-radius: $themeRadius;
    cursor: pointer;

    p{
      margin:3px;
      display: inline;
    }

    .radio{
      width:15px;
      height:15px;
      border-radius: 50%;
      border:1px solid black;
      margin:5px;
      margin-bottom:7px;
      background-color: white;
    }

    .active-theme{
      background-color: $menuBgColor;

    }

    .theme-sel{
      vertical-align: center;
      border-top-right-radius: $themeRadius;
      border-top-left-radius: $themeRadius;
      border-bottom:0.25px solid rgba(128, 128, 128, 0.384);
    }
  
    .theme-sel-app{
      width: 300px;
      height: 205px;
  
    }
  
    .theme-sel-reader{
      width: 145px;
      height: 204px;
    }
  }
 
}

.task-status-container{
  position:fixed;
  width:fit-content;
  left:50px;
  bottom:50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-left:10px;
  padding-bottom:10px;
  padding-right:10px;
  //border:1px solid grey;

  &.light{
    background-color: $readerBgColor;
    color:$textColor;

    .img-close-taskstatus:hover{
      background-color: $textboxColor;
    }
  }

  &.dark{
      background-color: $darkReaderBgColor ;
      color:$darkTextColor;

      .img-close-taskstatus:hover{
        background-color: $darkHover;
      }
  }

  .taskstatus-subheader{
    margin-top:5px;
    margin-bottom: 10px;
    margin-right:25px;
    font-size:$contentSize;
  }

  .img-close-taskstatus{
    width:12px;
    height:12px;
    position:absolute;
    top:10px;
    padding:3px;
    right:7px;
    border-radius: 50%;
  }

  .fileLabel{
    font-size: 14px;
    width:230px;
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
  }

//   .progress-bar-container{
//     width:100px;
//     height:12px;
//     background-color:lightgrey;
//     display:inline-block;
//     border-radius: 3px;
//   }

//   .progress-bar-fill{
//     height:100%;
//     color:transparent;
//     font-size: 12px;    
//     border-radius: 3px;

//     &.light{
//       background-color:$lightDesklampGreen;
//     }

//     &.dark{
//       background-color: $darkDesklampGreen;
//     }
//   }

  .progress-p{
    font-size: 14px;
    display: inline-block;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .loadingStatus-img{
    width:12px;
    height:12px;
  }

  .loadingStatus-open{
    color:rgb(180, 180, 81);
    font-weight: bold;
    &:hover{
      cursor:pointer
    }
  }
}

.accounts-sidebar{
    // border-top:1px solid white;
    // margin-top:200px;
    position:absolute;
    bottom:10px;
    width:100%;
    .right{
      &.light{
        color: $darkDesklampGrey;
      }
      &.dark{
        color: $lightDesklampGrey;
      }
    }

    .profile-sidebar{
      .row{
        display: flex;
        align-items: center;
      }
        padding:10px;
        // border:1px solid pink;
        
        bottom:0px;
        cursor:pointer;
        .sidebar-initials{
            padding:5px;
            border-radius: 5px;
        }
        &.dark{
            background-color: $darkTextboxColor;
            .sidebar-initials{
                color:$readerBgColor;
                background-color: $darkHover;
            }
        }
        &.light{
            background-color: $textboxColor;
            .sidebar-initials{
                color:$darkReaderBgColor;
                background-color: $clipboardColor;
            }
        }

        .column {
            float: left;
            padding: 10px;

            p{
                margin:0 auto;
                font-size: 12px;
                &.account-name{
                    font-weight:bold;
                }
            }
        }
        
        .left {
            width: 10%;
            margin-top: 4px;
            height:100%;
        }
        
        
        /* Clear floats after the columns */
        .row:after {
        content: "";
        display: table;
        clear: both;
        }

    }
}


.popup-container{
  width:100%;
  height:fit-content;
  // background-color: yellow;
  position: absolute;
  top:80px;//90vh;
  z-index:200;
  /* Center vertically and horizontally */
  left: 50%;
  transform: translate(-50%, 0%);

  .popup-homescreen{
    background-color: rgb(175, 2, 2);
  }
}

p.popup{
  color:white;
  // font-weight: bold;
  // position: absolute;
  width:fit-content;
  margin:0 auto;
  padding:5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}
p.popup-warning{
  background-color: rgba(180, 4, 4, 0.801);
  padding-right: 40px;
  padding-left: 40px;

}

.refresh-btn{
    // display: flex;
    // align-items: center;
    // justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 26px;
    right: 40px;
    &.dark{
      background-color: $darkReaderBgColor;
    }
    &.light{
      background-color: $readerBgColor;
    }
}

.download-enclosure{
  margin-bottom: 20px;
}

.refresh-icon{
    height: 30px;
    width: 30px;
}

.home-sort{
    margin: 15px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    font-weight: bold;
   .options{
       display: inline;
       margin-left: 10px;
       border-radius: 5px;
       &.dark{
        background-color: $darkClipboardColor;
       }
       &.light{
        background-color: $clipboardColor;
       }
       padding: 7px;

       .option{
           font-weight: bold;
           padding: 5px;
           border-radius: 5px;
           &.dark{
            color: $lightDesklampGrey;
           }
           &.light{
            color: $darkDesklampGrey;
           }
           
           cursor: pointer;
           &.active{
             &.dark{
               background-color: $darkHover;
             }
             &.light{
              background-color: $lightHover ;
             }
               
           }
       }
   }
}

.home-loading{
    width: 80px;
    height: 80px;
    top: 50%;
    transform: translateY(-40px);
    /* Center vertically and horizontally */
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;

}

.empty-tag-msg{
    // width: 600px;
    margin: 0 auto;
    margin-top: 20px;
}

.drag-message-container{
    position: fixed; 
    border-radius: 5px;

    &.light{
        background-color: $lightHover;
    }
    &.dark{
        background-color: $darkHover;
    }
    padding: 5px;
    box-shadow: $boxShadow;
    bottom : 20px;
    left: calc(50% - 30px);
}

@media screen and (max-width: 1250px) {
  .nextPrev{
    display: none;
  }
}


@media screen and (max-width: 1150px) {
  .homeTopbar{
    width: fit-content;
    margin:0px auto;
  }
  .nextPrev{
    display: none;
  }
  // .refresh-btn{
  //   display: none;
  // }
  .home-sort{
    margin-left: 100px;
  }
//   .refresh-btn{
//     margin-left: 30px;
//   }
}

@media screen and (max-width: 960px) {
.homeTopbar{
width: fit-content;
margin:0px auto;
}
.home-sort{
display: none;
}

}

.new-banner{
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;

    &.dark{
        background-color: rgb(238, 205, 143);
        color: #000;
    }

    &.light{
        background-color: rgb(217, 183, 120);
    }
}

.download-link{
    margin-left: 10px;
    text-decoration: none;
    cursor: pointer;
    padding:3px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    transition: 0.2s;

    &.dark{
        color: white;

    }

    &.light{
        color: $darkDesklampGrey;
    }
}






