.full {
  margin: 1em 0;
  width: 100%;
}

.half {
  display: inline-block;
  width: calc(50% - 0.5em);
}
.half.right {
  margin-left: 0.5em;
}
.half.left {
  margin-right: 0.5em;
}
.half .button-container {
  margin-bottom: 1em;
}/*# sourceMappingURL=ui-styles.css.map */