/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.feedback-icon {
  border-radius: 50%;
  padding: 4px;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border: 1px solid #665AEF;
}
.feedback-icon.light {
  background-color: #D8D5FA;
}
.feedback-icon.dark {
  background-color: #4e4e58;
}
.feedback-icon.loaded {
  cursor: pointer;
}
.feedback-icon.loading {
  opacity: 50%;
}

.frill-feedback-dropdown {
  position: absolute;
  height: calc(100% - 40px);
  width: 420px;
  right: 0px;
  top: 40px;
  z-index: 101;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: translateX(600px);
  transition: 0.5s;
}

.frill-feedback-dropdown.active {
  right: 15px;
  transform: translateX(15px);
}/*# sourceMappingURL=FrillWidget.css.map */