/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.prompt-container {
  position: absolute;
  width: 350px;
  padding: 10px;
  z-index: 100;
  left: 50%;
  transform: translateX(-175px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  border-radius: 5px;
}
.prompt-container.dark {
  background-color: #34353f;
  color: white;
}
.prompt-container.light {
  background-color: #f2f2f7;
  color: black;
}
.prompt-container .row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prompt-container .row .prompt-column {
  float: left;
}
.prompt-container .row .prompt-column.logo {
  width: 20%;
  margin-right: 10px;
}
.prompt-container .row .prompt-column.text {
  width: 70%;
  padding: 10px;
  text-align: left;
}
.prompt-container .row:after {
  content: "";
  display: table;
  clear: both;
}

.prompt-header {
  font-size: 14px;
  font-weight: bold;
}

.prompt-message {
  font-size: 16px;
}

.prompt-timeout {
  text-align: left;
  margin-left: calc(20% + 25px);
  margin-top: 0px;
}

.logo {
  width: 48px;
  height: 48px;
}

.prompt-buttons {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
}
.prompt-buttons.dark .prompt-reject-button {
  background-color: grey;
  color: white;
}
.prompt-buttons.dark .prompt-reject-button:hover {
  background-color: rgb(146, 145, 145);
}
.prompt-buttons.dark .prompt-confirm-button {
  color: white;
  background-color: #444758;
}
.prompt-buttons.dark .prompt-confirm-button:hover {
  background-color: rgba(68, 71, 88, 0.7);
}
.prompt-buttons.light {
  color: black;
}
.prompt-buttons.light .prompt-reject-button {
  background-color: grey;
}
.prompt-buttons.light .prompt-reject-button:hover {
  background-color: rgb(146, 145, 145);
}
.prompt-buttons.light .prompt-confirm-button {
  background-color: #665AEF;
}
.prompt-buttons.light .prompt-confirm-button:hover {
  background-color: rgba(102, 90, 239, 0.7);
}
.prompt-buttons .prompt-button {
  padding: 5px;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 3px;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}/*# sourceMappingURL=Prompt.css.map */