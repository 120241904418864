/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.landing-container.light {
  background-color: #212231;
}
.landing-container.dark {
  background-color: #e6e5ee;
}

.logo-header {
  margin-top: 50px;
}

.ledxLogo {
  height: 50px;
  margin-bottom: 20px;
}/*# sourceMappingURL=landing.css.map */