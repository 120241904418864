@import "../../styles/variables";

.email-box{
    display: inline;
    padding:4px;
    padding-right:6px;
    padding-left:6px;

    width:fit-content;
    border-radius: 4px;
    font-size:12px;
    
    &.email-box-valid{
        &.dark{
            background-color: $clipboardBgColorDark;
        }
        &.light{
            background-color: $lightDesklampRed;

        }
    }

    &.email-box-invalid{
        &.dark{
            background-color: $darkDesklampRed;

        }
        &.light{
            background-color: $textboxColor;
        }

    }
}

.email-box-container{
    padding:5px;
    padding-left:0px;
    padding-bottom:8px;
    width:fit-content;
    display: inline-block;
}

.email-box-close{
    margin-left:5px;
    width:10px;
    height:10px;
}