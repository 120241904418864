
@import "../../styles/variables";

@keyframes popup {
    0%   {transform: scale(0.1,0.1); padding: 5x;}
    100% {padding: 5x; transform: scale(1,1);}
}

.hoverTipContainer{
    position: absolute;
    padding: 5px;
    border-radius: 5px;
    min-width: max-content;
    box-shadow: $boxShadow;
    z-index: 500;
    &.dark{
        background-color: $darkReaderBgColor;
        color: white;
        .hoverTipShortcut{
            // font-size: 10px;
            color: $clipboardColor;
            padding: 2px;
            border-radius: 4px;
            background-color: $darkHover;
        }
    }

    &.light{
        background-color: $readerBgColor;
        color: black;
        .hoverTipShortcut{
            // font-size: 10px;
            color:$darkReaderBgColor;
            padding: 2px;
            border-radius: 4px;
            background-color: $lightHover;
        }
    }

    &.right{
        left: 50px;
        animation-name: popup;
        animation-duration: 0.1s;
    }

    &.top{
        bottom: 60px;
        right: 10px;
        animation-name: popup;
        animation-duration: 0.1s;
    }

    &.bottom{
        // top: 80px;
        animation-name: popup;
        animation-duration: 0.1s;
    }
}

.hoverTipShortcut{
    // font-size: 10px;
    margin-left: 5px;
}