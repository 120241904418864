
@import '../../../styles/colors';
@import '../../../styles/variables';

.codeinput-container{
    margin: 0 0;
    display: flex;
    justify-content: space-between;


}

.ap-otp-input{
    border: 1px solid $darkGrey;
    border-radius: 3px;
    width: 64px;
    height: 64px;
    // margin-right: 15px;
    text-align: center;
    font-size: 35px;
    font-family: 'Poppins';
    font-size: 28px;

}

.ap-otp-input:focus{
    outline: none !important;
    border: 1px solid $darkPurple;
    box-shadow: $boxShadow;
    transition: 0.12s ease-in;
}

.error{
    .ap-otp-input{
        border: 1px solid $lightModeRed;
    }
}