/*Simple css to style it like a toggle switch*/

@import '../../styles/variables';

.theme-switch-wrapper {
    display: flex;
    align-items: center;
  
    em {
      margin-left: 10px;
      font-size: 1rem;
    }
  }

  .toggle-img{
    height:14px;
    width:14px;
    &.right{
        float:right;
        margin-left:13px;
        
    }
    &.left{
        float:left;
        margin-left:10px;

    }
  }

  .theme-switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
  }
  
  .theme-switch input {
    display:none;
  }
  
  .slider {
    background-color: #ccc;
    bottom: 0;
    &.active-toggle{
        cursor: pointer;
    }
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }
  
  .slider:before {
    background-color: $clipboardColor;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 26px;
    
  }
  .slider.active-toggle:before{
    //the circle (dark)
    background-color: $darkClipboardColor;
  }
  .slider.inactive-toggle:before{
    //the circle (light)
    background-color: $desklampGrey;
  }

  .slider.static-toggle.dark:before{
    background-color: $darkClipboardColor;
  }

  .slider.static-toggle.light:before{
    background-color: $desklampGrey;
  }

.slider{
    //the track
    
    &.inactive-toggle.multi-toggle{
        background-color: $readerBgColor;
    }
    &.active-toggle.multi-toggle{
        // background-color: $darkTextboxColor;
        background-color: #696b77;

    }
    &.static-toggle{
      &.dark{
        // background-color: $darkTextboxColor;
        background-color: #696b77;
      }
      &.light{
        background-color: $readerBgColor;
      }
    }
}
  
  .slider.active-toggle:before {
    transform: translateX(26px);
  }

  
  .slider.round {
    border-radius: 34px;
    display: flex;
    align-items: center;
    
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  