/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.indexbar-main {
  padding-right: 13px;
  z-index: 7;
}
.indexbar-main.dark {
  color: white;
}
.indexbar-main.light {
  color: black;
}

ul {
  list-style-type: none;
  text-align: left;
}

.top-level-ul {
  left: 0px;
  position: absolute;
}
.top-level-ul.dark {
  color: #f2f2f7;
}
.top-level-ul.light {
  color: #34353f;
}

.section {
  cursor: pointer;
  width: 90%;
  text-align: left;
  font-size: 15px;
  font-family: "PT_Sans";
  padding: 0px;
  height: auto;
  margin-top: 4px;
  overflow: hidden;
  overflow-wrap: break-word;
}

.collapsible {
  cursor: pointer;
  /* border: 1px solid black; */
  height: 8px;
  width: 8px;
  margin-right: 10px;
}

.no-drop {
  cursor: default;
  padding: 5px;
  width: 0vw;
  height: 8px;
  text-align: left;
  font-size: 15px;
  margin-right: 10px;
}

.indexbar-main .active, .collapsible:hover {
  transform: rotate(90deg);
}

.content {
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  font-family: "PT_Sans";
}

.empty-index-message {
  margin: 0 auto;
  margin-top: 40px;
  width: 200px;
}
.empty-index-message.dark {
  color: #f2f2f7;
}
.empty-index-message.light {
  color: #313941;
}/*# sourceMappingURL=IndexBar.css.map */