.text a {
  text-decoration: none;
  font-family: "Poppins-Bold";
  color: #665aef;
  cursor: pointer;
}
.text b {
  color: #665aef;
  font-family: "Poppins-Bold";
}
.text.xs {
  font-size: 0.5em;
}
.text.s {
  font-size: 0.85em;
}
.text.l {
  font-size: 1.25em;
}
.text.xl {
  font-size: 1.5em;
}
.text.success {
  color: #3a9434;
}
.text.error {
  color: #db5461;
}

.title {
  font-size: 2em;
  font-family: "Poppins-Medium";
  margin: 10px 0;
}
.title .title-punctuation {
  color: #665aef;
}

.subtitle {
  font-family: "Poppins";
  font-size: 1em;
  margin-top: 15px;
}

.caption {
  font-family: "Poppins";
  font-size: 0.75em;
  color: #727272;
  margin-top: 15px;
}
.caption a {
  text-decoration: underline;
  font-family: "Poppins";
  color: #727272;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}/*# sourceMappingURL=Text.css.map */