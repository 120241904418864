@import "../../styles/variables";

.annotationPane-main{
    padding-left: 25px;
    padding-right:10px;
    height:100%;
}

.annotationPane-header{
    font-weight: bold;
}

.searchNFilter-annotations{
    margin-top: 15px;

    &.light{
        .annotationsSearch{
            background-color: $lightDesklampGrey;
        }
        .annotationsFilterButton{
            background-color: $textboxColor;
        }
        border-bottom:1px solid $lightHover;
    }

    &.dark{
        .annotationsSearch{
            background-color: $darkDesklampGrey;
        }
        .annotationsFilterButton{
            background-color: $darkTextboxColor;
            span{
                color: white
            }

        }
        border-bottom:1px solid $darkHover;
    }
}

.annotationsSearch{
    padding:5px;
    border-radius: 5px;
    &.light{
        background: url('../../icons/search_light.png') no-repeat scroll 7px 7px;

    }
    &.dark{
        background: url('../../icons/search_dark.png') no-repeat scroll 7px 7px;
        color: white;


    }
   
    padding-left:30px;
    width: calc(80% - 65px);
    margin:0 auto;

}

.annotationsFilterButton{
    width: 20%;
    margin-left:10px;
    padding:5px;
    border-radius: 5px;
    font-size: 14px;
    user-select: none;
    cursor: pointer;

}

.annotations_find_arrow{
    width: 10px;
    height:10px;
    margin-left:10px;
    margin-right:5px;

}

.annotationPane-main{
    padding-top: 20px;
}

.annotationBox-row{
    width: calc(100% - 10px);
    height:60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:10px;
    &:after{
        content: "";
        display: table;
        clear: both;
    }
    &.light{
        background-color: $textboxColor;
    }
    &.dark{
        background-color: $darkTextboxColor;
    }
}

.annotationBox-column{
    float: left;
    // height:100%;
    // display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    // border: 1px solid pink;
}

.annotationBox-color{
    width:10px;
    color:transparent;
    height:60px;
}

.annotationBox-icon{
    width: 60px;
    .annotationBox-icon-content{
        width:25px;
        height:25px;
        padding:5px;
        border-radius: 5px;
        &.light{
            background-color: $lightHover;
        }
        &.dark{
            background: $customDarkBg1;
        }
    }
}

.annotationBox-text{
    width: calc(100% - 10px - 60px - 70px);
    text-align: left;
}



.annotationBox-text-content{
    // white-space: nowrap;
    // overflow: hidden;
    margin:0 auto;
    // max-height:60px;
    vertical-align: center;
    justify-content: center;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;

    &.dark{
        color: $clipboardColor;
    }
}

.annotationBox-pageNum{
    width: 70px;

    .annotationBox-pageNum-content{
        &.dark{
            color: $clipboardColor;
        }
    }

}

.annotationBoxes-container{
    margin-top: 20px;
    height: 80%;
    overflow-y: auto;
    &.dark{
        color: $clipboardColor
    }
}

.annotationFilterDropdown-container{
    position: absolute;
    right: 20px;
    margin-top: 10px;
}

.selected-filters{
    margin-bottom: 0px;
    text-align: left;
    padding: 10px;
    padding-bottom: 0px;
}

.filterBox{
    padding: 5px;
    // display: block;
    width: fit-content;
    display: inline-block;
    // overflow: hidden;
    // float: left;
    border-radius: 5px;
    user-select: none;
    margin:5px;
    margin-bottom:10px;
    cursor: pointer;
    &.light{
        background-color: $textboxColor;
    }
    &.dark{
        background-color: $darkDesklampButtonBg;
        color:$lightDesklampGrey ;
    }
}

.annoType-filter-icon{
    height:18px;
    width: 18px;
    // padding:3px;
    border-radius: 3px;
    margin-right: 5px;
    // &.light{
    //     background-color: $lightHover;
    // }
    // &.dark{
    //     background-color: $darkHover;
    // }
}

.filterBox-color{
    display: inline-flex;
    width: 10px;
    height:10px;
    border-radius: 5px;
    margin-right: 5px;
}

.annotation-icon-container{
    margin-left: 5px;
}

.appliedFilter-item{
    display: flex;
    padding: 0px;
}