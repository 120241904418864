@import "../../styles/variables";


.note-stub{
    
    position: absolute;
    font-family: $globalFont;
    z-index: 1;
    resize: none;
    mix-blend-mode: normal;
    //border: 1px solid orange;
    //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
    
}
.linkpointer-gif{
    width:64px;
    height:64px;
}
.circle {
    
        width: 30px;
        height: 30px;
        position: absolute;
        left: 0; 
        top: 0;
        display: block;
        background-color: $menuBgColor;
        border-radius: 999999px;
        // transform: translateX(-50%) translateY(-50%);
        box-shadow: 0 0 8px rgba(0,0,0,.3);

}
.pulsating {
    position: absolute;
    left: 50%;
    top: 50%;
    // transform: translateX(-50%) translateY(-50%);
    width: 30px;
    height: 30px;
    
    &:before {
      content: '';
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 9999px;
      background-color: $desklampButtonBg;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    
    &:after {
      content: '';
      position: absolute;
      left: 0; 
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $menuBgColor;
      border-radius: 999990px;
      box-shadow: 0 0 8px rgba(0,0,0,.3);
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
    }
  }
  
  @keyframes pulse-ring {
    0% {
      transform: scale(.33);
    }
    80%, 100% {
      opacity: 0;
    }
  }
  
  @keyframes pulse-dot {
    0% {
      transform: scale(.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(.8);
    }
  }