/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.signup-topbar {
  height: 24px;
  padding: 5px;
  font-weight: bold;
  text-align: center;
}
.signup-topbar.dark {
  background-color: #665AEF;
  color: white;
}
.signup-topbar.dark .signin-button {
  color: #e6e5ee;
  border: 1px solid #e6e5ee;
}
.signup-topbar.dark .signin-button:hover {
  color: #212231;
}
.signup-topbar.light {
  background-color: #BAB6F5;
}
.signup-topbar.light .signin-button {
  color: #212231;
  border: 1px solid #212231;
}
.signup-topbar.light .signin-button:hover {
  background-color: #665AEF;
  color: #e6e5ee;
  border: 1px solid #665AEF;
}
.signup-topbar .signin-button {
  margin-left: 10px;
  padding: 3px;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  transition: 0.3s;
  text-decoration: none;
}
.signup-topbar .signin-button:hover {
  background-color: white;
  border: 1px solid white;
  color: #665AEF;
}

.app-reader-container.user {
  height: calc(100vh - 40px);
}
.app-reader-container.no-user {
  height: calc(100vh - 40px - 24px - 10px);
}
.app-reader-container.electron {
  height: calc(100vh - 110px);
}
.app-reader-container.electron.MacOS {
  height: calc(100vh - 80px);
}
.app-reader-container.electron.noFile {
  height: calc(100vh - 80px);
}

.app-row {
  height: 100%;
  position: relative;
}

.column {
  float: left;
  position: relative;
}

/* Clear floats */
.app-row:after {
  content: "";
  display: table;
  clear: both;
}

.toolbar-ul {
  list-style: none;
  padding-left: 0;
}

.toolbar-loader {
  width: 40px;
  z-index: 8;
  height: 100%;
  vertical-align: middle;
  justify-content: center;
  display: flex;
}
.toolbar-loader.dark {
  background-color: #34353f;
}
.toolbar-loader.light {
  background-color: #f2f2f7;
}

.toolbar-footer {
  bottom: 15px;
  margin-bottom: 70px;
  position: absolute;
}

.locationmarkers-loader {
  z-index: 7;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.locationmarkers-loader.invert-locMarksLoader {
  right: 0;
}

.locationmarkers-loader::-webkit-scrollbar {
  display: none;
}

.findbar-loader {
  z-index: 8;
  margin-top: 15px;
  margin-left: 10px;
  right: 10px;
  position: fixed;
}

.clipboard-loader {
  left: 40px;
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.clipboard-loader::-webkit-scrollbar {
  display: none;
}

.pagenav-loader {
  z-index: 8;
  color: black;
  /* left:66vw; */
  /* width:26vw; FIX THIS DYNAMICALLY */
  bottom: 0px;
  position: fixed;
  width: 150px;
  overflow: none;
  height: auto;
}
.pagenav-loader.pagenav-fullscreen {
  z-index: 9;
}

.reader-loader {
  z-index: 2;
  overflow: none;
  height: 100%;
}

.separator-loader {
  height: 100%;
  z-index: 1;
  background-color: transparent;
}

.pdf-container {
  position: relative;
}

.PDFReader {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--reader-bgcol);
  text-align: left;
  position: absolute;
}

.workspace-container {
  height: 100%;
  z-index: 2;
  overflow-y: hidden;
  text-align: center;
  box-sizing: border-box;
}

.NoteLayer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  transition: background 0.3s;
  opacity: 1;
  mix-blend-mode: normal;
}

.sliderMenu-wrapper-pageNav.hidden {
  visibility: hidden;
}

.PDFReader::-webkit-scrollbar {
  height: 8px;
  width: 9px;
}

/* Track */
.PDFReader::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.PDFReader::-webkit-scrollbar-thumb {
  background: darkgrey;
  width: 2px;
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Handle on hover */
.PDFReader::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}

.popup-container {
  width: 100%;
  position: absolute;
  top: 50px;
  z-index: 200;
}

p.popup {
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

p.popup-warning {
  background-color: rgba(180, 4, 4, 0.801);
  padding-right: 40px;
  padding-left: 40px;
}

p.popup-message {
  background-color: rgba(102, 90, 239, 0.9);
  padding-right: 40px;
  padding-left: 40px;
}

.span_toggleWorkspace {
  position: fixed;
  bottom: 10px;
  cursor: pointer;
  z-index: 11;
}

.btn_toggleWorkspace {
  width: 17px;
  height: 17px;
  padding: 8px;
  margin-top: 10px;
  bottom: 9px;
  cursor: pointer;
}
.btn_toggleWorkspace.light {
  background-color: #f2f2f7;
}
.btn_toggleWorkspace.light.btn_showWorkspace {
  background-color: #e6e5ee;
}
.btn_toggleWorkspace.dark {
  background-color: #34353f;
}
.btn_toggleWorkspace.dark.btn_showWorkspace {
  background-color: #212231;
}

.btn_hideWorkspace {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  transform: rotate(-90deg);
}

.btn_showWorkspace {
  position: fixed;
  right: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transform: rotate(90deg);
}

.workspace-header {
  font-size: 18px;
  display: inline;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0 auto;
  margin-top: 5px;
}
.workspace-header.dark {
  color: #e6e5ee;
}
.workspace-header.dark.active {
  color: #BAB6F5;
}
.workspace-header.light.active {
  color: #665AEF;
}

.workspace-scroll::-webkit-scrollbar {
  height: 8px;
  width: 9px;
}

/* Track */
.workspace-scroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.workspace-scroll::-webkit-scrollbar-thumb {
  background: darkgrey;
  width: 2px;
  border-radius: 4px;
}

/* Handle on hover */
.workspace-scroll::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}/*# sourceMappingURL=PDFLoader.css.map */