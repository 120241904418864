/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.annotationPane-main {
  padding-left: 25px;
  padding-right: 10px;
  height: 100%;
}

.annotationPane-header {
  font-weight: bold;
}

.searchNFilter-annotations {
  margin-top: 15px;
}
.searchNFilter-annotations.light {
  border-bottom: 1px solid #BAB6F5;
}
.searchNFilter-annotations.light .annotationsSearch {
  background-color: #f2f2f7;
}
.searchNFilter-annotations.light .annotationsFilterButton {
  background-color: #D8D5FA;
}
.searchNFilter-annotations.dark {
  border-bottom: 1px solid #4e4e58;
}
.searchNFilter-annotations.dark .annotationsSearch {
  background-color: #313941;
}
.searchNFilter-annotations.dark .annotationsFilterButton {
  background-color: #444758;
}
.searchNFilter-annotations.dark .annotationsFilterButton span {
  color: white;
}

.annotationsSearch {
  padding: 5px;
  border-radius: 5px;
  padding-left: 30px;
  width: calc(80% - 65px);
  margin: 0 auto;
}
.annotationsSearch.light {
  background: url("../../icons/search_light.png") no-repeat scroll 7px 7px;
}
.annotationsSearch.dark {
  background: url("../../icons/search_dark.png") no-repeat scroll 7px 7px;
  color: white;
}

.annotationsFilterButton {
  width: 20%;
  margin-left: 10px;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.annotations_find_arrow {
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-right: 5px;
}

.annotationPane-main {
  padding-top: 20px;
}

.annotationBox-row {
  width: calc(100% - 10px);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.annotationBox-row:after {
  content: "";
  display: table;
  clear: both;
}
.annotationBox-row.light {
  background-color: #D8D5FA;
}
.annotationBox-row.dark {
  background-color: #444758;
}

.annotationBox-column {
  float: left;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.annotationBox-color {
  width: 10px;
  color: transparent;
  height: 60px;
}

.annotationBox-icon {
  width: 60px;
}
.annotationBox-icon .annotationBox-icon-content {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 5px;
}
.annotationBox-icon .annotationBox-icon-content.light {
  background-color: #BAB6F5;
}
.annotationBox-icon .annotationBox-icon-content.dark {
  background: #363640;
}

.annotationBox-text {
  width: calc(100% - 10px - 60px - 70px);
  text-align: left;
}

.annotationBox-text-content {
  margin: 0 auto;
  vertical-align: center;
  justify-content: center;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.annotationBox-text-content.dark {
  color: #e6e5ee;
}

.annotationBox-pageNum {
  width: 70px;
}
.annotationBox-pageNum .annotationBox-pageNum-content.dark {
  color: #e6e5ee;
}

.annotationBoxes-container {
  margin-top: 20px;
  height: 80%;
  overflow-y: auto;
}
.annotationBoxes-container.dark {
  color: #e6e5ee;
}

.annotationFilterDropdown-container {
  position: absolute;
  right: 20px;
  margin-top: 10px;
}

.selected-filters {
  margin-bottom: 0px;
  text-align: left;
  padding: 10px;
  padding-bottom: 0px;
}

.filterBox {
  padding: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  border-radius: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}
.filterBox.light {
  background-color: #D8D5FA;
}
.filterBox.dark {
  background-color: #444758;
  color: #f2f2f7;
}

.annoType-filter-icon {
  height: 18px;
  width: 18px;
  border-radius: 3px;
  margin-right: 5px;
}

.filterBox-color {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
}

.annotation-icon-container {
  margin-left: 5px;
}

.appliedFilter-item {
  display: flex;
  padding: 0px;
}/*# sourceMappingURL=AnnotationPane.css.map */