@import "../../styles/variables";
// .radio-container {
//   // padding: 13px;
//   margin: 0 auto;
//   margin-top: $topmenuHeight;
//   // padding-right: 13px;
//   &.light{
//     color: $textColor
//   }
//   &.dark{
//     color: $darkTextColor;
//   }

.radio-container {
  margin: 0 auto;
  margin-top: 10px;
  // margin-top: $topmenuHeight;
  width: $menuRadioWidth;
  &.dark{
    background-color: $darkTextboxColor;
  }
  &.light{
    background-color: $clipboardColor;
  }
  height: $menuRadioHeight;
  border-radius: 5px;
  padding: 0px 7px;
  line-height: $menuRadioHeight;
  position: relative;
  z-index: 1;
  cursor:pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.radio-span-2{
  padding: $menuRadioHeight/2;
  padding-left: 0;
  padding-right: 0;
}
.radio-option-1 {
  //padding: 10px;
  // border-radius: 5px;
  //margin:3px;
  line-height: $menuRadioHeight;
  float: left;
  // transition: background-color $transitiontime;
  &.dark{
    color: $darkTextColor;
  }
  &.light{
    color: $textColor;
  }
}

.radio-option-2 {
  //padding: 10px;
  // border-radius: 5px;
  line-height: $menuRadioHeight;
  //margin:3px;
  float: right;
  &.dark{
    color: $darkTextColor;
  }
  &.light{
    color: $textColor;
  }
}

.radio-bg{
  line-height: $menuRadioHeight;
  height: 0.75*$menuRadioHeight;
  margin-top: $menuRadioHeight/8;
  width: $menuRadioBGWidth;
  position: absolute;
  top: 0;
  z-index: -1;
  // transition: $transitiontime;
  border-radius: 5px;
  &.radio-bg-2{
    &.dark{
      background-color: $darkReaderBgColor;
    }
    &.light{
      background-color: $readerBgColor;
    }
  }
}

.radio-span:hover{
  font-weight: bold;
}




// .radio-option-2.light > .radio-span-2:hover{
//   color: $darkTextColor;
//   }
// .radio-option-2.dark > .radio-span-2:hover{
//   color: $textColor;
// }

// .radio-option-2:hover + .radio-option-1.dark{
//   color: $darkTextColor;

// }
// .radio-option-2:hover + .radio-option-1.light{
//   color: $textColor;}

// .active-radio {
//   //border-radius: 10px;
//   &.light{
//     background-color: #0071bc;
//     color: white;
//   }
//   &.dark{
//     background-color: #2b2828;
//     color: $darkTextColor;
//   }
// }

// .inactive-radio:hover{
//   background-color: rgba(0, 0, 0, 0.212);
// }
