/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
@-webkit-keyframes popup {
  0% {
    transform: scale(0.1, 0.1);
    padding: 5x;
  }
  100% {
    padding: 5x;
    transform: scale(1, 1);
  }
}
@keyframes popup {
  0% {
    transform: scale(0.1, 0.1);
    padding: 5x;
  }
  100% {
    padding: 5x;
    transform: scale(1, 1);
  }
}
.hoverTipContainer {
  position: absolute;
  padding: 5px;
  border-radius: 5px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  z-index: 500;
}
.hoverTipContainer.dark {
  background-color: #34353f;
  color: white;
}
.hoverTipContainer.dark .hoverTipShortcut {
  color: #e6e5ee;
  padding: 2px;
  border-radius: 4px;
  background-color: #4e4e58;
}
.hoverTipContainer.light {
  background-color: #f2f2f7;
  color: black;
}
.hoverTipContainer.light .hoverTipShortcut {
  color: #34353f;
  padding: 2px;
  border-radius: 4px;
  background-color: #BAB6F5;
}
.hoverTipContainer.right {
  left: 50px;
  -webkit-animation-name: popup;
          animation-name: popup;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}
.hoverTipContainer.top {
  bottom: 60px;
  right: 10px;
  -webkit-animation-name: popup;
          animation-name: popup;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}
.hoverTipContainer.bottom {
  -webkit-animation-name: popup;
          animation-name: popup;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.hoverTipShortcut {
  margin-left: 5px;
}/*# sourceMappingURL=HoverTip.css.map */