body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.illegal-browser-container{
    padding: 50px;
    margin: 0 auto;
    text-align: center;
    align-items: middle;
}


.illegal-browser-caption{
    font-size:14px;
    color: black;
}

.iphone-video-embed{
    margin-left: -25px;
}

.illegal-mobile-container{
    width: 100%;
    text-align: center;
    margin-top: 40px;
}

.mobile-message-header{
    font-size: 24px;
    color: white;
}
.mobile-message-subtitle{
    font-size: 16px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;

}

.desklamp-logo{
    width: 60px;
    height: 60px;
    margin: 0 auto;
}