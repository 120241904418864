.secondary-button .button-image {
  filter: invert(38%) sepia(46%) saturate(6099%) hue-rotate(233deg) brightness(98%) contrast(92%);
}
.secondary-button .button-text {
  color: #665aef;
  position: relative;
}
.secondary-button .button-container {
  background-color: #e0e7ff;
  border: 1px solid #c7cafc;
  position: relative;
}
.secondary-button .button-container:hover {
  background-color: #c7d2fe;
}
.secondary-button .button-container.loading:hover {
  background-color: #e0e7ff;
}

.primary-button .button-image {
  filter: brightness(0) invert(1);
}
.primary-button .button-text {
  color: white;
}
.primary-button .button-container {
  background-color: #4f46e5;
}
.primary-button .button-container:hover {
  background-color: #4338ca;
}
.primary-button .button-container.loading:hover {
  background-color: #4f46e5;
}

.button-container {
  font-family: "Poppins-Medium";
  transition: 0.3s;
  padding: 0.9em 0;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  position: relative;
}
.button-container .loading-image {
  display: none;
}
.button-container.loading {
  cursor: auto;
}
.button-container.loading .loading-image {
  display: block;
}

.button-image {
  width: 1.333em;
  height: 1.333em;
  color: black;
  vertical-align: middle;
}

.loading-image {
  height: 1.77em;
  width: auto;
  position: absolute;
  right: 40px;
  top: 0.66em;
}
.loading-image.img-left, .loading-image.img-right {
  height: 20px;
  width: auto;
  right: 20px;
  top: 1em;
}

.button-text {
  font-family: "Poppins-Bold";
  font-size: 1em;
}/*# sourceMappingURL=Button.css.map */