.documentDeletedPageBigContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vw;
}

.documentDeletedPageContainer.dark {
  color: white;
}
.documentDeletedPageContainer .noAccess_header {
  font-size: 24px;
}
.documentDeletedPageContainer .noAccess_icon {
  width: 128px;
  height: 128px;
  margin: 10px;
}
.documentDeletedPageContainer .noAccess_message {
  margin: 30px;
  font-size: 18px;
}/*# sourceMappingURL=NoAccessPage.css.map */