@import '../../styles/variables';

$promptWidth: 350px;

.prompt-container{
    position: absolute;
    width: $promptWidth;
    padding: 10px;
    z-index: 100;
    left: 50%;
    transform: translateX(- $promptWidth/2);
    box-shadow: $boxShadow;
    border-radius: 5px;
    &.dark{
        background-color: $darkReaderBgColor;
        // border:1px solid grey;
        color: white;
    }
    &.light{
        background-color: $readerBgColor;
        color: black;
    }

    .row{
        display: flex;
        justify-content: center;
        align-items: center;
        .prompt-column {
            float: left;
            
            &.logo{
                width: 20%;
                margin-right: 10px;
                
            }
            &.text{
                width: 70%;
                padding: 10px;
                text-align: left;
            }
        }

        &:after{
            content: "";
            display: table;
            clear: both;    
        }
    }
}

.prompt-header{
    font-size: 14px;
    font-weight: bold;
}

.prompt-message{
    font-size: 16px;
}

.prompt-timeout{
    text-align: left;
    margin-left: calc(20% + 25px);
    margin-top: 0px;
}

.logo{
    width: 48px;
    height: 48px;
}

.prompt-buttons{
    width: fit-content;
    float: right;

    &.dark{
        
        .prompt-reject-button{
            background-color: grey;
            color: white;
            &:hover{
                background-color: rgb(146, 145, 145);
            }
        }

        .prompt-confirm-button{
            color: white;
            background-color: $darkDesklampButtonBg;
            &:hover{
                background-color: transparentize($darkDesklampButtonBg, $amount: 0.3);;
            }
        }   
    }

    &.light{
        color: black;
        .prompt-reject-button{
            background-color: grey;
            &:hover{
                background-color: rgb(146, 145, 145);
            }
        }

        .prompt-confirm-button{
            background-color: $desklampButtonBg;
            &:hover{
                background-color: transparentize($desklampButtonBg, $amount: 0.3);
            }

        }   
    }

    .prompt-button{
        padding: 5px;
        margin-top: 10px;
        margin-right: 10px;
        border-radius: 3px;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
        cursor: pointer;
    }
}