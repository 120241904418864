/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
@-webkit-keyframes popupSharing {
  0% {
    opacity: 0.1;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popupSharing {
  0% {
    opacity: 0.1;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.sharing-section-title {
  font-weight: bold;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.gen-access-subtitle {
  color: #313941;
}

.close-sharingbox {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 15px;
  top: 35px;
  padding: 5px;
  border-radius: 50%;
}
.close-sharingbox:hover {
  background-color: rgb(190, 190, 190);
}

.sharingBar-container {
  -webkit-animation-name: popupSharing;
          animation-name: popupSharing;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  text-align: left;
  margin-left: 20px;
  padding: 15px;
  border-radius: 5px;
  padding-right: 13px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-top: 20px;
  width: 600px;
  z-index: 10;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  padding-bottom: 35px;
  background-color: white;
}

.gen-access-container {
  margin-top: 20px;
  padding-bottom: 40px;
}
.gen-access-container .column.middle {
  margin-left: 20px;
}
.gen-access-container .column.left {
  margin-top: 5px;
}

.gen-access-header {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  margin-left: -5px;
  border-radius: 5px;
}
.gen-access-header.active-edit {
  cursor: pointer;
}
.gen-access-header.active-edit:hover {
  background-color: #e6e5ee;
}

.gen-access-dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  margin-top: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}
.gen-access-dropdown .access-option {
  padding: 8px;
}
.gen-access-dropdown .access-option:hover {
  background-color: #BAB6F5;
}

.link-right {
  float: right;
  margin-right: 10px;
  color: #665AEF;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #BAB6F5;
  padding: 10px;
  border-radius: 20px;
}

.gen-access-dropdown-arrow {
  height: 8px;
  width: 8px;
  margin-left: 5px;
  margin-bottom: 3px;
  transform: rotate(90deg);
}

.link-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.collaborators-input {
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: calc(100% - 100px);
  display: inline-block;
  background-color: #D8D5FA;
}
.collaborators-input .sharingMenu-fileinput, .collaborators-input .permission-dropdown {
  color: #34353f;
}
.collaborators-input .permission-dropdown {
  background-color: #D8D5FA;
}
.collaborators-input .sharingMenu-fileinput {
  display: inline;
  width: calc(100% - 100px);
}

.add-collaborator-button {
  background-color: #665AEF;
  padding: 8px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.permission-dropdown {
  border: none;
  border-radius: 2px;
  outline: none;
  font-size: 12px;
  width: 80px;
  color: #34353f;
}

.send-menu-input {
  width: 100%;
}

.sidemenu-label {
  display: inline;
  margin: 3px;
}

.send-button-sharingBar {
  float: right;
}

.sharingBar-icon {
  height: 80px;
  width: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.collaborators-container {
  margin-top: 30px;
  max-height: 400px;
  overflow-y: auto;
}

.collaborator-person {
  margin-bottom: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  /* Clear floats after the columns */
}
.collaborator-person .column {
  float: left;
}
.collaborator-person .column .collab-initials {
  font-size: 14px;
  background-color: #665AEF;
  color: #444758;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.collaborator-person .column.left {
  width: 50px;
  height: 100%;
}
.collaborator-person .column.center {
  width: 550px;
}
.collaborator-person .column.center .collab-name {
  font-size: 15px;
  margin: 0 auto;
  margin-left: 20px;
  color: #313941;
}
.collaborator-person .column.center .collab-email {
  margin: 0 auto;
  font-size: 12px;
}
.collaborator-person.row:after {
  content: "";
  display: table;
  clear: both;
}

.permission-owner {
  font-size: 12px;
}

.sharing-group {
  padding-top: 0px;
}

.sharing-loading {
  width: 50px;
  margin-left: 50px;
  margin-top: -20px;
}

.lock-icon-share {
  height: 24px;
  width: 24px;
  padding: 6px;
  background-color: #f2f2f7;
  border-radius: 100%;
}/*# sourceMappingURL=SharingBar.css.map */