.light .notebook-text-color{
    --notebook-text-red: #FF0000;
    --notebook-text-blue: #0000FF;
    --notebook-text-green: #008000;
    --notebook-text-orange: #D9730D;
    --notebook-text-yellow: #CB912F;
    --notebook-text-violet: #F600F6;
    --notebook-text-brown: #A15000;
    --notebook-text-black: #000000;
    // Add new colors
}


.dark .notebook-text-color{
    --notebook-text-red: #FF4C48;
    --notebook-text-blue: #00FFFF;
    --notebook-text-green: #00FF00;
    --notebook-text-orange: #FFA500;
    --notebook-text-yellow: #FFFF00;
    --notebook-text-violet: #FF80FF;
    --notebook-text-brown: #FF9E3D;
    --notebook-text-black: #FFFFFF;
    // Add new colors
}