@import "../../styles/variables";
$dropdownWidth: 160px;
$arrowSize: 8px;

@keyframes popup {
    0%   {transform: scale(0.1,0.1)}
    100% {transform: scale(1,1);}
}

@keyframes dropdown{
    0%   {top: -20px;}
    100% {top:30px;}
}

.topmenu{
    &.dark{
        background-color: $darkTopmenuColor;
        box-shadow: inset 0px -1px 0px 0px $darkDesklampGrey;

    }
    &.light{
        background-color: $lightTopmenuColor;
        // border-bottom: 1px solid #c2c2c4;
        // outline-offset: -1px;
        box-shadow: inset 0px -1px 0px 0px #c2c2c4;


    }
    width: 100%;
    height: 40px;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    // position: fixed;
    z-index: 10;
    top:0;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    // top: 0px;
  
   

    

    
}

.topmenu-right{
    float: right;
    display: flex;
    justify-content: center;
    align-items: center; 
    width: fit-content;
    &.dark{
        color: white;
    }
    &.light{
        color: black;

    }
    // right: 0px;
    // top: 50%;
    // transform: translate(0, -50%);
  

}

.topmenu-center{
    display: flex;
    justify-content: center;
    align-items: center; 
    // margin: 0 auto;
    width: fit-content;
    // margin-left: -260px;

    .topmenu-center-item{
        margin-left: 10px;
        margin-right: 10px;
    }

}

.topmenu-left{
    margin-left: 10px;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.left-menu-icon{
    height: 20px;
    width: 20px;
    margin: 0 auto;
    justify-content: center;
    align-items: center; 
    display:inline-flex;
    cursor: pointer;

}

.topmenu-tagsbutton{
    color: $darkTextColor;
    padding:5px;
    border-radius: 5px;
    margin-left:10px;
    &:hover{
        &.dark{
            background-color: $darkTextboxColor;
        }
        &.light{
            background-color: $textboxColor;
        }
        
    }
}

.topmenu-title{
    font-size: 20px;
    width: fit-content;
    margin-left: 20px;
    &.dark{
        color: white;
        &:hover{
            border:1px solid $darkHover;
        }
        &:focus{
            border:1px solid $desklampButtonBg
        }
    }
    &.light{
        color: black;
        &:hover{
            border:1px solid $desklampGrey;
        }
        &:focus{
            border:1px solid $desklampButtonBg
        }
    }
    max-width: 280px;
    // border: 1px solid pink;
    border: 1px solid transparent;
    padding-left:5px;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    
    
}
.topmenu-title-ebook{
    font-size: 20px;
    width: fit-content;
    margin-left: 20px;
    &.dark{
        color: white;
        
    }
    &.light{
        color: black;
       
    }
    max-width: 280px;
    // border: 1px solid pink;
    border: 1px solid transparent;
    padding-left:5px;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    
    
}

.pagenav-container-topmenu{
    // border: 1px solid pink;
    &.dark{
        color: white;
    }
    &.light{
        color: black;
    }
    width: fit-content;
}

.topmenu-right-label{
    margin-right: 20px;
}

.topmenu-drop-input{
    width: 75px;
    text-align: center;
    -webkit-user-select: none;
    display: inline-block;
    &.dark{
        background-color: $darkHover;
    }
    &.light{
        background-color: #E2E1F3;
    }
    // background-color: $darkHover;
    // width: 70px;
    padding:4px;
    padding-left: 6px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    user-select: none;
    cursor: pointer;
    &.dark{
        color: white;
    }
    // color: white;
    &.light{
        color: black;
    }
}

.topmenu-workspace-dropdown{
    -webkit-user-select: none;
    position: absolute;
    // animation-name: dropdown;
    // animation-duration: 0.2s;
    box-shadow: $boxShadow;
    top:30px;
    z-index:20;
    &.dark{
        background-color: $darkHover;
    }
    &.light{
        background-color: #E2E1F3;
    }
    
    width: 100px;
    // border-radius: 5px;
    padding-bottom: 5px;
    user-select: none;

    .workspace-dropdown-option{
        font-size: 14px;
        margin-top:3px;
        margin-bottom:5px;

        &:hover{
            &.dark{
                background-color: $darkActiveButtonColor;
            }
            &.light{
                background-color: $lightHover;
            }
            
        }
    }

}

.wokspace-dropdown-selector{
    position: relative;
}


.findArrow-topmenu-right{
    &.dark{
        background-color: $darkHover;
    }
    &.light{
        background-color: #E2E1F3;
    }
    // background-color: $darkHover;
    padding: 9px;
    padding-left: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 20px;
    cursor: pointer;
}

.topmenu-button{
    border-radius: 5px;
    background-color: $desklampButtonBg;
    padding: 4px;
    padding-right: 15px;
    padding-left: 15px;
    // margin-left: 20px;
    font-size: 14px;
    cursor: pointer;
    font-weight: bold;
    margin-right: 10px;
    &.dark{
        color: white;
    }
    &.light{
        color: white
    }

}

.share-button-topmenu{
    margin-right: 50px;
    &.disabled{
        background-color: grey;
    }
}

.sharingBox-screen{
    position: fixed;
    z-index: 20;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.411) ;
}

.sharingBox-container{
    /* Center vertically and horizontally */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -30px;
    // max-height: 100px;
}

// .zoom-dropdown{
//     &.dark{
//         background-color: $darkDesklampGrey;
//     }
//     &.light{
//         background-color: lavender;
//     }
// }
.topmenu-dropdown{
    animation-name: popup;
    animation-duration: 0.2s;
    position: absolute;

    // background-color: #313941;
    &.dark{
        background-color: $darkDesklampGrey;
    }
    &.light{
        background-color: $lightTopmenuColor;
    }
    z-index: 8;
    margin-top: 15px;
    text-align: left;
    &.dark{
        color: white;

    }
    &.light{
        color: black;
        
    }
    // color:white;
    font-size:14px;
    border-radius:5px;
    width:$dropdownWidth;
    user-select: none;
    padding-bottom:5px;
    box-shadow: $boxShadow;

    
 
}

.dropdown-arrow{

    &.dark{
        &::before{
            border-bottom: $arrowSize solid #313941;     
        }
    }
    &.light{
        &::before{
            border-bottom: $arrowSize solid $lightTopmenuColor;     
        }
    }

    &::before {
        content: "";

        display: block;
        position: absolute;
        right: $dropdownWidth*0.5;
        top: -$arrowSize;
        &.dark{
            border-bottom: $arrowSize solid #313941;
        }
        &.light{
            border-bottom: $arrowSize solid lavender;
        }
        
        border-left: $arrowSize solid transparent;
        border-right: $arrowSize solid transparent;
   }

}



.topmenu-dropdown-input{
    &.dark{
        color: white;
        &::placeholder{
            color:rgb(219, 219, 219)
        }
        background-color:$darkTextboxColor ;
        border:1px solid $darkHover;

    }
    &.light{
        color: black;
        &::placeholder{
            color:rgb(36, 36, 36)
        }
        background-color: lavender;
        border:1px solid $desklampGrey;

    }
    // color:white;
    width:90%;
    
    border-radius: 5px;
    &:focus{
        border:1px solid $desklampButtonBg;
    }
    // margin-left:20px;
    // margin-right:20px;
    margin-top:10px;
    padding-top:3px;
    padding-bottom:3px;
}

.topmenu-tags-dropdown{
    margin-left:125px;
    width: 250px;
    padding:10px;
    :before{
        right: 125px;
    }
}

.tags-dropdown-input{
    width: 80%;
}

.topmenu-dropdown-button{
    background-color: $desklampButtonBg;
    padding: 6px;
    border-radius:5px;
    color:white;
    margin-left:5px;
    cursor: pointer;

}

.existing-tags-container{
    margin-top:10px;
}

.tagname{
    font-size: 14px;
    display:inline-flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 3px;
    padding-left: 5px;
    margin: 3px;
    margin-left:0px;
    margin-right:5px;
    border-radius: 5px;
    &.dark{
        background-color: $darkTextboxColor;
    }
    &.light{
        background-color: $textboxColor;
    }
    // background-color: $darkTextboxColor
    
}

.deletetag-btn{
    width: 8px;
    height: 8px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 3px;
    border-radius: 50%;
    &:hover{
        background-color:$desklampButtonBg;
    }
    // &:hover{
    // &.dark{
    //     background-color:$darkDesklampGrey;
    // }
    // &.light{
    //     background-color: $lightDesklampGrey;
    // }
    // }
}

.searchtag-results-dropdown{
    position: absolute;
    display: block;
    margin-top: 2px;
    // border-radius: 3px;
    font-size: 14px;
    box-shadow: $boxShadow;

    background-color: $desklampButtonBg;
    

   
    
    // &.light{
    //     background-color: $readerBgColor;

    //     .tag-suggestion{
    //         &:hover{
    //             background-color: $lightHover;
    //         }
    //     }
    // }

    // &.dark{
    //     background-color: $darkReaderBgColor;

    //     .tag-suggestion{
    //         &:hover{
    //             background-color: $darkHover;
    //         }
    //     }
    // }

    .tag-suggestion{
        padding: 7px;
        &:hover{
            background-color: $lightHover;
        }
    }
}

.edit-tags-button{
    width: 12px;
    height:12px;
    margin-left: 10px;
    // cursor: pointer;
    opacity:0.7;
    &:hover{
        opacity: 1;
    }
}
.tags-dropdown-message{
    &.dark{
        color: white;
    }
    &.light{
        color: black;
    }
}

.no-share{
    margin-right: 50px;
}


@media screen and (max-width: 850px) {
    .topmenu-center{
        display: none;
        background-color: pink;
    }
    
  }