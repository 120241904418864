@import "variables";
@import "fonts";
@import "colors";
// iframe
// {
//     display: none;
// }


html*{
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}

img{
    &.l{
        width: 40px;
        height: auto;
    }
    &.md{
        width: 32px;
        height: auto;

    }
}

.kitab-button{
    font-size:$contentSize;
    height: 1.5*$contentSize;
    cursor: pointer;
    border-radius: 4px;
  }
  
  
  .kitab-search{
    padding-left:30px;
    height: 35px;
}


html{
  // transition: $transitiontime;
  font-family: 'PT_Sans';
  overflow: hidden;
  &.dark{
      background-color: $darkReaderBgColor;
    }
  &.light{
      background-color: $readerBgColor;
    }
}



.kitab-button{
    &.dark{
      background-color: $darkDesklampButtonBg;
      // color:$readerBgColor
      color: white;
    }
    &.light{
      background-color: $desklampButtonBg;
      // color:$darkReaderBgColor;
      color: white;
    }
}
  
.kitab-input{
  font-size:$contentSize;
  box-sizing: border-box;
  border-radius: 4px;
  // float:left;
  // width: 450px;
  // transition:background-color $transitiontime;
    &.dark{
      &::placeholder{
      }
      background-color: $darkTextboxColor;
      border: 2px solid $darkDesklampGrey;
      color: $readerBgColor;
      &:focus{
        border: 2px solid $darkDesklampButtonBg;
      }
    }

    &.light{
      &::placeholder{
      }
      background-color: $textboxColor;
      border: 2px solid $desklampGrey;
      color: $darkReaderBgColor;
  
  
      &:focus{
        border: 2px solid $desklampButtonBg;
      }
    }
}

.kitab-input-showlist{
    &.dark{
      color: $readerBgColor;
    }
    &.light{
      color: $darkReaderBgColor;
    }
}  
    
.kitab-search{
    &.light{
      color: $darkReaderBgColor;
    }  
}

.profile-btn{
  position:absolute;
  bottom:10px;
  
  cursor:pointer;
  // left: defined in kitab-web and kitab-electron ;
  height:35px;
  width:35px;
  padding:0.1*$iconSize;
  border-radius:3px;
  
  &.profile-initials{
    height:auto;
    width:auto;
    left: 1px;
    padding: 5px;
    margin:0;
    // transition: background-color $transitiontime;
    &.light{
      background-color: $clipboardColor;
    }

    &.dark{
        background-color: $darkClipboardColor;
    }
  }
  
}

.support-toolbar-btn{
    bottom: 64px;
    height: 24px;
    width: 24px;
  }

.beta-text{
    margin-left: 10px ;
    &.light{
        color: $menuBgColor
    }
    &.dark{
        color: $lightHover
    }
}

.hide {
    display: none;
  }
  

.cet-titlebar,
.cet-titlebar > * {
    font-family: 'PT_Sans';
}



