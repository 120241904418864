.dynamic-input {
  background-color: transparent;
  font-size: 18px;
  display: inline-block;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
}
.dynamic-input.dark {
  color: white;
}
.dynamic-input.light {
  color: black;
}/*# sourceMappingURL=DynamicInput.css.map */