@import '../../styles/variables';

.landing-container{
    // position: absolute;
    // transform: translate(50%);
    &.light{
        background-color: $darkClipboardColor;
    }
    &.dark{
        background-color: $clipboardColor;
    }
}

.logo-header{
    margin-top: 50px;
}

.ledxLogo{
    height: 50px;
    margin-bottom: 20px;
}