.imageLabelInput {
  background-color: transparent;
  width: 100%;
  text-align: center;
  font-weight: bold;
  z-index: 9;
  margin-bottom: 10px;
  border-radius: 5px;
}
.imageLabelInput:focus {
  border: 1px solid grey;
}
.imageLabelInput.dark {
  color: white;
}
.imageLabelInput.light {
  color: black;
}/*# sourceMappingURL=ClipboardImage.css.map */