/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.radio-container {
  margin: 0 auto;
  margin-top: 10px;
  width: 140px;
  height: 30px;
  border-radius: 5px;
  padding: 0px 7px;
  line-height: 30px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */
}
.radio-container.dark {
  background-color: #444758;
}
.radio-container.light {
  background-color: #e6e5ee;
}

.radio-span-2 {
  padding: 15px;
  padding-left: 0;
  padding-right: 0;
}

.radio-option-1 {
  line-height: 30px;
  float: left;
}
.radio-option-1.dark {
  color: #f2f2f7;
}
.radio-option-1.light {
  color: #34353f;
}

.radio-option-2 {
  line-height: 30px;
  float: right;
}
.radio-option-2.dark {
  color: #f2f2f7;
}
.radio-option-2.light {
  color: #34353f;
}

.radio-bg {
  line-height: 30px;
  height: 22.5px;
  margin-top: 3.75px;
  width: 70px;
  position: absolute;
  top: 0;
  z-index: -1;
  border-radius: 5px;
}
.radio-bg.radio-bg-2.dark {
  background-color: #34353f;
}
.radio-bg.radio-bg-2.light {
  background-color: #f2f2f7;
}

.radio-span:hover {
  font-weight: bold;
}/*# sourceMappingURL=RadioSelector.css.map */