/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
@-webkit-keyframes popup {
  0% {
    transform: scale(0.1, 0.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes popup {
  0% {
    transform: scale(0.1, 0.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes dropdown {
  0% {
    top: -20px;
  }
  100% {
    top: 30px;
  }
}
@keyframes dropdown {
  0% {
    top: -20px;
  }
  100% {
    top: 30px;
  }
}
.topmenu {
  width: 100%;
  height: 40px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  top: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.topmenu.dark {
  background-color: #323344;
  box-shadow: inset 0px -1px 0px 0px #313941;
}
.topmenu.light {
  background-color: #F4F2F2;
  box-shadow: inset 0px -1px 0px 0px #c2c2c4;
}

.topmenu-right {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.topmenu-right.dark {
  color: white;
}
.topmenu-right.light {
  color: black;
}

.topmenu-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.topmenu-center .topmenu-center-item {
  margin-left: 10px;
  margin-right: 10px;
}

.topmenu-left {
  margin-left: 10px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-menu-icon {
  height: 20px;
  width: 20px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
}

.topmenu-tagsbutton {
  color: #f2f2f7;
  padding: 5px;
  border-radius: 5px;
  margin-left: 10px;
}
.topmenu-tagsbutton:hover.dark {
  background-color: #444758;
}
.topmenu-tagsbutton:hover.light {
  background-color: #D8D5FA;
}

.topmenu-title {
  font-size: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 20px;
  max-width: 280px;
  border: 1px solid transparent;
  padding-left: 5px;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.topmenu-title.dark {
  color: white;
}
.topmenu-title.dark:hover {
  border: 1px solid #4e4e58;
}
.topmenu-title.dark:focus {
  border: 1px solid #665AEF;
}
.topmenu-title.light {
  color: black;
}
.topmenu-title.light:hover {
  border: 1px solid #C4C4C4;
}
.topmenu-title.light:focus {
  border: 1px solid #665AEF;
}

.topmenu-title-ebook {
  font-size: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 20px;
  max-width: 280px;
  border: 1px solid transparent;
  padding-left: 5px;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.topmenu-title-ebook.dark {
  color: white;
}
.topmenu-title-ebook.light {
  color: black;
}

.pagenav-container-topmenu {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.pagenav-container-topmenu.dark {
  color: white;
}
.pagenav-container-topmenu.light {
  color: black;
}

.topmenu-right-label {
  margin-right: 20px;
}

.topmenu-drop-input {
  width: 75px;
  text-align: center;
  -webkit-user-select: none;
  display: inline-block;
  padding: 4px;
  padding-left: 6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  cursor: pointer;
}
.topmenu-drop-input.dark {
  background-color: #4e4e58;
}
.topmenu-drop-input.light {
  background-color: #E2E1F3;
}
.topmenu-drop-input.dark {
  color: white;
}
.topmenu-drop-input.light {
  color: black;
}

.topmenu-workspace-dropdown {
  -webkit-user-select: none;
  position: absolute;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  top: 30px;
  z-index: 20;
  width: 100px;
  padding-bottom: 5px;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}
.topmenu-workspace-dropdown.dark {
  background-color: #4e4e58;
}
.topmenu-workspace-dropdown.light {
  background-color: #E2E1F3;
}
.topmenu-workspace-dropdown .workspace-dropdown-option {
  font-size: 14px;
  margin-top: 3px;
  margin-bottom: 5px;
}
.topmenu-workspace-dropdown .workspace-dropdown-option:hover.dark {
  background-color: rgba(196, 196, 196, 0.4862745098);
}
.topmenu-workspace-dropdown .workspace-dropdown-option:hover.light {
  background-color: #BAB6F5;
}

.wokspace-dropdown-selector {
  position: relative;
}

.findArrow-topmenu-right {
  padding: 9px;
  padding-left: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-right: 20px;
  cursor: pointer;
}
.findArrow-topmenu-right.dark {
  background-color: #4e4e58;
}
.findArrow-topmenu-right.light {
  background-color: #E2E1F3;
}

.topmenu-button {
  border-radius: 5px;
  background-color: #665AEF;
  padding: 4px;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
}
.topmenu-button.dark {
  color: white;
}
.topmenu-button.light {
  color: white;
}

.share-button-topmenu {
  margin-right: 50px;
}
.share-button-topmenu.disabled {
  background-color: grey;
}

.sharingBox-screen {
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.411);
}

.sharingBox-container {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -30px;
}

.topmenu-dropdown {
  -webkit-animation-name: popup;
          animation-name: popup;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  position: absolute;
  z-index: 8;
  margin-top: 15px;
  text-align: left;
  font-size: 14px;
  border-radius: 5px;
  width: 160px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-bottom: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}
.topmenu-dropdown.dark {
  background-color: #313941;
}
.topmenu-dropdown.light {
  background-color: #F4F2F2;
}
.topmenu-dropdown.dark {
  color: white;
}
.topmenu-dropdown.light {
  color: black;
}

.dropdown-arrow.dark::before {
  border-bottom: 8px solid #313941;
}
.dropdown-arrow.light::before {
  border-bottom: 8px solid #F4F2F2;
}
.dropdown-arrow::before {
  content: "";
  display: block;
  position: absolute;
  right: 80px;
  top: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.dropdown-arrow::before.dark {
  border-bottom: 8px solid #313941;
}
.dropdown-arrow::before.light {
  border-bottom: 8px solid lavender;
}

.topmenu-dropdown-input {
  width: 90%;
  border-radius: 5px;
  margin-top: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.topmenu-dropdown-input.dark {
  color: white;
  background-color: #444758;
  border: 1px solid #4e4e58;
}
.topmenu-dropdown-input.dark::-moz-placeholder {
  color: rgb(219, 219, 219);
}
.topmenu-dropdown-input.dark:-ms-input-placeholder {
  color: rgb(219, 219, 219);
}
.topmenu-dropdown-input.dark::placeholder {
  color: rgb(219, 219, 219);
}
.topmenu-dropdown-input.light {
  color: black;
  background-color: lavender;
  border: 1px solid #C4C4C4;
}
.topmenu-dropdown-input.light::-moz-placeholder {
  color: rgb(36, 36, 36);
}
.topmenu-dropdown-input.light:-ms-input-placeholder {
  color: rgb(36, 36, 36);
}
.topmenu-dropdown-input.light::placeholder {
  color: rgb(36, 36, 36);
}
.topmenu-dropdown-input:focus {
  border: 1px solid #665AEF;
}

.topmenu-tags-dropdown {
  margin-left: 125px;
  width: 250px;
  padding: 10px;
}
.topmenu-tags-dropdown :before {
  right: 125px;
}

.tags-dropdown-input {
  width: 80%;
}

.topmenu-dropdown-button {
  background-color: #665AEF;
  padding: 6px;
  border-radius: 5px;
  color: white;
  margin-left: 5px;
  cursor: pointer;
}

.existing-tags-container {
  margin-top: 10px;
}

.tagname {
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
  padding-left: 5px;
  margin: 3px;
  margin-left: 0px;
  margin-right: 5px;
  border-radius: 5px;
}
.tagname.dark {
  background-color: #444758;
}
.tagname.light {
  background-color: #D8D5FA;
}

.deletetag-btn {
  width: 8px;
  height: 8px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 3px;
  border-radius: 50%;
}
.deletetag-btn:hover {
  background-color: #665AEF;
}

.searchtag-results-dropdown {
  position: absolute;
  display: block;
  margin-top: 2px;
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  background-color: #665AEF;
}
.searchtag-results-dropdown .tag-suggestion {
  padding: 7px;
}
.searchtag-results-dropdown .tag-suggestion:hover {
  background-color: #BAB6F5;
}

.edit-tags-button {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  opacity: 0.7;
}
.edit-tags-button:hover {
  opacity: 1;
}

.tags-dropdown-message.dark {
  color: white;
}
.tags-dropdown-message.light {
  color: black;
}

.no-share {
  margin-right: 50px;
}

@media screen and (max-width: 850px) {
  .topmenu-center {
    display: none;
    background-color: pink;
  }
}/*# sourceMappingURL=TopMenu.css.map */