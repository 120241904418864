@import "../../styles/variables";
$lookupTriangle: 15px;
.lookup-container{
    position: absolute;
    height: fit-content;
    padding-bottom: 10px;
}
.contents-container{
    padding: 3px;
    height: fit-content;
}
.lookup-box{
    overflow-y: auto;
    overflow-x: none;
    padding-top: 4px;
    padding-left: 10px;
    &.dark{
        background-color: $darkClipboardColor;
    }
    &.light{
        background-color: $clipboardColor;
    }

    &.none{
        &.top{
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }  
    }
    &.left{
        &.top{
            border-top-right-radius: 5px;
        }
    }
    // border-radius: 5px;

    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}

.lookup-triangle{
    height: 0px;
    width: 0px;
    z-index: inherit;
    position: relative;
    border: $lookupTriangle solid;
    &.top{
        border-bottom-color: transparent;
        &.dark{
            border-top-color: $darkClipboardColor;
        }
        &.light{
            border-top-color: $clipboardColor;
        }
    }
    &.bottom{
        border-top-color: transparent;
        &.dark{
            border-bottom-color: $darkClipboardColor;
        }
        &.light{
            border-bottom-color: $clipboardColor;
        }
    }
    
    border-left-color: transparent;
    border-right-color: transparent;

    &.left{
        left: 0%;
    }
    &.none{
        left: 45%;
    }
    
    
}

.lookup-text{
    font-size: 14px;
    margin-bottom: 3px;
    &.dark{
        color: $readerBgColor;
    }
    &.light{
        color: $darkReaderBgColor

    }
}

.lookup-button{
    margin: 0px auto;
    background-color: transparent;
    &.dark{
        color: $readerBgColor;
    }
    &.light{
        color: $darkReaderBgColor
    }

    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
    &.activeButton{
        font-weight: bold;
    }
}

.lookup-button-container{
    &.none{
        &.bottom{
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }  
    }
    &.left{
        &.bottom{
            border-bottom-right-radius: 5px;
        }
    }
    
    padding-bottom: 5px;
    display: flex;
    &.dark{
        background-color: $darkClipboardColor;
    }
    &.light{
        background-color: $clipboardColor;
    }

}

.lookup-load{
    position: relative;
    top:50%;
    height: 50px;
    width: 50px;
}

.lookup-section-header{
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 2px;
    font-weight: bolder;
}
.lookup-section-content{
    height: fit-content;
    margin-top: 2px;
}
.loading-container{
    position: relative;
    top: 40%;
    // left: 40%;
    margin: 0px auto;
    width: fit-content;
    height: fit-content;
    display: block;
    align-items: center;
    justify-content: center;
}

.lookup-hr{
    position: relative;
    top: 0%;
    width:100%;
    height: 1px;
    border: none;
    margin-bottom:5px;
    &.dark{
        background-color: $readerBgColor;
    }
    &.light{
        background-color: $darkReaderBgColor;
    }
    &.sub{
        opacity: 0.3;
    }

    
}

.lookup-definition-pos{
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 6px;
}

// .lookup-definition-example{
//     font-style: italic;
// }

.lookup-synonym{
    padding-right: 2px;
}

.lookup-word{
    font-size: 18px;
    display: inline;
}

.lookup-meaning-subtitle{
    // float: left;
    padding-right: 3px;
    // font-weight: bold;
}

.meaning-container{
    margin-left: 10px;
}

.minibold{
    font-weight: bold;
    font-style: italic;

}

.lookup-goto-button{
    height: 10px;
    width: 10px;
    font-size: 14px;
    &.dark{
        color: $readerBgColor;
    }
    &.light{
        color: $darkReaderBgColor;
    }
}

.phonetic{
    font-size: 14px;
    margin-left: 10px;
    &.light{
        color: $darkDesklampGrey
    }
    &.dark{
        color: $desklampGrey;
    }

}

.lookup-box::-webkit-scrollbar {
    height: 8px;
    width: 5px;
  }
  
  /* Track */
  .lookup-box::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  .lookup-box::-webkit-scrollbar-thumb {
    background: darkgrey;
    width: 2px;
    border-radius: 4px;
  }
  
  /* Handle on hover */
  .lookup-box::-webkit-scrollbar-thumb:hover {
    background: #5c5c5c;
  }

  .oops-message{
      padding-top: 20px;
      &.dark{
        color: $darkTextboxColor;
    }
    &.light{
        color: $textboxColor

    }
    font-size: 16px;
  }

  .oops-image{
      position: relative;
      left: 30%;
      margin-top: 10px;
      margin-bottom: 10px;
      height: 100px;
      width: 100px;
  }

  .oops-container{
      position: relative;
      margin-top: 50px;
  }

  .wiki-word{
      margin-bottom: 5px;
  }