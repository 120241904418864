/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
@font-face {
  font-family: "PT_Sans";
  src: local("PT_Sans"), url("../../../common-components/src/styles/fonts/PT_Sans/PTSans-Regular.ttf");
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
html* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

img.l {
  width: 40px;
  height: auto;
}
img.md {
  width: 32px;
  height: auto;
}

.kitab-button {
  font-size: 16px;
  height: 24px;
  cursor: pointer;
  border-radius: 4px;
}

.kitab-search {
  padding-left: 30px;
  height: 35px;
}

html {
  font-family: "PT_Sans";
  overflow: hidden;
}
html.dark {
  background-color: #34353f;
}
html.light {
  background-color: #f2f2f7;
}

.kitab-button.dark {
  background-color: #444758;
  color: white;
}
.kitab-button.light {
  background-color: #665AEF;
  color: white;
}

.kitab-input {
  font-size: 16px;
  box-sizing: border-box;
  border-radius: 4px;
}
.kitab-input.dark {
  background-color: #444758;
  border: 2px solid #313941;
  color: #f2f2f7;
}
.kitab-input.dark:focus {
  border: 2px solid #444758;
}
.kitab-input.light {
  background-color: #D8D5FA;
  border: 2px solid #C4C4C4;
  color: #34353f;
}
.kitab-input.light:focus {
  border: 2px solid #665AEF;
}

.kitab-input-showlist.dark {
  color: #f2f2f7;
}
.kitab-input-showlist.light {
  color: #34353f;
}

.kitab-search.light {
  color: #34353f;
}

.profile-btn {
  position: absolute;
  bottom: 10px;
  cursor: pointer;
  height: 35px;
  width: 35px;
  padding: 3.6px;
  border-radius: 3px;
}
.profile-btn.profile-initials {
  height: auto;
  width: auto;
  left: 1px;
  padding: 5px;
  margin: 0;
}
.profile-btn.profile-initials.light {
  background-color: #e6e5ee;
}
.profile-btn.profile-initials.dark {
  background-color: #212231;
}

.support-toolbar-btn {
  bottom: 64px;
  height: 24px;
  width: 24px;
}

.beta-text {
  margin-left: 10px;
}
.beta-text.light {
  color: #665AEF;
}
.beta-text.dark {
  color: #BAB6F5;
}

.hide {
  display: none;
}

.cet-titlebar,
.cet-titlebar > * {
  font-family: "PT_Sans";
}/*# sourceMappingURL=App.css.map */