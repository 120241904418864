
@import '../../styles/colors';

.gen-form-caption{
    font-size: 16px;
    font-family: 'Poppins';
    margin-bottom: 20px;

    &.gen-form-error{
        color: $lightModeRed;
    }

    &.gen-form-success{
        color: $lightModeGreen;

    }

}