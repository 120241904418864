@import "../../styles/variables";

// $color: rgba(0, 0, 0, 0.664);

// @keyframes popup {
//     0%   {transform: scale(0.1,0.1);}
//     100% {transform: scale(1,1);}
// }

$color: transparentize($darkTextboxColor, 0.2);
$lightColor: $lightDesklampGrey;
$darkColor: $darkDesklampGrey;

.tooltip-container{

    user-select: none;
    border-radius: 5px;
    position:absolute;
    text-align: left;
    // animation-name: popup;
    // animation-duration: 0.2s;
    margin-left:10px;
    // margin-bottom: 50px;
    box-shadow: $boxShadow;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 30;
    // overflow-y: hidden;

    &.tooltip{
        padding-left: 20px;
        padding-right: 20px;
        
        &:after{
            content: " ";
            position: absolute;
            left: -10px;
            top: 15px;
            border-top: 10px solid transparent;
            border-left: none;
            border-bottom: 10px solid transparent;
            // box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
            box-shadow: 0 -5px 5px -5px pink, 
                0 5px 5px -5px #333, 
                -5px 0 5px -5px #333;
        }
    }

    &.dark{
        background-color: $darkColor;
        &:after{
            border-right: 10px solid $darkColor ;
        }
    }

    &.light{
        color: $darkReaderBgColor;
        background-color: $lightColor;
        &:after{
            border-right: 10px solid $lightColor ;
        }
    }


    
}

.tooltip-heading{
    font-size:14px;
    padding:5px;
    margin-bottom: 0px;
    margin-top:5px;
    padding-bottom: 0px;
    text-align: center;
    &.dark{
        color:$clipboardColor
    }

}

.tooltip-hr{
    margin-left: 5px;
    margin-right: 5px;
    // margin-top: 5px;
}

.tooltip-options{
    margin-bottom:10px;
    margin-top: 10px;
    &.centering{
        text-align: center;
    }

    &.context{
        padding-left: 10px;
        padding-right: 0px;    
    }


    &.dark{
        color: $readerBgColor;
    }
    // &.light{
    //     background-color: $;
    // }
}

.color-btn-container{
    
    display: inline-flex;
    margin: 2px;
    width: 20px;
    height: 20px;
    border:1px solid black;
    border-radius: 50%;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 2px;
}

.btn-color{
    width: 30px;
    margin: -2px;
}

.active-color{
    &.dark{
        border: 2px solid $readerBgColor;
    }
    &.light{
        border: 2px solid $darkReaderBgColor;
    }
}

.inactive-color:hover{
    &.dark{
        border: 2px solid $readerBgColor;
    }
    &.light{
        border: 2px solid $darkReaderBgColor;
    }
}


.colorSelector-container{
    // margin-left: $toolbarWidth + 100px;
    // margin-top: 5px;
    // text-align: left;
    // margin-left: 10px;
    
    display:inline-block;
    width:calc(120px - 15px);
    // width: fit-content;
    height:30px;
    z-index:1;
    transition:0.3s;
    text-align: left;
    // transform: translateX(-230px);
}

.radio-btn-container{
    width: 21px;
    height: 21px;
    display: inline-flex;
    
    justify-content: center;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    &.active-option{
        border: 1px solid black;
    }
}

.tooltip-radio-icon{
    width: 15px;
    height: 15px;
    padding: 1px;
    border-radius: 50%;
    background-color: white;

    &.active-option{
        &.dark{
            border: 2px solid $readerBgColor;
        }
        &.light{
            border: 2px solid $darkReaderBgColor;
        }
    }
    
    &.inactive-option:hover{
        &.dark{
            border: 2px solid $readerBgColor;
        }
        &.light{
            border: 2px solid $darkReaderBgColor;
        }
    }
}

.sticker-container{
    width: fit-content;
    height: fit-content;
    display: inline-block;
    padding: 2px;
    border-radius: 7px;
    margin: 2px;
    &.active-sticker{
        &.dark{
            background-color: $darkHover;
        }
        &.light{
            background-color: $lightHover;
        }
    }
    &.active-postit{
        &.dark{
            background-color: $darkHover;
        }
        &.light{
            background-color: $lightHover;
        }
    }
}

.sticker-img{
    width: 32px;
    height: 32px;
    display: inline;
    margin-left:5px;
    margin-right:5px;
    border: 1px solid transparent;
    

    &.emoji{
        width: 24px;
        height: 24px;
    }

    // &.active-sticker{
    //     border-radius: 50%;
    //     box-shadow: $boxShadow;
    // }

    // &.active-postit{
    //     box-shadow: $boxShadow;
    // }
    
}

.sticker-collection{
    padding-top:10px;
    width: 150px;
    display: inline-block;
    padding-bottom:10px;
    margin-top:10px;
    margin-bottom: 10px;
    text-align: center;
}

.tooltip-radio-container{
    // border: 1px solid pink;
    margin-bottom: 0px ;

}

.tooltip-dropdown{
    padding: 3px;
    border-radius: 5px;
}

.tooltip-radio-options{
    padding: 5px;
    display: inline-flex;
    // border: 1px solid pink;
    justify-content: center;
    align-items: center;
}

.tooltip-label{
    // display: inline;
    // border: 1px solid black;
    font-size: 14px;
    margin: 0 auto;
    margin-bottom: 5px;
    margin-top: 5px;
    text-align: left;
}


.tooltip-selector{
    width: 120px;
    font-size: 14px;
    user-select: none;
    &.dark{
        background-color: $darkClipboardColor;
    }
    &.light{
        background-color: $clipboardColor;
    }
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 10px;
    
    
    .selector-option{
        // border: 1px solid blue;
        display: inline-block;
        text-align: center;
        padding: 2px;
        width: 45%;
        &.left{
            border-radius:5px ;
            // border-bottom-left-radius:5px ;
        }
        &.right{
            border-radius:5px ;
            // border-bottom-right-radius:5px ;
        }
        // &.dark{
        //     border: 1px solid grey
        // }
        // &.light{
        //     border: 1px solid grey
        // }

        &.active-option{
            &.light{
                background-color: $lightHover;
            }
            &.dark{
                background-color: $darkHover;
            }
        }
        &.inactive-option{
            &.light{
                // background-color: #f8f9fa;
            }
            &.dark{

            }
        }
    }
}


//-------------SLIDER-------


.tooltip-slider-container {
    width: 90%; /* Width of the outside container */
  }
  
  /* The slider itself */
  .tooltip-slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 10px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 10px;
    position:static;
  }
  
  /* Mouse-over effects */
  .tooltip-slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
  }
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .tooltip-slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 10px; /* Set a specific slider handle width */
    height: 10px; /* Slider handle height */
    border-radius: 50%;
    background: $desklampButtonBg; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }
  
  .tooltip-slider::-moz-range-thumb {
    width: 10px; /* Set a specific slider handle width */
    height: 10px; /* Slider handle height */
    background: $desklampButtonBg; /* Green background */
    cursor: pointer; /* Cursor on hover */
  }