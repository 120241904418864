/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.notebook-menu-container {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 10px;
  height: 100%;
}
.notebook-menu-container.dark {
  color: white;
}

.notebook-menu-header {
  height: 30px;
  margin-bottom: 20px;
}
.notebook-menu-header .add-new-nb {
  border-radius: 6px;
  padding: 2px;
  margin-left: 15px;
  padding-left: 5px;
  padding-right: 5px;
  display: inline;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.notebook-menu-header .add-new-nb.dark {
  background-color: #4e4e58;
}
.notebook-menu-header .add-new-nb.light {
  background-color: #BAB6F5;
}
.notebook-menu-header .add-new-nb .add-new-label {
  margin: 0 auto;
  display: inline;
  margin-right: 10px;
  font-size: 14px;
}
.notebook-menu-header .add-new-nb .add-new-icon {
  width: 12px;
  height: 12px;
}

.nb-list-container::-webkit-scrollbar {
  height: 8px;
  width: 9px;
}

/* Track */
.nb-list-container::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.nb-list-container::-webkit-scrollbar-thumb {
  background: darkgrey;
  width: 2px;
  border-radius: 4px;
}

/* Handle on hover */
.nb-list-container::-webkit-scrollbar-thumb:hover {
  background: rgb(92, 92, 92);
}

.nb-list-container {
  overflow-y: auto;
  height: calc(100% - 80px);
}

.notebook-tips-container {
  margin-top: 40px;
  font-style: italic;
}

.nb-item {
  text-align: left;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}
.nb-item.row {
  display: flex;
}
.nb-item.row .nb-item-column {
  display: flex;
  align-items: center;
}
.nb-item.row .nb-item-column:after {
  content: "";
  display: table;
  clear: both;
}
.nb-item.light {
  background-color: #f2f2f7;
}
.nb-item.light.public {
  background-color: #78BC61;
}
.nb-item.light:hover {
  background-color: #BAB6F5;
}
.nb-item.dark {
  background-color: #34353f;
}
.nb-item.dark.public {
  background-color: green;
}
.nb-item.dark:hover {
  background-color: #4e4e58;
}
.nb-item .nb-item-title {
  display: inline;
  width: 90%;
}
.nb-item .nb-item-delete {
  float: right;
}
.nb-item .nb-item-delete.dark {
  color: #ffa69e;
}
.nb-item .nb-item-delete.light {
  color: #EC4E20;
}
.nb-item .nb-item-delete:hover {
  font-weight: bold;
  cursor: pointer;
}/*# sourceMappingURL=NotebookMenu.css.map */