/*Simple css to style it like a toggle switch*/
/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.theme-switch-wrapper {
  display: flex;
  align-items: center;
}
.theme-switch-wrapper em {
  margin-left: 10px;
  font-size: 1rem;
}

.toggle-img {
  height: 14px;
  width: 14px;
}
.toggle-img.right {
  float: right;
  margin-left: 13px;
}
.toggle-img.left {
  float: left;
  margin-left: 10px;
}

.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
.slider.active-toggle {
  cursor: pointer;
}

.slider:before {
  background-color: #e6e5ee;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

.slider.active-toggle:before {
  background-color: #212231;
}

.slider.inactive-toggle:before {
  background-color: #C4C4C4;
}

.slider.static-toggle.dark:before {
  background-color: #212231;
}

.slider.static-toggle.light:before {
  background-color: #C4C4C4;
}

.slider.inactive-toggle.multi-toggle {
  background-color: #f2f2f7;
}
.slider.active-toggle.multi-toggle {
  background-color: #696b77;
}
.slider.static-toggle.dark {
  background-color: #696b77;
}
.slider.static-toggle.light {
  background-color: #f2f2f7;
}

.slider.active-toggle:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
  display: flex;
  align-items: center;
}

.slider.round:before {
  border-radius: 50%;
}/*# sourceMappingURL=ToggleSwitch.css.map */