@import "../../styles/variables";
$containerHeight: 28px;
.clipboard-loader{
    scrollbar-width: none;
    &.dark{
        background-color: $darkClipboardColor;
    }
    &.light{
        background-color: $clipboardColor;
    }
}
.clipboard-screenshot{
    // transition: background-color $transitiontime;
    filter:  var(--reader-theme);
    margin-bottom:-5px;
    pointer-events: none;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    // border:5px solid orange;
}

.clipboardImage-wrapper{
    padding: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.screenshotContainer{
    border:5px solid orange;
    padding-bottom: 500px;
}

.clipboard-images{
    z-index:1;
}

.empty-clipboard-space-bottom{
    z-index:0;
    width:130%;
    height:200px;
    position:absolute;
    // border:1px solid green;
}

div.clipboard-empty-message{
    top:35%;
    transform:(0,-50%);
    color:$clipboardMessageColor;

    &.dark{
        filter: invert(1);
    }
}

div.clipboard-empty-message>p{
    width:250px;
}


img.clipboard-starter-img{
    width:110px * 0.9;
    height:100px * 0.9;
}

.imageLabelInput{
    &.dark{
        color:$readerBgColor;
    }

    &.light{
        color:$darkReaderBgColor
    }
}

.clipboard-starter-header{
    font-size: 14px;
}
.clipboard-starter-msg{
    font-size: 14px;
}



