@import "../../styles/variables";

$container-width: 400px;
$fontSize: 24px;

.errorless-container {
  height: 160px;
}

.error-container {
  height: 180px;
}

.auth-offline-message {
  cursor: pointer;
  /* Center */
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 20%;
  color: red;
  font-size: 16px;
  width: 100%;
}

.auth-offline-message:hover {
  color: darkred;
}

.auth-container {
  width: $container-width;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  background-color: #f2f2f7;

  /* Center */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.auth-input {
  &:focus{
    border:1px solid $darkClipboardColor
  }
  width: 98%;
  border-radius: 3px;
  padding: 2px;
  margin-bottom: 8px;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.247);
  height: $fontSize;
  text-align: left;
}

.auth-header {
  color: black;
  // font-weight: bold;
  font-weight: 300;
  font-size: 24px;
  padding: 0px;
  margin-bottom: 8px;
  margin-top: 0px;
}
.password-button-container{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.error-signup {
  color: red;
  font-size: 20px;
}

.auth-submit {
  background-color: #665aef;
  height: $fontSize;
  width: 100px;
  padding:2px;
  margin: 0px auto;
  // margin-left: 10px;
  color: white;
  font-weight: bold;
  padding: 2px;
  margin-top: 8px;
  border-radius: 3px;
  // position: relative;
  // left: 50%;
  // transform: translate(-50%);
}

.auth-btn {
  cursor: pointer;
}

.auth-link {
  color: $menuBgColor;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-top: 8px;
  transform: translate(-50%);
  cursor: pointer;
}

.auth-link:hover {
  text-decoration: underline;
}

.password-hr {
  margin-top: 32px;
  margin-bottom: -8px;
}

.auth-secondary {
  background-color: $menuBgColor;
}

.auth-btn:hover {
  // background-color: $ligh;
  cursor: pointer;
}

div.signup {
  height: 280px;
}
.signup.error-container {
  height: 300px;
}

.confirm-signup.error-container {
  height: 200px;
}

.auth-error {
  color: red;
  margin: 0;
  font-size: 15px;
  margin-bottom: -6px;
}

.auth-loading {
  position: absolute;
  left: 50%;
  transform: translate(-46%);
  bottom: 20%;
  color: black;
  height: 40px;
  width: 40px;
}
