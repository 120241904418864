

.imageLabelInput{
    background-color: transparent;
    width:100%;
    text-align:center;
    font-weight: bold;
    z-index:9;
    margin-bottom:10px;
    border-radius: 5px;

    &:focus{
        border:1px solid grey;

    }

    &.dark{
        color: white;
    }

    &.light{
        color: black;
    }
}

