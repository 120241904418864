@import '../../styles/variables';

$permissionFontSize: 12px;

@keyframes popupSharing {
    0%   {opacity: 0.1;}
    25%   {opacity: 0.3;}
    50%   {opacity: 0.7;}
    100% {opacity: 1;}
}

.sharing-section-title{
    font-weight: bold;
    font-size: 18px;
    margin-top:5px;
    margin-bottom:10px;

}

.gen-access-subtitle{
    // margin-top: 5px;
    color: $darkDesklampGrey;
}

.close-sharingbox{
    width: 12px;
    height: 12px;
    position: absolute;
    right: 15px;
    top:35px;
    padding:5px;
    border-radius: 50%;
    &:hover{
        background-color: rgb(190, 190, 190);
    }
}

.sharingBar-container{
    animation-name: popupSharing;
    animation-duration: 0.2s;
    text-align:left;
    margin-left:20px;
    padding: 15px;
    border-radius: 5px;
    padding-right: 13px;
    user-select: none;
    margin-top: 20px;
    width: 600px;
    // height: 400px;
    z-index: 10;
    box-shadow: $boxShadow;
    padding-bottom:35px;

    // &.dark{
    //     background-color: white;
    // }

    // &.light{
        background-color: white;
    // }


}

.gen-access-container{
    margin-top: 20px;
    padding-bottom: 40px;

    .column{
        &.middle{
            margin-left: 20px;
        }
        &.left{
            margin-top: 5px;
        }
    }
}

.gen-access-header{
    width: fit-content;
    padding: 5px;
    margin-left: -5px;
    border-radius: 5px;
    &.active-edit{
        cursor: pointer;
        &:hover{
            background-color: $clipboardColor;
        }
    }
    
}

.gen-access-dropdown{
    
    position: absolute;
    background-color: white;
    box-shadow: $boxShadow;
    margin-top: 5px;
    padding-top: 10px;
    padding-bottom: 10px;

    border-radius: 5px;

    .access-option{
        padding: 8px;

        &:hover{
            background-color: $lightHover;
        }
    }

}

.link-right{
    float: right;
    margin-right: 10px;
    color: $menuBgColor;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    border: 1px solid $lightHover;
    padding: 10px;
    border-radius: 20px;
}

.gen-access-dropdown-arrow{
    height: 8px;
    width: 8px;
    margin-left: 5px;
    margin-bottom: 3px;
    transform: rotate(90deg);

}

.link-icon{
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.collaborators-input{
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: calc(100% - 100px);
    display: inline-block;

    
    background-color: $textboxColor;
    .sharingMenu-fileinput, .permission-dropdown{
        color: $darkReaderBgColor;
    }
    

    .permission-dropdown{
        background-color: $textboxColor;
    }

    .sharingMenu-fileinput{
        display: inline;
        width:calc(100% - 100px);
    }

    
}

.add-collaborator-button{
    // padding-left:10px;
    // padding-right:10px;
    // padding-top: 10px;
    // padding-bottom:10px;
    background-color: $desklampButtonBg;
    padding: 8px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    font-weight: bold;
    cursor: pointer;


}



.permission-dropdown{
    
    border: none;
    border-radius: 2px;
    outline: none;
    font-size: $permissionFontSize;
    width: 80px;

    // &.dark{
    //     color: $readerBgColor;
    // }

    // &.light{
        color: $darkReaderBgColor;
    // }
}

.send-menu-input{
    width:100%
}

.sidemenu-label{
    display:inline;
    margin:3px;
}

.send-button-sharingBar{
    float:right
}

.sharingBar-icon{
    height: 80px;
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.collaborators-container{
    margin-top: 30px;
    max-height: 400px;
    overflow-y: auto;
}

.collaborator-person{
    // border: 1px solid lightblue;
    margin-bottom: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    .column {

        .collab-initials{
            font-size: 14px;

            // &.dark{
            //     background-color:$darkTextboxColor  ;
            //     color: $textboxColor;
            // }
            // &.light{
                background-color:$menuBgColor ;
                color: $darkTextboxColor;
                color: white;
                font-weight: bold;

            // }
            // padding: 5px;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            display: inline-flex;
            text-align: center;
            align-items: center;
            justify-content: center;
        }

        float: left;
        &.left{
            width: 50px;
            height: 100%;
        }
        &.center{
            width: 550px;
            // margin: 0 auto;

            .collab-name{
                font-size: 15px;
                margin: 0 auto;
                margin-left: 20px;

                // &.dark{
                //     color: white;
                // }

                // &.light{
                    color: $darkDesklampGrey;
                // }

            }
            .collab-email{
                margin: 0 auto;
                font-size: 12px;
            }
        }
        // border: 1px solid pink;
    }
      
    /* Clear floats after the columns */
    &.row:after {

        content: "";
        display: table;
        clear: both;
    }
}

.permission-owner{
    font-size: $permissionFontSize;
}


.sharing-group{
    padding-top: 0px;
    // padding: 10px;
    
}

.sharing-loading{
    width: 50px;
    margin-left: 50px;
    margin-top: -20px;
}

.lock-icon-share{
    height: 24px;
    width: 24px;
    padding: 6px;
    background-color: $lightDesklampGrey;
    border-radius: 100%;
}