@import "../../styles/variables";

$nbBackScale: 0.05;







.nb-header{
    z-index: 2;
    // border: 1px solid green;
    width: 100%;
    // padding-left: 15px;
    // padding-right: 15px;
    // justify-content: center;
    // display: inline-block;
    // width: max-content;
    max-height: max-content;
    border-radius: 5px;
    // position: fixed;
    // width: 80%;
    &.light{
      background-color: $clipboardColor;
    }
    &.dark{
      background-color: $darkClipboardColor;
    }
    // transform: translateX(-52%);

    .nb-titlebar{
        display: flex;
        // border: 1px solid pink;
        align-items: center;
        justify-content: center;
        .nb-menu-icon{
            margin-left: 15px;
            cursor: pointer;
            width: 320px * $nbBackScale;
            height: 216px * $nbBackScale;
            padding: 5px;
            border-radius: 5px;
            position: absolute;
            left: 15px;

            &.light{
                background-color: $lightHover;
            }

            &.dark{
                background-color: $darkHover;
            }
        }
        .nb-public-icon{
          
          position: absolute;
          right: 30px;
          cursor: pointer;
          width: 225px * $nbBackScale;
          height: 225px * $nbBackScale;
          padding: 7px;
          border-radius: 5px;
          &.light{
              &.private{
                background-color: $lightHover ;
              }
              &.public{
                background-color: #78BC61;
              }
              
          }

          &.dark{
            &.private{
              background-color: $darkHover  ;
            }
            &.public{
              background-color: green;
            }
            
        }
          
      }
        margin-bottom: 5px;

    }

    &.notebook-frozenTopbar{
        top: $topmenuHeight;
        padding-top: 15px;
    }

    &.notebook-unfrozenTopbar{
        padding-top:15px;
    }
}





.request-bar{
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;

    .editor-request{
        text-decoration: underline;
        margin-left: 5px;

        &.light{
            color: black;
        }
    
        &.dark{
            color: white;
        }

    }

    &.light{
        color: black;
        background-color: $readerBgColor;
    }

    &.dark{
        color: white;
        background-color: $darkReaderBgColor;
    }
}
.request-box{
    position: absolute;
    z-index: 100;
    // left: 50vw;
    top: 20px;
    &.dark{
        background-color: white;
    }
}




.popup-menu{
  z-index: 2;
  display: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  border-radius: 5px;
  padding-left: 15px;
  position: fixed;
  right: 10px;
  padding-right: 15px;
  margin-top: 10px;
  margin-right: 40px;
  width:fit-content;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  &.light{
    background-color: $readerBgColor;
  }

  &.dark{
    background-color: $darkReaderBgColor;
  }
}













 




