@import "../../styles/variables";

$findBarImageSize:13px;
$darkgreyColor: #c2c3c5;
$hoverColor: #dddedf;

.PDFFindBar_component{
    
    border-radius:4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &.frozenTopbar{
        transform:translateY($topmenuHeight);
    }
    button{
        margin-left:3px;
        // margin-right:3px;
        padding-top:3px;
        padding-bottom:3px;
        border-radius:5px;
    }

    button.active-btn{
        background-color:$darkgreyColor ;
    }

    button.waste{
        display: none;
    }

    button.err-msg{
        &.dark{
            color: $darkDesklampRed
        }
        &.light{
            color: $lightDesklampRed
        }
    }

    .searchInput{
        margin-left:8px;
        margin-top:8px;
        margin-bottom:8px;
        margin-right:10px;
        width:150px;
        text-align:left;
        height:17px;
        border-radius: 4px;
    }

    img{
        height:$findBarImageSize;
        width:$findBarImageSize;
    }

    .findButtonImage{
        // cursor:pointer;
        // background-color: #dddedf;
        padding:3px;
        // border:0.5px solid $findBarBorderColor;
        top:6px;
        position:relative;
        padding:4px;
        border-radius:50%;
        margin-right:5px;

    }
    
    .closeFind{
        margin-right:10px;
        padding:3px;
        top:4px;
        position:relative;
        border-radius:50%;
    }

    .findNext{
        transform:rotate(0deg)
    }
    .findPrev{
        transform:rotate(180deg);
    }

    &.light{
        background-color: $readerBgColor;
        .searchInput{
            background-color:$textboxColor ;
        }
        button.non-button:hover{
            background-color: $readerBgColor;
        }
        .findButtonImage{
            &:hover{background-color: $lightHover;}
        }
        .find-message{
            color:$darkReaderBgColor
        }
        .err-msg{
            color:$lightDesklampRed
        }
    }

    &.dark{
        background-color: $darkReaderBgColor;
        .searchInput{
            background-color:$darkTextboxColor ;
            color:white;
        }
        button.non-button:hover{
            background-color: $darkReaderBgColor;
        }
        ::placeholder{
            color:rgba(255, 255, 255, 0.589)
        }
        .findButtonImage{
            &:hover{background-color: $darkHover;}
        }
        .find-message{
            color:$readerBgColor
        }
        .err-msg{
            color:$darkDesklampRed
        }
    }

}
