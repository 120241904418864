@import '../../../styles/colors';

.PromoCodeInfoPanel{
    font-family: 'Poppins';

   
   

}

.BulletPoint{

    .bullet-number{
        height: 16px;
        width: auto;
        vertical-align: middle;
        margin-right: 10px;
    }

    p{
        display: inline-block;
    }

    /* Create two equal columns that floats next to each other */
    .col {
        width: fit-content;
    }
    
    /* Clear floats after the columns */
    .row:after {
        content: "";
        display: table;
        clear: both;
    }

}
