/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.toolbar-svg {
  height: 21px;
  width: 21px;
  stroke-width: 6px;
}

.menupane-main {
  height: 100%;
  position: fixed;
  z-index: 4;
  top: 34px;
}

.toolbar-contents {
  position: relative;
  padding-bottom: 10px;
  height: 100%;
}

.toolbar-img {
  height: 24px;
  width: 24px;
}

.toolbarButton {
  border-radius: 2px;
  padding: 4px;
  margin: 0px;
  z-index: 10;
}

.img-container {
  height: 35px;
  width: 35px;
  margin-right: 2px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.btn-snip {
  width: 24px;
  height: 24px;
}

.btn-highlight {
  width: 24px;
  height: 24px;
}

.btn-postit {
  height: 24px;
  width: 24px;
}

.btn-locationmarker {
  height: 24px;
  width: 20px;
}

.btn-back {
  height: 20.8px;
  width: 24px;
}

.btn-search {
  height: 24px;
  width: 24px;
}

.horizontal-rule {
  border: none;
  margin: 10px;
  height: 2px;
  transition: 0.3s;
  align-self: center;
  margin-left: 5px;
  margin-right: 5px;
  z-index: 10;
  width: auto;
}
.horizontal-rule.always-show.dark {
  background: none;
}
.horizontal-rule.always-show.light {
  background: none;
}
.horizontal-rule.hidden {
  background: none;
}

.colorSelector-container {
  z-index: 1;
}

.img-div {
  height: 35px;
  width: 35px;
}

.img-container {
  transition: transform 0.3s;
  z-index: 3;
  margin-top: 15px;
}
.img-container.dark path {
  fill: white;
}
.img-container.disabled {
  filter: opacity(0.5);
}
.img-container.enabled:hover.dark path {
  fill: #a49bf3;
}
.img-container.enabled:hover.light path {
  fill: #998eff;
}
.img-container.active-lock.dark path {
  stroke-width: 8px;
  fill: #6557ff;
}
.img-container.active-lock.light path {
  stroke-width: 18px;
  fill: #665aef;
}
.img-container.active-lock:hover.dark path {
  fill: #6557ff;
}
.img-container.active-lock:hover.light path {
  fill: #665aef;
}
.img-container.hidden {
  transform: translateX(-50px);
}

.toolbar-loader:hover .img-container.hidden {
  transform: translateX(0px);
}

.active-lock.hidden {
  transform: translateX(0px);
}

.AreaHighlight {
  border: 1px solid #333;
  background-color: rgb(252, 232, 151);
  opacity: 1;
  mix-blend-mode: multiply;
}

.rnd-div {
  background-color: rgba(255, 0, 0, 0.384);
  border: 1px solid green;
}

.highlighter-tools:hover .colorSelector-container {
  display: inline-block;
}

.annotation-tools:hover .annotationSelector-container {
  display: inline-block;
}

.menu-btn {
  cursor: pointer;
}
.menu-btn.dark {
  filter: invert(0.85);
  cursor: pointer;
}

.dropMenuButton {
  transform: translateY(40px);
}

.tools-container {
  display: flex;
}
.tools-container .img-div-highlight {
  z-index: 2;
}
.tools-container .toolbarbuttons-colsel {
  z-index: 1;
}

.toolbar-buttons {
  z-index: 3;
  /* Center vertically */
  position: absolute;
  top: 45%;
  transform: translate(0, -50%);
}

.annotation-tools:hover .colorSelector-container {
  display: inline-block;
}

.toolbarMenuBar {
  left: 40px;
  position: fixed;
  top: 40px;
  overflow-y: auto;
  height: 100%;
  box-shadow: 4px 8px 8px -4px rgba(0, 0, 0, 0.2);
  color: black;
  width: 350px;
}
.toolbarMenuBar.electron {
  top: 80px;
}
.toolbarMenuBar.explore {
  top: 74px;
}
.toolbarMenuBar.dark {
  background-color: #34353f;
  color: white;
}
.toolbarMenuBar.light {
  background-color: #f2f2f7;
  color: black;
}

.sliderMenu-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}/*# sourceMappingURL=MenuPane.css.map */