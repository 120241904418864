/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.exportbar-container {
  text-align: left;
  padding: 20px;
}

.close-exportbox {
  z-index: 2;
  position: absolute;
  height: 12px;
  width: 12px;
  top: 10px;
  right: 10px;
  padding: 5px;
  border-radius: 50%;
}
.close-exportbox:hover {
  background-color: rgb(190, 190, 190);
}

.exportbar-section {
  margin-bottom: 20px;
}

.exportbar-section-header {
  margin-bottom: 20px;
}

.exportbar-subtitle {
  font-size: 14px;
  padding-left: 5px;
}

.export-options-container {
  padding: 10px;
  border-radius: 5px;
}
.export-options-container.light {
  background-color: rgba(230, 229, 238, 0.7);
}
.export-options-container.dark {
  background-color: rgba(78, 78, 88, 0.7);
}
.export-options-container.disabled {
  opacity: 0.7;
}

.all-annotations-checkbox {
  font-weight: bold;
}

.export-option {
  margin-top: 10px;
  margin-bottom: 10px;
}

.export-option-p {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 5px;
  padding: 7px;
  display: flex;
  vertical-align: middle;
}
.export-option-p:hover.light {
  background-color: #BAB6F5;
}
.export-option-p:hover.dark {
  background-color: #323344;
}
.export-option-p:hover.disabled {
  background-color: transparent;
}

.export-download-loading {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

.export-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.export-downloadButton {
  background-color: #665AEF;
  padding: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  margin-top: 20px;
  float: right;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin: 0px auto;
  margin-left: 70px;
  width: 150px;
  float: right;
}
.export-downloadButton.active-btn {
  cursor: pointer;
}
.export-downloadButton.inactive-btn {
  opacity: 0.5;
}

.exportNotebooks-container {
  width: 500px;
}

.notebooks-container {
  width: 100%;
}
.notebooks-container table {
  width: 100%;
}
.notebooks-container table td {
  padding: 10px;
}

.annotations-checkbox.odd {
  background-color: #f2f2f7;
}
.annotations-checkbox.eve {
  background-color: #DDDBEC;
}

.notebooks-table-head {
  border-bottom: 1px solid #929597;
}

.td-check {
  width: 30px;
}

.td-title {
  width: calc(100% - 30px);
}

.loading-download-notebooks {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.download-message-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.downloading-message {
  margin: 0px auto;
  text-align: left;
  padding-left: 10px;
  width: 480px;
}
.downloading-message.loading-message {
  color: #665AEF;
}
.downloading-message.download-completed {
  color: #7ac74f;
}/*# sourceMappingURL=ExportBar.css.map */