/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.email-box {
  display: inline;
  padding: 4px;
  padding-right: 6px;
  padding-left: 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  font-size: 12px;
}

.email-box.email-box-valid.dark {
  background-color: #495867;
}

.email-box.email-box-valid.light {
  background-color: #db5461;
}

.email-box.email-box-invalid.dark {
  background-color: #ffa69e;
}

.email-box.email-box-invalid.light {
  background-color: #bfd2ff;
}

.email-box-container {
  padding: 5px;
  padding-left: 0px;
  padding-bottom: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
}

.email-box-close {
  margin-left: 5px;
  width: 10px;
  height: 10px;
}
