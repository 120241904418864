@import '../../../common-components/src/styles/variables';


.home-container{
    &.dark{
        color: white
    }
    &.light{
        color: black
    }
}

.home-button{
    background-color: pink;
    color: black;
}

.home-input{
    width: 250px;
    background-color: pink;
}

// .doc-container{
//     display: inline-block;
//     background-color: rgb(66, 66, 66);
//     padding: 20px;
//     margin:10px;
//     width: fit-content;

//     .taginput{
//         width: 100%;
//         padding: 5px;
//         background-color: lightblue;
//     }
// }

.searchbar{
    background-color: lightblue;
    width: 80%;
}

.tagSpan{
    background-color: green;
    padding: 3px;
    margin: 3px;
}

.desktop-popup{
    user-select: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    border-radius: 5px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
    &.dark{
        background-color: $darkHover;
        color: white;
        
    }

    &.light{
        background-color: $clipboardColor;

    }
}



.closePopup{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: 15px;
    opacity: 0.5;
    cursor: pointer;

    &:hover{
        opacity: 1;
    }

}