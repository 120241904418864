@import '../../styles/variables';

$totalWidth: 330px;
$leftWidth: 120px;

.annotationFilterSelector-main{
    font-size: 14px;
    &.light{
        background-color: white;
    }
    &.dark{
        background-color: $darkTextboxColor;
        color: white;
    }
    width: $totalWidth;
    // border-radius: 5px;
    box-shadow: $boxShadow;
    border-radius: 5px;
    &:after {
        content: "";
        display: table;
        clear: both;
      }

    .column{
        float: left;
    }
}

.master-wrapper{
    // display: flex;
    width: 150px;
    margin-left: 10px;
}





.filter-topics{
    padding-top:15px;
    padding-bottom:15px;
    width: $leftWidth;
    text-align: left;
    

}

.filter-options{
    // width: $totalWidth - $leftWidth;
    padding: 10px;
    &.light{
        border-left: 1px solid $lightHover;
    }
    &.dark{
        border-left: 1px solid $desklampButtonBg;
    }
    padding-bottom: 20px;
}

.filterTopic{
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top:5px;
    padding-bottom:5px;
    &.activeTopic{
        &.light{
            background-color: $lightHover;
        }
        &.dark{
            background: $desklampButtonBg;
        }
    }
}

.pagerange-input{
    margin-left: 10px;
    border-radius: 5px;
    &.light{
        background-color: $textboxColor;
        color: black
    }
    &.dark{
        background-color: $darkTextboxColor;
        color: white
    }
}

.annotations-check-boxes{
    text-align:left;
}

.annotations-checkbox{
    margin-bottom:5px;
}

.colorpicker-annotationsFilter{
    // display:flex;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    margin-left: 10px;
}