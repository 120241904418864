@import '../../styles/variables';

.mainPopup-container{
    position: fixed;
    top:0;
    z-index: 20;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.411) ;
}

.mainPopup-element-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -30px;
    background-color: $lightDesklampGrey;
    padding: 20px;
    border-radius: 5px;
    text-align: left;


}

.mainPopup-title{
    text-align: left;
    font-weight: bold;
}