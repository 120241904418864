.gen-form-caption {
  font-size: 16px;
  font-family: "Poppins";
  margin-bottom: 20px;
}
.gen-form-caption.gen-form-error {
  color: #db5461;
}
.gen-form-caption.gen-form-success {
  color: #3a9434;
}/*# sourceMappingURL=Form.css.map */