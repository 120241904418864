@import "../../styles/variables";

$themeRadius:10px;
$readerThemeScale:1.5;


.settings-container{
    text-align:left;
    margin-left:20px;
    padding-right: 13px;
    user-select: none;
    margin-bottom: 20px;
}

.settings-group-header{
  margin-bottom:20px;
//   font-size: 15px;
}

.settings-group{
  &.light{
    background-color: transparentize($clipboardColor,$settingsTransparency);
  }
  &.dark{
    background-color: transparentize($darkHover, $settingsTransparency);
  }
  border-radius:5px;
  width:100%;

  &.sharing-group{
      width: auto
  }
  padding:5px;

}

table.settings-group{
  // border:1px solid yellow;
  
  tr{
    border-radius: 5px;
    min-height:40px;
    // transition: background-color $transitiontime;
    &.hoverable{
      &.dark:hover{
        background-color: $darkHover;
      }
      &.light:hover{
        background-color: $lightHover;
      }
    }
  }
  td.settings-label{
    width:80%;
  }

  td.settings-label-mini{
      width: 25%;
  }
  td.settings-wide{
    width: 75%;
    }

  td.settings-icon-container{
    width:20%;
    text-align: center;
  }

  td.sharing-label{
    width:70%;
    // border:1px solid orange;
  }
  td.sharing-right-container{
    width:30%;
    // border:1px solid pink;
  }
}

.settings-td-icon{
  height:18px;
  width:18px;
}

.setting{
  display: flex;
  align-items: center;

}

.toggle-switch-container{
  float:right;
}

.settings-message{
  &.dark{
    color:transparentize($darkTextColor, 0.5);
  }
  &.light{
    color:transparentize($textColor, 0.5);
  }

  &.clickable{
      cursor: pointer;
  }
}

.settings-input{
    width: 90%;
    padding: 5px;
    border-radius: 5px;
    &.dark{
        border: 1px solid grey;
        background-color: $darkClipboardColor;
        color: white;
    }
    &.light{
        background-color: $clipboardColor;
    }
    &:disabled{
        background-color: transparent;
    }

    &.editing{
        width: 70%
    }
}

.addtag-button{
    font-size: 14px;
    padding: 5px;
    margin-left: 5px;
    border-radius: 5px;;
    &.light{
        background-color: $desklampButtonBg;
    }
    &.dark{
        background-color: $darkDesklampButtonBg;
    }
}

.theme-container-set{
    display:inline-block;
    text-align: left;
    font-weight: bold;
    border:0.25px solid rgba(128, 128, 128, 0.384);
    margin-right: 25px;
    border-radius: $themeRadius;
    cursor: pointer;

    p{
      margin:3px;
      display: inline;
    }

    .radio{
      width:15px;
      height:15px;
      border-radius: 50%;
      border:1px solid black;
      margin:5px;
      margin-bottom:7px;
      background-color: white;
    }

    .active-theme{
      background-color: $menuBgColor;

    }

    .theme-sel{
        vertical-align: center;
        border-top-right-radius: $themeRadius;
        border-top-left-radius: $themeRadius;
        border-bottom:0.25px solid rgba(128, 128, 128, 0.384);
      }
    
      .theme-sel-app{
        width: 187.5px;
        height: 129px;
      }
    
      .theme-sel-reader{
        width: 121px;
        height: 170px;
      }
}


