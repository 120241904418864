@import "../../styles/variables";
$iconSize:0.6*$toolbarWidth;
// $menuBtnHeight:3.7vw;
$toolButtonHeight:4vw;

$lightHoverToolbar: #998eff;
// #991cff
// #915ef0;
$darkHoverToolbar: #a49bf3;
$activeButtonColorToolbar: #665aef;
$darkActiveButtonColorToolbar: #6557ff;

$toolbarIconSize:32px;
$iconFactor:0.8;

$indexWidth:350px;
$indexHeight:100%;
$indexTop:40px;
$fontSize:15px;
$arrowSize:8px;
$marginRight:10px;
$indent:20px;

.toolbar-svg{
    height: 21px;
    width: 21px;
    stroke-width: 6px;
}
.menupane-main{
    height:100%;
    position: fixed;
    z-index: 4;
    top: $signupBarTotHeight;
}

.toolbar-contents{
    position:relative;
    padding-bottom: 10px;
    height:100%;
}

.toolbar-img{
    height:$iconSize;
    width: $iconSize;
}

// .active-btn{
//     border-left:0.3vw solid white;
//     &.dark{
//         background-color: $darkActiveButtonColor;
//     }
//     &.light{
//         background-color: $activeButtonColor;
//     }
// }


.toolbarButton{
    // height:$toolbarIconSize;
    // width:$toolbarIconSize;
    border-radius: 2px;
    padding:4px;
    margin:0px;
    // margin-left: 5px;
    // margin-right: 5px;
    z-index:10;
}

.img-container{
    height: 35px;
    width: 35px;
    margin-right:2px;
    margin-left: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.btn-snip{
    width: 30px * $iconFactor;
    height: 30px * $iconFactor;
    // transform: rotate(50deg);
}

.btn-highlight{
    width: 30px * $iconFactor;
    height: 30px * $iconFactor;
}

.btn-postit{
    height:30px * $iconFactor;
    width:30px * $iconFactor;
}

.btn-locationmarker{
    height:30px * $iconFactor;
    width:25px * $iconFactor;
}

.btn-back{
    height:26px * $iconFactor;
    width:30px * $iconFactor;
}

.btn-search{
    height: 30px * $iconFactor;
    width: 30px * $iconFactor;
}

.horizontal-rule{
    border: none;
    margin:10px;
    height: 2px;
    transition: 0.3s;
    align-self: center;
    margin-left: 5px;
    margin-right: 5px;
    z-index:10;
    width: auto;
    &.always-show{
        
        &.dark{
        // background-color: transparentize($clipboardColor, 0.7);
        background: none;
    }
        &.light{
        // background-color: transparentize($darkClipboardColor, 0.7);
        background: none;
    }

    }
    &.hidden{
        background:none;
    }
    
}
.colorSelector-container{
    z-index:1;
}

.img-div{
    height: 35px;
    width: 35px;
    
}

.img-container{
    &.dark{
        path{
            fill: white;
        }
    }
    &.disabled{
        filter: opacity(0.5);
    }
    // transition: background-color $transitiontime;
    &.enabled{
        &:hover{
            &.dark{
                path{
                    fill: $darkHoverToolbar;
                }
                // background-color: $darkHover;
            }
            &.light{
                path{
                    fill: $lightHoverToolbar;
                }
                // background-color: $lightHover;
            }
        }
    }
    &.active-lock{
        
        &.dark{
            path{
                stroke-width: 8px;
                fill: $darkActiveButtonColorToolbar;
            }
            // background-color: $darkActiveButtonColor;
        }
        &.light{
            // background-color: $activeButtonColor;
            path{
                stroke-width: 18px; 
                fill: $activeButtonColorToolbar;
            }
        }
        &:hover{
            &.dark{
                path{
                    fill: $darkActiveButtonColorToolbar;
                }
                // background-color: $darkActiveButtonColor;
            }
            &.light{
                // background-color: $activeButtonColor;
                path{
                    fill: $activeButtonColorToolbar;
                }
            }
        }
    }
    &.hidden{
        transform: translateX(-50px);
    }
    transition:transform 0.3s;
    z-index:3;
    margin-top: 15px;
}

.toolbar-loader:hover .img-container{
    &.hidden{
        transform: translateX(0px);
    }
    
}
// .toolbar-loader:hover .horizontal-rule{
//     &.dark{
//         background-color: transparentize($clipboardColor, 0.7);
//     }
//     &.light{
//         background-color: transparentize($darkClipboardColor, 0.7);
//     }

// }
.active-lock{
    &.hidden{
        transform: translateX(0px);
    }
    

    // .active-img{
    //     background-color: $activeButtonColor;
    //     // border-right:2px solid black;
    // }
}

.AreaHighlight{
    border: 1px solid #333;
    background-color: rgba(252, 232, 151, 1.0);
    opacity: 1;
    mix-blend-mode: multiply;
}

.rnd-div{
    background-color: rgba(255, 0, 0, 0.384);
    border:1px solid green;
}



.highlighter-tools:hover .colorSelector-container{
    display:inline-block ;
}
.annotation-tools:hover .annotationSelector-container{
    display:inline-block ;
}

.menu-btn{
    // transition: background-color $transitiontime;
    cursor:pointer;
    &.dark{
        filter: invert(0.85);
        cursor:pointer;
    }
}

.dropMenuButton{
    transform: translateY(40px);
}

.tools-container{
    display: flex;
    .img-div-highlight{
        // float: left;
        z-index: 2
    }

    .toolbarbuttons-colsel{
        z-index:1;
    }
}
.toolbar-buttons{
    z-index:3;
    /* Center vertically */
    position: absolute;
    top: 45%;
    transform: translate(0, -50%);
}



.annotation-tools:hover .colorSelector-container{
    display:inline-block ;
}

.toolbarMenuBar{
    // transition: background-color $transitiontime;
    left:$leftGap;
    position:fixed;
    top:$indexTop;
    overflow-y: auto;
    height:$indexHeight;
    //transform: translateY($topmenuHeight);
    box-shadow: 4px 8px 8px -4px rgba(0, 0, 0, 0.2);
    color:black;
    width:$indexWidth;

    &.electron{
        top: $indexTop + 40px;
    }

    &.explore{
        top: $indexTop + $signupBarTotHeight;
    }

    &.dark{
        background-color: $darkReaderBgColor;
        color: white; 
    }
    &.light{
        background-color:$readerBgColor; 
        color: black; 
    }
    
}

.sliderMenu-wrapper{
    display: flex;
    justify-content: center;
    margin-top:15px;
}

