@import "../../styles/variables";
$indexHeight:100%;
$indexTop:0px;
$fontSize:15px;
$arrowSize:8px;
$marginRight:10px;
$indent:20px;

.indexbar-main{
    padding-right: 13px;
    z-index:7;
    &.dark{
        color: white;
    }
    &.light{
        color: black
    }
}

ul{
    list-style-type: none;
    text-align: left; 
}

.top-level-ul{
    left:0px;
    position: absolute;
    &.dark{
        color: $readerBgColor;
    }
    &.light{
        color:$darkReaderBgColor
    }
}


.section{
    cursor: pointer;
    width: 90%;
    text-align: left;
    font-size: $fontSize;
    font-family:$globalFont;
    padding: 0px;
    // height:$fontSize+8;//some gap is needed (5px)
    height:auto;
    margin-top:4px;
    overflow: hidden;
    overflow-wrap: break-word;

    
}

//this is the arrow
.collapsible {
    cursor: pointer;
    /* border: 1px solid black; */
    // text-align: left;
    height: $arrowSize;
    width:$arrowSize;
    margin-right:$marginRight;
    // border-radius: 50%;
}

.no-drop {
    // background-color: transparent;
    cursor: default;
    padding: 5px;
    width: 0vw;
    height:$arrowSize;
    text-align: left;
    font-size: $fontSize;
    margin-right:$marginRight;
}

.indexbar-main .active, .collapsible:hover {
    transform: rotate(90deg);
}

.content {
    padding: 0 $indent;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    font-family:$globalFont;
    // &.dark{
    //     color: $readerBgColor;
    // }
    // &.light{
    //     color:$darkReaderBgColor
    // }
}

.empty-index-message{
    margin: 0 auto;
    margin-top: 40px;
    width: 200px;
    &.dark{
        color: $lightDesklampGrey
    }
    &.light{
        color: $darkDesklampGrey
    }
}


