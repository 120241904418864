/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.clipboard-loader {
  scrollbar-width: none;
}
.clipboard-loader.dark {
  background-color: #212231;
}
.clipboard-loader.light {
  background-color: #e6e5ee;
}

.clipboard-screenshot {
  filter: var(--reader-theme);
  margin-bottom: -5px;
  pointer-events: none;
}

.clipboardImage-wrapper {
  padding: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.screenshotContainer {
  border: 5px solid orange;
  padding-bottom: 500px;
}

.clipboard-images {
  z-index: 1;
}

.empty-clipboard-space-bottom {
  z-index: 0;
  width: 130%;
  height: 200px;
  position: absolute;
}

div.clipboard-empty-message {
  top: 35%;
  transform: 0, -50%;
  color: rgb(65, 64, 64);
}
div.clipboard-empty-message.dark {
  filter: invert(1);
}

div.clipboard-empty-message > p {
  width: 250px;
}

img.clipboard-starter-img {
  width: 99px;
  height: 90px;
}

.imageLabelInput.dark {
  color: #f2f2f7;
}
.imageLabelInput.light {
  color: #34353f;
}

.clipboard-starter-header {
  font-size: 14px;
}

.clipboard-starter-msg {
  font-size: 14px;
}/*# sourceMappingURL=Clipboard.css.map */