@import "../../styles/variables";

.exportbar-container{
    text-align: left;
    padding:20px;
}


.close-exportbox{
    z-index:2;
    position:absolute;
    height:12px;
    width:12px;
    top:10px;
    right:10px;
    padding:5px;
    border-radius:50%;

    &:hover{
        background-color: rgb(190, 190, 190);
    }
}

.exportbar-section{
    margin-bottom: 20px;

}

.exportbar-section-header{
    margin-bottom:20px;
}

.exportbar-subtitle{
    font-size: 14px;
    // font-style: italic;
    padding-left:5px;
}

.export-options-container{
    padding:10px;
    border-radius: 5px;
    &.light{
        background-color: transparentize($clipboardColor,$settingsTransparency);
    }
    &.dark{
        background-color: transparentize($darkHover, $settingsTransparency);
    }
    &.disabled{
        opacity: 0.7
    }
}

.all-annotations-checkbox{
    font-weight: bold;
}

.export-option{
    margin-top:10px;
    margin-bottom:10px;
}

.export-option-p{
    user-select: none;
    border-radius: 5px;
    padding: 7px;
    display:flex;
    vertical-align: middle;

    &:hover{
        
        &.light{
            background-color: $lightHover;
        }
        &.dark{
            background-color: $darkTopmenuColor;
        }
        &.disabled{
            background-color: transparent;
        }
    }
}

.export-download-loading{
    width: 20px;
    height: 20px;
    margin-left: 20px;
}

.export-icon{
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.export-downloadButton{
    background-color: $desklampButtonBg;
    padding: 10px;
    user-select: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    float:right;
    display: flex;
    justify-content: center;
    font-size: 14px;
    margin: 0px auto;
    margin-left: 70px;
    width: 150px;
    float: right;
    &.active-btn{
        cursor: pointer;
    }
    &.inactive-btn{
        opacity: 0.5;
    }
}

.exportNotebooks-container{
    width: 500px;
}

.notebooks-container{
    width: 100%;
    table{
        width: 100%;
        td{
            padding: 10px;
        }
    }
}

.annotations-checkbox{
    &.odd{
        background-color: $lightDesklampGrey;
    }
    &.eve{
        background-color: $inactiveTabBgColor;
    }
}

.notebooks-table-head{
    border-bottom: 1px solid $darkerGrey;
}

.td-check{
    width: 30px;
}

.td-title{
    width: calc(100% - 30px);
}

.loading-download-notebooks{
    width: 15px;
    height: 15px;
    margin-left: 5px;
}

.download-message-container{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content:space-around;
}

.downloading-message{
    margin: 0px auto;
    text-align: left;
    padding-left: 10px;
    width: 480px;
    &.loading-message{
        color: $desklampButtonBg
    }
    &.download-completed{
        color: $lightDesklampGreen
    }
}


.loading-download-notebooks{
//     margin: 0px auto;
//     margin-left: 2px;
    // margin-top: 4px;
}


