/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.tooltip-container {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 5px;
  position: absolute;
  text-align: left;
  margin-left: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 30;
}
.tooltip-container.tooltip {
  padding-left: 20px;
  padding-right: 20px;
}
.tooltip-container.tooltip:after {
  content: " ";
  position: absolute;
  left: -10px;
  top: 15px;
  border-top: 10px solid transparent;
  border-left: none;
  border-bottom: 10px solid transparent;
  box-shadow: 0 -5px 5px -5px pink, 0 5px 5px -5px #333, -5px 0 5px -5px #333;
}
.tooltip-container.dark {
  background-color: #313941;
}
.tooltip-container.dark:after {
  border-right: 10px solid #313941;
}
.tooltip-container.light {
  color: #34353f;
  background-color: #f2f2f7;
}
.tooltip-container.light:after {
  border-right: 10px solid #f2f2f7;
}

.tooltip-heading {
  font-size: 14px;
  padding: 5px;
  margin-bottom: 0px;
  margin-top: 5px;
  padding-bottom: 0px;
  text-align: center;
}
.tooltip-heading.dark {
  color: #e6e5ee;
}

.tooltip-hr {
  margin-left: 5px;
  margin-right: 5px;
}

.tooltip-options {
  margin-bottom: 10px;
  margin-top: 10px;
}
.tooltip-options.centering {
  text-align: center;
}
.tooltip-options.context {
  padding-left: 10px;
  padding-right: 0px;
}
.tooltip-options.dark {
  color: #f2f2f7;
}

.color-btn-container {
  display: inline-flex;
  margin: 2px;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 2px;
}

.btn-color {
  width: 30px;
  margin: -2px;
}

.active-color.dark {
  border: 2px solid #f2f2f7;
}
.active-color.light {
  border: 2px solid #34353f;
}

.inactive-color:hover.dark {
  border: 2px solid #f2f2f7;
}
.inactive-color:hover.light {
  border: 2px solid #34353f;
}

.colorSelector-container {
  display: inline-block;
  width: 105px;
  height: 30px;
  z-index: 1;
  transition: 0.3s;
  text-align: left;
}

.radio-btn-container {
  width: 21px;
  height: 21px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 50%;
}
.radio-btn-container.active-option {
  border: 1px solid black;
}

.tooltip-radio-icon {
  width: 15px;
  height: 15px;
  padding: 1px;
  border-radius: 50%;
  background-color: white;
}
.tooltip-radio-icon.active-option.dark {
  border: 2px solid #f2f2f7;
}
.tooltip-radio-icon.active-option.light {
  border: 2px solid #34353f;
}
.tooltip-radio-icon.inactive-option:hover.dark {
  border: 2px solid #f2f2f7;
}
.tooltip-radio-icon.inactive-option:hover.light {
  border: 2px solid #34353f;
}

.sticker-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: inline-block;
  padding: 2px;
  border-radius: 7px;
  margin: 2px;
}
.sticker-container.active-sticker.dark {
  background-color: #4e4e58;
}
.sticker-container.active-sticker.light {
  background-color: #BAB6F5;
}
.sticker-container.active-postit.dark {
  background-color: #4e4e58;
}
.sticker-container.active-postit.light {
  background-color: #BAB6F5;
}

.sticker-img {
  width: 32px;
  height: 32px;
  display: inline;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid transparent;
}
.sticker-img.emoji {
  width: 24px;
  height: 24px;
}

.sticker-collection {
  padding-top: 10px;
  width: 150px;
  display: inline-block;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.tooltip-radio-container {
  margin-bottom: 0px;
}

.tooltip-dropdown {
  padding: 3px;
  border-radius: 5px;
}

.tooltip-radio-options {
  padding: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.tooltip-label {
  font-size: 14px;
  margin: 0 auto;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: left;
}

.tooltip-selector {
  width: 120px;
  font-size: 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
.tooltip-selector.dark {
  background-color: #212231;
}
.tooltip-selector.light {
  background-color: #e6e5ee;
}
.tooltip-selector .selector-option {
  display: inline-block;
  text-align: center;
  padding: 2px;
  width: 45%;
}
.tooltip-selector .selector-option.left {
  border-radius: 5px;
}
.tooltip-selector .selector-option.right {
  border-radius: 5px;
}
.tooltip-selector .selector-option.active-option.light {
  background-color: #BAB6F5;
}
.tooltip-selector .selector-option.active-option.dark {
  background-color: #4e4e58;
}
.tooltip-slider-container {
  width: 90%; /* Width of the outside container */
}

/* The slider itself */
.tooltip-slider {
  -webkit-appearance: none; /* Override default CSS styles */
  -moz-appearance: none;
       appearance: none;
  width: 100%; /* Full-width */
  height: 10px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */ /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border-radius: 10px;
  position: static;
}

/* Mouse-over effects */
.tooltip-slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.tooltip-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 10px; /* Set a specific slider handle width */
  height: 10px; /* Slider handle height */
  border-radius: 50%;
  background: #665AEF; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.tooltip-slider::-moz-range-thumb {
  width: 10px; /* Set a specific slider handle width */
  height: 10px; /* Slider handle height */
  background: #665AEF; /* Green background */
  cursor: pointer; /* Cursor on hover */
}/*# sourceMappingURL=ToolTip.css.map */