/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.contextmenu-container {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 11;
}
.contextmenu-container.light {
  background-color: #f2f2f7;
}
.contextmenu-container.dark {
  background-color: #34353f;
}

.cm-icon {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.color-buttons-cm {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 10px;
}

.btn_color_cm {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid transparent;
}
.btn_color_cm.light:hover {
  border: 1px solid #34353f;
}
.btn_color_cm.dark:hover {
  border: 1px solid #f2f2f7;
}

.cm-text {
  font-size: 15px;
  width: calc(100% - 20px);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
}
.cm-text.light {
  color: #34353f;
}
.cm-text.light:hover {
  background-color: #BAB6F5;
}
.cm-text.dark {
  color: #f2f2f7;
}
.cm-text.dark:hover {
  background-color: #4e4e58;
}
.cm-text.pad-left {
  padding-left: 30px;
}
.cm-text.disabled:hover {
  background-color: transparent;
}

.cm-color-text {
  font-size: 15px;
  width: calc(100% - 20px);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.cm-color-text.light {
  color: #34353f;
}
.cm-color-text.dark {
  color: #f2f2f7;
}

.contextMenu-hr {
  margin-top: 5px;
  margin-bottom: 5px;
}/*# sourceMappingURL=contextMenu.css.map */