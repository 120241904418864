/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.settings-container {
  text-align: left;
  margin-left: 20px;
  padding-right: 13px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-bottom: 20px;
}

.settings-group-header {
  margin-bottom: 20px;
}

.settings-group {
  border-radius: 5px;
  width: 100%;
  padding: 5px;
}
.settings-group.light {
  background-color: rgba(230, 229, 238, 0.7);
}
.settings-group.dark {
  background-color: rgba(78, 78, 88, 0.7);
}
.settings-group.sharing-group {
  width: auto;
}

table.settings-group tr {
  border-radius: 5px;
  min-height: 40px;
}
table.settings-group tr.hoverable.dark:hover {
  background-color: #4e4e58;
}
table.settings-group tr.hoverable.light:hover {
  background-color: #BAB6F5;
}
table.settings-group td.settings-label {
  width: 80%;
}
table.settings-group td.settings-label-mini {
  width: 25%;
}
table.settings-group td.settings-wide {
  width: 75%;
}
table.settings-group td.settings-icon-container {
  width: 20%;
  text-align: center;
}
table.settings-group td.sharing-label {
  width: 70%;
}
table.settings-group td.sharing-right-container {
  width: 30%;
}

.settings-td-icon {
  height: 18px;
  width: 18px;
}

.setting {
  display: flex;
  align-items: center;
}

.toggle-switch-container {
  float: right;
}

.settings-message.dark {
  color: rgba(242, 242, 247, 0.5);
}
.settings-message.light {
  color: rgba(52, 53, 63, 0.5);
}
.settings-message.clickable {
  cursor: pointer;
}

.settings-input {
  width: 90%;
  padding: 5px;
  border-radius: 5px;
}
.settings-input.dark {
  border: 1px solid grey;
  background-color: #212231;
  color: white;
}
.settings-input.light {
  background-color: #e6e5ee;
}
.settings-input:disabled {
  background-color: transparent;
}
.settings-input.editing {
  width: 70%;
}

.addtag-button {
  font-size: 14px;
  padding: 5px;
  margin-left: 5px;
  border-radius: 5px;
}
.addtag-button.light {
  background-color: #665AEF;
}
.addtag-button.dark {
  background-color: #444758;
}

.theme-container-set {
  display: inline-block;
  text-align: left;
  font-weight: bold;
  border: 0.25px solid rgba(128, 128, 128, 0.384);
  margin-right: 25px;
  border-radius: 10px;
  cursor: pointer;
}
.theme-container-set p {
  margin: 3px;
  display: inline;
}
.theme-container-set .radio {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid black;
  margin: 5px;
  margin-bottom: 7px;
  background-color: white;
}
.theme-container-set .active-theme {
  background-color: #665AEF;
}
.theme-container-set .theme-sel {
  vertical-align: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 0.25px solid rgba(128, 128, 128, 0.384);
}
.theme-container-set .theme-sel-app {
  width: 187.5px;
  height: 129px;
}
.theme-container-set .theme-sel-reader {
  width: 121px;
  height: 170px;
}/*# sourceMappingURL=SettingsBar.css.map */