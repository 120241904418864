/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.doc-box-container {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  border-radius: 5px;
  padding: 10px;
  width: 180px;
  text-align: left;
}
.doc-box-container.dark {
  background-color: #212231;
}
.doc-box-container.dark:after {
  border-right: 10px solid #212231;
}
.doc-box-container.light {
  background-color: #e6e5ee;
}
.doc-box-container.light:after {
  border-right: 10px solid #e6e5ee;
}
.doc-box-container hr {
  margin-bottom: 10px;
  margin-top: 10px;
}
.doc-box-container:after {
  content: " ";
  position: absolute;
  left: -10px;
  top: 40%;
  border-top: 10px solid transparent;
  border-left: none;
  border-bottom: 10px solid transparent;
}
.doc-box-container .kitab-input {
  width: 100%;
}
.doc-box-container .delete-message.dark {
  color: #ffa69e;
}
.doc-box-container .delete-message.light {
  color: #db5461;
}

.tag-suggestions-dropdown {
  position: absolute;
}
.tag-suggestions-dropdown.dark {
  background-color: #313941;
}
.tag-suggestions-dropdown.light {
  background-color: #C4C4C4;
}
.tag-suggestions-dropdown .tag-suggestion {
  padding: 5px;
}
.tag-suggestions-dropdown .tag-suggestion:hover {
  cursor: pointer;
}
.tag-suggestions-dropdown .tag-suggestion:hover.dark {
  background-color: rgba(196, 196, 196, 0.4862745098);
}
.tag-suggestions-dropdown .tag-suggestion:hover.light {
  background-color: rgba(59, 63, 63, 0.368627451);
}

.docbox-entry {
  display: flex;
  align-items: center;
  padding: 0px;
  cursor: pointer;
}
.docbox-entry .docbox-icon {
  width: 16px;
  height: 16px;
}
.docbox-entry .docbox-label {
  display: inline;
  padding: 0px;
  margin: 5px;
  margin-left: 10px;
}

.DocBox-back {
  margin: 0;
  margin-bottom: 5px;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  font-weight: bold;
}

.tag-input {
  width: 130px;
  border-radius: 5px;
  padding: 5px;
}
.tag-input.dark {
  background-color: #444758;
  color: #f2f2f7;
}
.tag-input.light {
  background-color: #D8D5FA;
  color: #34353f;
}

.tags-container {
  font-size: 14px;
}

.submit-tag {
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
  padding: 5px;
  border-radius: 5px;
  color: white;
  background-color: #665AEF;
}

.tag-label {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
  margin: 3px;
  display: inline-block;
  border-radius: 4px;
}
.tag-label.dark {
  background-color: #4e4e58;
}
.tag-label.light {
  background-color: #BAB6F5;
}
.tag-label .delete-tag {
  width: 8px;
  height: 8px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
  cursor: pointer;
}/*# sourceMappingURL=DocBox.css.map */