@import "../../styles/variables";

$size:15px;
$padding:10px;

$colorBtnHeight: 20px;
$colorBtnWidth: 20px;
$colorBtnGap: 5px;

.contextmenu-container{
    position:absolute;
    width:fit-content;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding-top:$padding;
    padding-bottom:$padding;
    user-select: none;
    z-index: 11;
    &.light{
        background-color: $readerBgColor;
    }
    &.dark{
        background-color:$darkReaderBgColor ;
    }
}

.cm-icon{
    height:$size;
    width: $size;
    margin-right:5px;
    // margin: auto;
}

.color-buttons-cm{
    position: relative;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 10px;
}

.btn_color_cm{
    height:$colorBtnHeight;
    width:$colorBtnWidth;
    margin-left: $padding;
    margin-right:$padding;

    border-radius: 50%;
    margin-top:5px;
    margin-right:$colorBtnGap; //100% - 4*(width %) / 8
    margin-left:$colorBtnGap;
    border:1px solid transparent;

    &.light{
        &:hover{
            border: 1px solid $darkReaderBgColor;
        }
    }
    &.dark{
        &:hover{
            border: 1px solid $readerBgColor
        }
    }
}

.cm-text{
    // margin-top:10px;
    font-size:$size;
    width:calc(100% - 20px); //2*$padding
    padding-left: $padding;
    padding-right:$padding;
    padding-top:2px;
    padding-bottom:2px;
    border-radius: 5px;
    // margin-top: 5px;
    // margin-bottom: 5px;

    &.light{
        color:$darkReaderBgColor;
        &:hover{
            background-color:$lightHover;
        }

    }
    &.dark{
        color:$readerBgColor;
        &:hover{
            background-color:$darkHover
        }

    }

    &.pad-left{
        padding-left: 30px
    }

    &.disabled{
        &:hover{
            background-color:transparent
        }
    }
}
.cm-color-text{
    // margin-top:10px;
    font-size:$size;
    width:calc(100% - 20px); //2*$padding
    padding-left: $padding;
    padding-right:$padding;
    padding-top:2px;
    padding-bottom:2px;
    &.light{
        color:$darkReaderBgColor;

    }
    &.dark{
        color:$readerBgColor;

    }
}



.contextMenu-hr{
    margin-top: 5px;
    margin-bottom:5px;

}
