/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.home-container.dark {
  color: white;
}
.home-container.light {
  color: black;
}

.home-button {
  background-color: pink;
  color: black;
}

.home-input {
  width: 250px;
  background-color: pink;
}

.searchbar {
  background-color: lightblue;
  width: 80%;
}

.tagSpan {
  background-color: green;
  padding: 3px;
  margin: 3px;
}

.desktop-popup {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 5px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.desktop-popup.dark {
  background-color: #4e4e58;
  color: white;
}
.desktop-popup.light {
  background-color: #e6e5ee;
}

.closePopup {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 15px;
  opacity: 0.5;
  cursor: pointer;
}
.closePopup:hover {
  opacity: 1;
}/*# sourceMappingURL=Home.css.map */