.blurScreen{
    user-select: none;
    position:absolute;
    color:transparent;
    top:0;
    z-index:100;
    width:100%;
    height:100%;
    backdrop-filter: blur(3px);
}

.blurScreen.set-color-blur{
    background-color: #E6E5EE;
}