/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.page-heading {
  font-weight: bold;
  font-size: 24px;
  display: block;
  padding-top: 10px;
}

.page-description {
  font-size: 20px;
  display: block;
  padding-top: 10px;
}

.selector {
  font-size: larger;
}

.return-page-container {
  position: relative;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.return-page-container.loading-home-container {
  height: 100vh;
}

.welcome-div {
  margin-left: 300px;
}
.welcome-div.dark {
  color: #f2f2f7;
}
.welcome-div.light {
  color: #34353f;
}

.homeTopbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  z-index: 80;
  margin: 0px auto;
  top: 0px;
}
.homeTopbar.light {
  background-color: #f2f2f7;
}
.homeTopbar.dark {
  background-color: #34353f;
}

.nextPrev {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 40px;
}

.nextprev-button {
  height: 30px;
  width: 30px;
  border-radius: 5px;
}
.nextprev-button:hover.dark {
  background-color: #4e4e58;
}
.nextprev-button:hover.light {
  background-color: #BAB6F5;
}

.prev {
  cursor: pointer;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.next-img {
  margin: 0px auto;
  height: 20px;
  width: 20px;
}

.prev-img {
  margin: 0px auto;
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
}

.next {
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-greeting {
  font-size: 38px;
  padding: 0px;
  margin-bottom: -30px;
}

.home-subtitle {
  font-size: 30px;
  font-weight: lighter;
}

.welcome {
  padding: 16px;
  padding-bottom: 0px;
  padding-left: 50px;
  text-align: left;
}
.welcome .home-container {
  margin-top: 20px;
  text-align: left;
  padding-top: 20px;
}
.welcome .home-container .renderDocsArray {
  margin-top: 10px;
}
.welcome .img-newpdf {
  box-shadow: none;
  background-color: transparent;
}

.skeleton {
  opacity: 0.5;
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
          animation: skeleton-loading 1s linear infinite alternate;
  border-radius: 1rem;
}

.skeleton-rect {
  opacity: 0.5;
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
          animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-noani {
  opacity: 1;
  background-color: hsl(200deg, 20%, 85%);
}

.skeleton-text {
  width: 100%;
  height: 1rem;
  margin-bottom: 0.25rem;
  border-radius: 0.25rem;
}

@-webkit-keyframes skeleton-loading {
  0% {
    background-color: hsl(200deg, 20%, 70%);
  }
  100% {
    background-color: hsl(200deg, 20%, 85%);
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200deg, 20%, 70%);
  }
  100% {
    background-color: hsl(200deg, 20%, 85%);
  }
}
.bug-note {
  text-align: left;
  font-size: 12px;
  padding: 10px;
  margin-left: 10px;
}

.hide {
  display: none;
}

.sidebar-home {
  -webkit-backdrop-filter: blur(24px);
          backdrop-filter: blur(24px);
  margin: 10px;
  width: 300px;
  border-radius: 10px;
  position: fixed;
  height: 90%;
  padding-top: 50px;
  left: 0;
  align-items: left;
  text-align: left;
}
.sidebar-home .greeting-container {
  margin-left: 14px;
  text-align: left;
  font-size: 18px;
}
.sidebar-home .greeting-container.dark {
  color: #f2f2f7;
}
.sidebar-home .greeting-container.light {
  color: #313941;
}
.sidebar-home .sidebar-logo {
  width: 50%;
  background-color: transparent;
  display: block;
  margin: 0 auto;
}
.sidebar-home .active-sidebar {
  font-weight: bold;
}
.sidebar-home.light {
  background-color: #e6e5ee;
}
.sidebar-home.light .active-sidebar {
  background-color: #BAB6F5;
}
.sidebar-home.dark {
  background-color: #212231;
}
.sidebar-home.dark .active-sidebar {
  background-color: #4e4e58;
}
.sidebar-home .search-container {
  border-radius: 5px;
  margin-bottom: 20px;
}
.sidebar-home .search-container.light {
  background-color: #f2f2f7;
}
.sidebar-home .search-container.dark {
  background-color: #34353f;
}
.sidebar-home .search-container.focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
}
.sidebar-home .search-sidebar {
  width: 100%;
  padding: 4px;
  padding-left: 5px;
  border: none;
}
.sidebar-home .search-sidebar.light {
  background: url("../../icons/search_light.png") no-repeat scroll 7px 7px;
  padding-left: 30px;
  color: #313941;
}
.sidebar-home .search-sidebar.light::-moz-placeholder {
  color: #313941;
}
.sidebar-home .search-sidebar.light:-ms-input-placeholder {
  color: #313941;
}
.sidebar-home .search-sidebar.light::placeholder {
  color: #313941;
}
.sidebar-home .search-sidebar.dark {
  background: url("../../icons/search_dark.png") no-repeat scroll 7px 7px;
  padding-left: 30px;
}
.sidebar-home .search-sidebar:focus {
  border: none;
}
.sidebar-home .search-sidebar::-moz-placeholder {
  color: rgb(221, 220, 220);
}
.sidebar-home .search-sidebar:-ms-input-placeholder {
  color: rgb(221, 220, 220);
}
.sidebar-home .search-sidebar::placeholder {
  color: rgb(221, 220, 220);
}

.sidebar-pages {
  margin-top: 40px;
}

.icon-enclosure {
  margin-bottom: 10px;
  padding: 5px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.icon-enclosure .sidebarLabel {
  font-size: 16px;
  display: inline;
  vertical-align: middle;
  padding-left: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.icon-enclosure .sidebarLabel.dark {
  color: #f2f2f7;
}
.icon-enclosure .sidebarLabel.light {
  color: #313941;
}
.icon-enclosure .sidebarIcon {
  width: 31.2px;
  height: 31.2px;
  vertical-align: middle;
}

.prefs-container {
  text-align: left;
  padding: 40px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.prefs-container .header-line {
  margin-top: -7px;
  margin-bottom: 25px;
}
.prefs-container.light {
  color: #34353f;
}
.prefs-container.light .header-line {
  border: 1px solid #34353f;
}
.prefs-container.dark {
  color: #f2f2f7;
}
.prefs-container.dark .header-line {
  border: 1px solid rgba(242, 242, 247, 0.4588235294);
}
.prefs-container h3 {
  font-size: 40px;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 30px;
}
.prefs-container h4 {
  font-size: 18px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0px;
}
.prefs-container .theme-container {
  display: inline-block;
  text-align: left;
  font-weight: bold;
  border: 0.25px solid rgba(128, 128, 128, 0.384);
  margin-right: 25px;
  border-radius: 10px;
  cursor: pointer;
}
.prefs-container .theme-container p {
  margin: 3px;
  display: inline;
}
.prefs-container .theme-container .radio {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid black;
  margin: 5px;
  margin-bottom: 7px;
  background-color: white;
}
.prefs-container .theme-container .active-theme {
  background-color: #665AEF;
}
.prefs-container .theme-container .theme-sel {
  vertical-align: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 0.25px solid rgba(128, 128, 128, 0.384);
}
.prefs-container .theme-container .theme-sel-app {
  width: 300px;
  height: 205px;
}
.prefs-container .theme-container .theme-sel-reader {
  width: 145px;
  height: 204px;
}

.task-status-container {
  position: fixed;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  left: 50px;
  bottom: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}
.task-status-container.light {
  background-color: #f2f2f7;
  color: #34353f;
}
.task-status-container.light .img-close-taskstatus:hover {
  background-color: #D8D5FA;
}
.task-status-container.dark {
  background-color: #34353f;
  color: #f2f2f7;
}
.task-status-container.dark .img-close-taskstatus:hover {
  background-color: #4e4e58;
}
.task-status-container .taskstatus-subheader {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-right: 25px;
  font-size: 16px;
}
.task-status-container .img-close-taskstatus {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 10px;
  padding: 3px;
  right: 7px;
  border-radius: 50%;
}
.task-status-container .fileLabel {
  font-size: 14px;
  width: 230px;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
}
.task-status-container .progress-p {
  font-size: 14px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 0px;
  margin-bottom: 3px;
}
.task-status-container .loadingStatus-img {
  width: 12px;
  height: 12px;
}
.task-status-container .loadingStatus-open {
  color: rgb(180, 180, 81);
  font-weight: bold;
}
.task-status-container .loadingStatus-open:hover {
  cursor: pointer;
}

.accounts-sidebar {
  position: absolute;
  bottom: 10px;
  width: 100%;
}
.accounts-sidebar .right.light {
  color: #313941;
}
.accounts-sidebar .right.dark {
  color: #f2f2f7;
}
.accounts-sidebar .profile-sidebar {
  padding: 10px;
  bottom: 0px;
  cursor: pointer;
  /* Clear floats after the columns */
}
.accounts-sidebar .profile-sidebar .row {
  display: flex;
  align-items: center;
}
.accounts-sidebar .profile-sidebar .sidebar-initials {
  padding: 5px;
  border-radius: 5px;
}
.accounts-sidebar .profile-sidebar.dark {
  background-color: #444758;
}
.accounts-sidebar .profile-sidebar.dark .sidebar-initials {
  color: #f2f2f7;
  background-color: #4e4e58;
}
.accounts-sidebar .profile-sidebar.light {
  background-color: #D8D5FA;
}
.accounts-sidebar .profile-sidebar.light .sidebar-initials {
  color: #34353f;
  background-color: #e6e5ee;
}
.accounts-sidebar .profile-sidebar .column {
  float: left;
  padding: 10px;
}
.accounts-sidebar .profile-sidebar .column p {
  margin: 0 auto;
  font-size: 12px;
}
.accounts-sidebar .profile-sidebar .column p.account-name {
  font-weight: bold;
}
.accounts-sidebar .profile-sidebar .left {
  width: 10%;
  margin-top: 4px;
  height: 100%;
}
.accounts-sidebar .profile-sidebar .row:after {
  content: "";
  display: table;
  clear: both;
}

.popup-container {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 80px;
  z-index: 200;
  /* Center vertically and horizontally */
  left: 50%;
  transform: translate(-50%, 0%);
}
.popup-container .popup-homescreen {
  background-color: rgb(175, 2, 2);
}

p.popup {
  color: white;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

p.popup-warning {
  background-color: rgba(180, 4, 4, 0.801);
  padding-right: 40px;
  padding-left: 40px;
}

.refresh-btn {
  cursor: pointer;
  position: absolute;
  top: 26px;
  right: 40px;
}
.refresh-btn.dark {
  background-color: #34353f;
}
.refresh-btn.light {
  background-color: #f2f2f7;
}

.download-enclosure {
  margin-bottom: 20px;
}

.refresh-icon {
  height: 30px;
  width: 30px;
}

.home-sort {
  margin: 15px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  font-weight: bold;
}
.home-sort .options {
  display: inline;
  margin-left: 10px;
  border-radius: 5px;
  padding: 7px;
}
.home-sort .options.dark {
  background-color: #212231;
}
.home-sort .options.light {
  background-color: #e6e5ee;
}
.home-sort .options .option {
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.home-sort .options .option.dark {
  color: #f2f2f7;
}
.home-sort .options .option.light {
  color: #313941;
}
.home-sort .options .option.active.dark {
  background-color: #4e4e58;
}
.home-sort .options .option.active.light {
  background-color: #BAB6F5;
}

.home-loading {
  width: 80px;
  height: 80px;
  top: 50%;
  transform: translateY(-40px);
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
}

.empty-tag-msg {
  margin: 0 auto;
  margin-top: 20px;
}

.drag-message-container {
  position: fixed;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.195);
  bottom: 20px;
  left: calc(50% - 30px);
}
.drag-message-container.light {
  background-color: #BAB6F5;
}
.drag-message-container.dark {
  background-color: #4e4e58;
}

@media screen and (max-width: 1250px) {
  .nextPrev {
    display: none;
  }
}
@media screen and (max-width: 1150px) {
  .homeTopbar {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0px auto;
  }
  .nextPrev {
    display: none;
  }
  .home-sort {
    margin-left: 100px;
  }
}
@media screen and (max-width: 960px) {
  .homeTopbar {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0px auto;
  }
  .home-sort {
    display: none;
  }
}
.new-banner {
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
}
.new-banner.dark {
  background-color: rgb(238, 205, 143);
  color: #000;
}
.new-banner.light {
  background-color: rgb(217, 183, 120);
}

.download-link {
  margin-left: 10px;
  text-decoration: none;
  cursor: pointer;
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  transition: 0.2s;
}
.download-link.dark {
  color: white;
}
.download-link.light {
  color: #313941;
}/*# sourceMappingURL=HomeScreen.css.map */