@import '../../styles/variables';
$color: white;
$containerWidth: 180px;

.doc-box-container{
    box-shadow: $boxShadow;
    border-radius: 5px;
    &.dark{
        background-color: $darkClipboardColor ;
        &:after{
            border-right: 10px solid $darkClipboardColor ;
        }
    }
    &.light{
        background-color: $clipboardColor;
        &:after{
            border-right: 10px solid $clipboardColor ;
        }
    }
    padding: 10px;
    width: $containerWidth;
    text-align: left;
    hr{
        margin-bottom:10px;
        margin-top: 10px;

    }

    &:after{
        content: " ";
        position: absolute;
        left: -10px;
        top: 40%;
        border-top: 10px solid transparent;
        border-left: none;
        border-bottom: 10px solid transparent;
        // box-shadow: $boxShadow;
    }

    .kitab-input{
        width: 100%
    }

    .delete-message{
        
        &.dark{
            color: $darkDesklampRed
        }
        &.light{
            color: $lightDesklampRed
        }
    }
}

.tag-suggestions-dropdown{
    position: absolute;
    &.dark{
        background-color: $darkDesklampGrey;
    }
    &.light{
        background-color: $desklampGrey;
    }
    

    .tag-suggestion{
        padding: 5px;

        &:hover{
            cursor: pointer;
            &.dark{
                background-color: $darkActiveButtonColor;
            }
            &.light{
                background-color: $activeButtonColor;
            }
            
            
        }
    }
}

.docbox-entry{
    // margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 0px;
    cursor: pointer;
    // font-weight: ;
    .docbox-icon{
        width: 16px;
        height: 16px;
        // border: 1px solid green;
    }

    .docbox-label{
        display: inline;
        padding: 0px;
        margin: 5px;
        margin-left: 10px;

        // border: 1px solid green;

    }
}

.DocBox-back{
    margin: 0 ;
    margin-bottom:5px;
    cursor: pointer;
    width: fit-content;
    padding: 5px;
    font-weight: bold;
    
}

.tag-input{
    width:130px;
    border-radius: 5px;
    padding: 5px;
    &.dark{
        background-color: $darkTextboxColor;
        color: $readerBgColor;
    }
  
    &.light{
        background-color: $textboxColor;
        color: $darkReaderBgColor;
    }
}

.tags-container{
    font-size: 14px;
}

.submit-tag{
    font-weight: bold;
    cursor: pointer;
    margin-left:5px;
    padding: 5px;
    border-radius: 5px;
    color:white;
    background-color: $desklampButtonBg;

}

.tag-label{

    &.dark{
        background-color: $darkHover;
    }
    &.light{
        background-color: $lightHover;
    }
    width: fit-content;
    padding: 3px;
    margin: 3px;
    display: inline-block;
    border-radius: 4px;

    .delete-tag{
        width: 8px;
        height: 8px;
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 4px;
        cursor: pointer;
        // padding: 2px;
    }
}