/*Simple css to style it like a toggle switch*/

@import '../../styles/variables';

$iconSize: 24px;
$iconMargin:6px;
$heightPadding: 10px;

.slider-menu-container {
    display: inline-flex;
    align-items: center;
  
    em {
      margin-left: 10px;
      font-size: 1rem;
    }
  }

  .option-switch {
    display: inline-block;
    position: relative;
  }
  
  
  .slider-bar {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .4s, visibility 0s, background-color 0s;
    border-radius:5px;
    // border:1px solid green;
  }
  
  .slider-bar:before {
    // bottom: 4px;
    content: "";
    height: $iconSize+$heightPadding;
    width:$iconSize+$heightPadding;
    // left: 4px;
    position: absolute;
    transition: all .4s, visibility 0s, background-color 0s;

    transform: translateX(var(--bevelPos));
    border-radius: 5px;

    
  }
  .slider-bar.dark:before{
    //the slider bevel (dark)
    background-color: $darkTextboxColor;
  }
  .slider-bar.light:before{
    //the slider bevel (light)
    background-color: $lightHover;
  }

.slider-bar{
    //the track 
    &.dark{
      &.sliderstyle-menu{
        background-color: $darkClipboardColor;
      }
      &.sliderstyle-pagenav{
        background-color:$darkReaderBgColor
      }
    }
    &.light{
      &.sliderstyle-menu{
        background-color: transparentize($clipboardColor, 0.3);
      }
      &.sliderstyle-pagenav{
        background-color:$readerBgColor
      }

    }
}
  
  .slider-bar.bevel {
    border-radius: 5px;
    display: flex;
    align-items: center;
    // border:1px solid lightgreen;

  }
  .slider-img{
      position:relative;
      height: $iconSize;
      width:$iconSize;
      margin-right:$iconMargin ;
      margin-left:$iconMargin ;
    //   border:1px solid yellow;

  }
  .icons-container{
      // border:1px solid pink;
      margin:0 auto;
      display: flex;
      align-items: center;
    
  }
  