/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
input[type=number] {
  -moz-appearance: textfield;
}

.pagenav-input.dark {
  color: white;
}
.pagenav-input.light {
  color: black;
}

.pagenav-container {
  border-radius: 7px;
  padding: 1px;
  height: 28px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
}

.sliderMenu-wrapper-pageNav {
  bottom: 10px;
  position: fixed;
  margin-left: 150px;
  padding-right: 5px;
  cursor: pointer;
  right: 20px;
}

.long-pagenav {
  width: 150px;
}

.pagenav-inner-container {
  display: inline-block;
}

.offset-menu-btn {
  display: inline-block;
  height: 10px;
  cursor: pointer;
  border-radius: 10%;
}

.sliderMenu-wrapper-pageNav {
  display: inline;
}

.vertical-rule {
  margin-left: 4px;
  border-right: 1px solid rgb(206, 205, 205);
  margin-top: -10px;
}

.page-navigator {
  font-size: 14px;
}
.page-navigator .popup1-container {
  z-index: 1;
  position: absolute;
  width: auto;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  left: -20px;
  padding-left: 15px;
  padding: 10px;
  bottom: 50px;
  background-color: #f2f2f7;
  color: rgb(44, 44, 44);
  cursor: pointer;
  transition: transform 0.5s;
}
.page-navigator .popup2-container {
  z-index: 2;
  position: absolute;
  width: 180px;
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  left: -13px;
  padding-left: 15px;
  bottom: 50px;
  background-color: #f2f2f7;
  color: rgb(44, 44, 44);
}
.page-navigator .popup2-container h3 {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0px;
}
.page-navigator .popup2-container .input-popup2 {
  background-color: white;
  width: 100%;
  border-radius: 5px;
  margin-top: 8px;
  color: rgb(36, 36, 36);
  text-align: left;
}

input {
  width: 30px;
  border: none;
  outline: none;
  text-align: center;
  background-color: transparent;
  font-family: "PT_Sans";
  text-align: left;
  font-size: 14px;
}

.pagenav-dropdown {
  padding: 10px;
  padding-bottom: 5px;
}
.pagenav-dropdown .pagenav-input {
  padding: 3px;
  padding-right: 5px;
  padding-left: 5px;
  width: 90%;
  background: #444758;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.pagenav-dropdown p {
  margin-bottom: 5px;
  margin-top: 5px;
}
.pagenav-dropdown.dark {
  color: white;
}
.pagenav-dropdown.light {
  color: black;
}

.input-pagenum {
  border-radius: 5px;
  margin-left: 4px;
  height: 20px;
}
.input-pagenum.dark {
  background-color: #4e4e58;
}
.input-pagenum.light {
  background-color: #E1D7FE;
}

.input-totpages {
  margin-left: 3px;
  margin-right: 3px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dark.btn_hideClipboard {
  background-color: #34353f;
}
.dark.btn_showClipboard {
  background-color: #212231;
}
.dark.pagenav-container {
  background-color: #34353f;
  color: #f2f2f7;
}
.dark.input-pagenum {
  background-color: #444758;
  color: #f2f2f7;
}
.dark.input-totpages {
  color: #f2f2f7;
}
.dark.offset-menu-btn {
  filter: invert(0.85);
}
.dark.popup1-container {
  color: #f2f2f7;
  background-color: #34353f;
}
.dark.popup2-container {
  color: #f2f2f7;
  background-color: #34353f;
}/*# sourceMappingURL=PageNavigator.css.map */