@import "../../../styles/variables"; 


.notes-toolbar.inline{

    zoom: 0.8;
    -moz-transform: scale(0.8);

}





@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
}

.notebook-toolbar-button {
  // padding-top: 3px;
  margin: 3px;
  width:22px;
  height:22px;
  // FIX CENTERING WITH SOME OTHER METHOD    
  position: relative;
  // top: 50%;
  // transform: translate(0, -50%);
  padding: 3px;
  border-radius: 4px;
  transition: 0s;
  &.inactive-nb-button{
    &.light{
      &:hover{
        background-color:$textboxColor;
      }
    }
    &.dark{
      &:hover{
        background-color:$darkHover;
      }
    }
  }
  
  &.hidden{
    display: none;
  }
}

.active-nb-button{
  // transition: $transitiontime;
  &.light{
    background-color: $activeButtonColor;
      
  }
  &.dark{
    background-color: $darkActiveButtonColor;
  }
}

.dropbtn {
  width: 20px;
  min-width: 92px;
  // padding: 16px;
  // font-size: 16px;
  display: inline-block;
  border-radius: 2px;
  padding-left:5px;
  text-align: center;
  // transition: background-color $transitiontime;
  padding-right:5px;
  &.light{
    background-color: $textboxColor;
    color:$darkReaderBgColor
  }
  &.dark{
    background-color: $darkHover;
    color:$readerBgColor
  }


}




.dropdown-content {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  display: none;
  position: absolute;
  min-width: 50px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 20;
  border-radius: 4px;
  padding-top:10px;
  padding-bottom:10px;
  top:30px;
  width: fit-content;
  &.light{
    background-color: $readerBgColor;
  }
  &.dark{
    background-color: $darkReaderBgColor;
  }
}

.nb-main-toolbar {
  display: flex;
  overflow-x: auto;
  // Shitty hack because overflow-x as auto sets overflow-y as auto
  // and that cannot be reset to visible: 
  // SO: https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
  padding-bottom: 250px;
  margin-bottom: -250px;
}

.nb-main-toolbar::-webkit-scrollbar{
  display: none;
}



.activeColorButton {
  width:22px;
  cursor: pointer;
}

.circle-button{
  cursor: pointer;
}

.notebook-toolbar-button-container{
  display: flex;
  justify-content: center;
}




.basedropdown {
  // left:calc(0px - 100% - 10px);
  animation-name: popup;
  animation-duration: 0.1s;
  top: 30px;
  padding: 0;
  // display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

.dropdown-arrow-icon {
  height: 5px;
  width: 5px;
  float:right;
  cursor: pointer;
}

.dropdown-menu-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.dropdown-entry-text{
  font-size: 15px;
  /* width: calc(100% - 20px); */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  white-space: nowrap; 
  display: flex;
  align-items: center;
  margin: auto;
  &.light{
    color:$darkReaderBgColor;
    &:hover{
        background-color:$lightHover;
    }

  }
  &.dark{
      color:$readerBgColor;
      &:hover{
          background-color:$darkHover
      }

  }
}

.color-dropdown-content{
  animation-name: popup;
  animation-duration: 0.1s;
}

.color-dropdown {

  position: relative;
  display: inline-block;
  padding:0px;
  user-select: none;
}

.base-button{
    transform: none;
}

.base-button-span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.notes-toolbar {
  padding-left: 10px;
  padding-right: 10px;
  height: inherit;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  width: max-content;
  border-radius: 5px;
  position: relative;
  // position:fixed;
  
  // transition: $transitiontime;
  // transform: translateX(-52%);
  z-index:6;

  margin: auto;

  &.light{
    background-color: $readerBgColor;
  }

  &.dark{
    background-color: $darkReaderBgColor;
  }

}