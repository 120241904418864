@import "../../styles/variables";


$toolbarColor:#0071BC;

.signup-topbar{
    height: $signupBarHeight;
    padding: $signupBarPadding;
    font-weight: bold;
    text-align: center;
    &.dark{
        background-color: $menuBgColor;
        color: white;
        .signin-button{
            color: $clipboardColor;
            border: 1px solid $clipboardColor;
            &:hover{
                color: $darkClipboardColor
            }
        }
       
    }

    &.light{
        background-color: $ribbonColor;
        .signin-button{
            color: $darkClipboardColor;
            border: 1px solid $darkClipboardColor;
            &:hover{
                background-color: $menuBgColor;
                color: $clipboardColor;
                border: 1px solid $menuBgColor;
            }
        }
    }

    .signin-button{
        margin-left: 10px;
        padding: 3px;
        border-radius: 3px;
        font-size: 14px;
        cursor: pointer;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
        transition: 0.3s;
        
        &:hover{
            background-color: white;
            border: 1px solid white;
            color: $menuBgColor
        }
        text-decoration: none;
    }
}

.app-reader-container{
    // display:flex;
    // flex-direction: column;
    // border:5px solid green;
    &.user{
        height:calc(100vh - 40px);
    }
    &.no-user{
        height:calc(100vh - 40px - $signupBarHeight - 2*$signupBarPadding);
    }
    &.electron{

        height:calc(100vh - 110px);

        &.MacOS{
            height:calc(100vh - 80px);
        }

        &.noFile {
            height: calc(100vh - 80px);
        }
    }
    
    // overflow-x: hidden;
}

.app-row{
    height: 100%;
    position: relative;
}

.column{
    float:left;
    position: relative;

    // border:2px solid green;
}



/* Clear floats */
.app-row:after {
    content: "";
    display: table;
     clear: both;
  }

.toolbar-ul{
    list-style:none;
    padding-left:0;
}

.toolbar-loader {
    // transition: background-color $transitiontime;
    width:$toolbarWidth;
    z-index:8;
    height: 100%;
    vertical-align: middle;
    justify-content: center;
    display: flex;



    &.dark{
        background-color: $darkReaderBgColor;   
    }
    &.light{
        background-color: $readerBgColor
    }
}

.toolbar-footer{
    bottom:15px;
    margin-bottom: $topmenuHeight + 30;
    position:absolute;
    // transform: translateY(300px);
    // transition: 0.3s;
}

.locationmarkers-loader{
    z-index: 7;
    width:fit-content;
    &.invert-locMarksLoader{
        right:0;
    }
}

.locationmarkers-loader::-webkit-scrollbar {
    display: none;
  }

.findbar-loader{
    z-index:8;
    margin-top:15px;
    margin-left:10px;
    right:10px;
    position:fixed
}

.clipboard-loader{
    left:$toolbarWidth;
    position: fixed;
    overflow: auto;
    width:100%;
    height: 100%;
}
.clipboard-loader::-webkit-scrollbar {
    display: none;
  }

.pagenav-loader {
    z-index:8;
    color:black;
    /* left:66vw; */
    /* width:26vw; FIX THIS DYNAMICALLY */
    bottom:0px;
    position:fixed;
    width:150px;
    overflow:none;
    height:auto;
    &.pagenav-fullscreen{
        z-index: 9;
    }
}

.reader-loader {
    z-index:2;
    // left:$leftGap;
    // float:left;
    // position:fixed;
    overflow: none;
    height: 100%;
    // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.596);
    // -webkit-user-select: true;
    // display: flex;
    // justify-content: center;
    // text-align: center;
}

.separator-loader{
    height:100%;
    z-index: 1;
    background-color: transparent;
}

.pdf-container{
    position: relative;
}

.PDFReader{
    // transition: background-color $transitiontime;
    // direction: rtl;
    width:100%;
    height:100%;
    overflow:auto;
    background-color: var(--reader-bgcol) ;
    text-align: left;
    position: absolute;
}

.workspace-container{
    height: 100%;
    z-index: 2;
    // position: fixed;
    overflow-y: hidden;
    text-align: center;
    box-sizing: border-box;
    // border:1px solid black;
}

.NoteLayer{
    position:absolute; top: 0; left: 0;
    z-index:4; transition: background 0.3s; opacity:1; mix-blend-mode: normal;
}

.sliderMenu-wrapper-pageNav{
    &.hidden{
        visibility: hidden;
    }
    
}


.PDFReader::-webkit-scrollbar {
    height:8px;
    width:9px;
    
  }
  
  /* Track */
 .PDFReader::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  .PDFReader::-webkit-scrollbar-thumb {
    background: darkgrey; 
    width:2px;
    border-radius:4px;
  }

  ::-webkit-scrollbar-corner{
      background-color: transparent;
  }
  
  /* Handle on hover */
  .PDFReader::-webkit-scrollbar-thumb:hover {
    background:rgb(92, 92, 92); 
  }

.popup-container{
    width:100%;
    // height:20px;
    // background-color: yellow;
    position: absolute;
    top:50px;
    z-index:200;

}

p.popup{
    color:white;
    // font-weight: bold;
    // position: absolute;
    width:fit-content;
    margin:0 auto;
    padding:5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
}

p.popup-warning{
    background-color: rgba(180, 4, 4, 0.801);
    padding-right: 40px;
    padding-left: 40px;

}

p.popup-message{
    background-color: transparentize($menuBgColor, 0.1);
    padding-right: 40px;
    padding-left: 40px;
    // color: $darkReaderBgColor;
}

.span_toggleWorkspace{
    position: fixed;
    bottom:10px;
    cursor:pointer;
    z-index: 11;
    // &.span_showWorkspace{
    //     z-index: 7;
    // }
    // &.span_hideWorkspace{
    //     z-index: 2;
    // }

}

.btn_toggleWorkspace {
    width:17px;
    height:17px;
    padding:8px;
    margin-top:10px;
    bottom:9px;
    cursor: pointer;
    &.light{
        background-color: $readerBgColor;
        &.btn_showWorkspace{
            background-color: $clipboardColor;
        }
    }
    &.dark{
        background-color: $darkReaderBgColor;
        &.btn_showWorkspace{
            background-color: $darkClipboardColor;
        }
    }
  }
  

.btn_hideWorkspace {
    // position: absolute;
    // left:-13px;
    border-bottom-right-radius:10px;
    border-bottom-left-radius:10px;
    transform: rotate(-90deg);
  
  }
  
  .btn_showWorkspace{
    position:fixed;
    right:0px;
    border-bottom-left-radius:10px;
    border-bottom-right-radius:10px;
    transform: rotate(90deg);
  }

.workspace-header{
    font-size: 18px;
    display: inline;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0 auto;
    margin-top: 5px;

    &.dark{
        color: $clipboardColor;
        &.active{
            color: $ribbonColor;
        }
    }
    &.light{
        &.active{
            color: $menuBgColor;
        }
    }
}

.annotationsPane-loader{
    // z-index: 9;
}

.workspace-scroll::-webkit-scrollbar {
    height:8px;
    width:9px;
  }
  
  /* Track */
  .workspace-scroll::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  .workspace-scroll::-webkit-scrollbar-thumb {
    background: darkgrey; 
    width:2px;
    border-radius:4px;
  }
  
  /* Handle on hover */
  .workspace-scroll::-webkit-scrollbar-thumb:hover {
    background:rgb(92, 92, 92); 
  }
