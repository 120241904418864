/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.circle-progress.light {
  stroke: #665AEF;
}
.circle-progress.dark {
  stroke: #665AEF;
}

.circle-text {
  font-size: 3em;
  font-weight: bold;
  fill: #665AEF;
}/*# sourceMappingURL=CircularProgressBar.css.map */