$margin: 1em;

.full{
    margin: $margin 0;
    width: 100%;

}

.half{
    display: inline-block;
    width: calc(50% - $margin/2);

    // margin-right: $margin/2;
    &.right{
        margin-left:$margin/2; 
    }
    &.left{
        margin-right:$margin/2;
    }

    .button-container{
        margin-bottom: $margin;
    }

}