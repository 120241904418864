
@import "../../styles/variables";


$tbHeight: 20px;
$fontSize: 14px;


input[type=number] {
  -moz-appearance:textfield;
  // color: white;
}

.pagenav-input{
  &.dark{
    color: white;
  }
  &.light{
    color: black;
  }
}

.pagenav-container{
  border-radius:7px;
  padding:1px;
  height: $containerHeight;
  width:fit-content;
  display: flex;
  align-items: center;
  // color: white;
}


.sliderMenu-wrapper-pageNav{
  // margin-top:10px;
  bottom:10px;
  position: fixed;
  margin-left:150px;
  padding-right:5px;
  cursor: pointer;
  right:20px;
}


.long-pagenav{
  width:150px;
}

.pagenav-inner-container{
  display: inline-block;
}
.offset-menu-btn{
  display: inline-block;
  height:10px;
  cursor: pointer;
  border-radius: 10%;

}

.sliderMenu-wrapper-pageNav{
  display: inline;
}

.vertical-rule{
  // background-color: green;
  margin-left:4px;
  border-right:1px solid rgb(206, 205, 205);
  margin-top:-10px;
}


.page-navigator{
  font-size: $fontSize;
  // font-weight: bold;

  .popup1-container{
    z-index:1;
    position:absolute;
    width:auto;
    // padding:10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    left:-20px;
    padding-left:15px;
    padding: 10px;
    bottom:50px;
    background-color: $readerBgColor;
    color:rgb(44, 44, 44);
    cursor:pointer;
    transition: transform 0.5s;
  }

  .popup2-container{
    z-index:2;

    h3{
      font-size:14px;
      margin-bottom:5px;
      margin-top:0px;
    }
    position: absolute;
    width:180px;
    padding:10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    left:-13px;
    padding-left:15px;
    bottom:50px;
    background-color: $readerBgColor;
    color:rgb(44, 44, 44);

    .input-popup2{
      background-color: white;
      width:100%;
      border-radius:5px;
      margin-top:8px;
      color:rgb(36, 36, 36);
      text-align:left;
    }
  }
}




input {
  width:$inputWidth;
  border:none;
  outline:none;
  text-align:center;
  background-color: transparent;
  font-family: $globalFont;
  text-align:left;
  font-size: $fontSize;
  // font-weight: bold;
}

.pagenav-dropdown{
    padding:10px;
    .pagenav-input{
        padding:3px;
        padding-right: 5px;
        padding-left: 5px;
        // border: 5px;
        width: 90%;
        background: $darkTextboxColor;
        border-radius: 5px;
        margin-bottom: 5px;
        margin-top:5px;
    }
    p{
        margin-bottom:5px;
        margin-top:5px;
    }
    padding-bottom:5px;
    &.dark{
      color: white;
    }
    &.light{
      color: black;
    }
}

.input-pagenum{
  &.dark{
    background-color: $darkHover;
  }
  &.light{
    background-color: #E1D7FE;
  }
  // background-color: $darkHover;
  border-radius:5px;
  margin-left:4px;
  // margin-top:2px;
  height:$tbHeight;
}


.input-totpages{
  margin-left:3px;
  margin-right:3px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dark{
  &.btn_hideClipboard{
    // transition: background-color $transitiontime;
    background-color: $darkReaderBgColor;
  }
  &.btn_showClipboard{
    // transition: background-color $transitiontime;
    background-color: $darkClipboardColor;
  }

  &.pagenav-container{
    // transition: background-color $transitiontime;
    background-color: $darkReaderBgColor;
    color:$readerBgColor;
  }

  &.input-pagenum{
    // transition: background-color $transitiontime;
    background-color: $darkTextboxColor;
    color:$readerBgColor
  }

  &.input-totpages{
    // transition: background-color $transitiontime;
    color:$readerBgColor;
  }
  
  &.offset-menu-btn{
    // transition: background-color $transitiontime;
    filter: invert(0.85);
  }

  &.popup1-container{
    // transition: background-color $transitiontime;
    color:$readerBgColor;
    background-color: $darkReaderBgColor;
  }

  &.popup2-container{
    // transition: background-color $transitiontime;
    color:$readerBgColor;
    background-color: $darkReaderBgColor;
  }
}

