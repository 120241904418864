@import "../../styles/variables";


.notebook-menu-container{
    user-select: none;
    
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 10px;
    height: 100%;

    

    &.dark{
        color: white;
    }
}

.notebook-menu-header{

    // border: 1px solid green;
    height: 30px;
    margin-bottom: 20px;

    .add-new-nb{
        border-radius: 6px;
        padding: 2px;
        margin-left: 15px;
        padding-left: 5px;
        padding-right: 5px;
        display: inline;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        
        &.dark{
            background-color: $darkHover;
        }
        &.light{
            background-color: $lightHover;
        }
    
        .add-new-label{
            margin: 0 auto;
            display: inline;
            margin-right: 10px;
            font-size: 14px;
        }
    
        .add-new-icon{
            width: 12px;
            height: 12px;
        }
    }

}

.nb-list-container::-webkit-scrollbar {
    height:8px;
    width:9px;
  }
  
  /* Track */
  .nb-list-container::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  .nb-list-container::-webkit-scrollbar-thumb {
    background: darkgrey; 
    width:2px;
    border-radius:4px;
  }
  
  /* Handle on hover */
  .nb-list-container::-webkit-scrollbar-thumb:hover {
    background:rgb(92, 92, 92); 
  }


.nb-list-container{
    overflow-y: auto;
    height: calc(100% - 80px)
}

.notebook-tips-container{
    margin-top: 40px;
    font-style: italic;
    
}
.nb-item{
    text-align: left;
    
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;

    &.row{
        display: flex;

        .nb-item-column{
            display: flex;
            // justify-content: center;
            align-items: center;
            &:after{
                content: "";
                display: table;
                clear: both;    
            }
        }
    }

    &.light{
        background-color: $readerBgColor;
        &.public {
          background-color: #78BC61;        ;
        }
        &:hover{
            background-color: $lightHover;
        }
    }

    &.dark{
        background-color: $darkReaderBgColor;
        &.public {
          background-color: green;
        }
        &:hover{
            background-color: $darkHover;
        }
    }

    .nb-item-title{
        display: inline;
        width: 90%;
    }

    .nb-item-delete{
        float: right;
        &.dark{
            color: $darkDesklampRed
        }
        &.light{
            color: #EC4E20 ;
        }
        &:hover{
            font-weight: bold;
            cursor: pointer;

        }
    }

}
  

