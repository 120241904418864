/**
z-index values
toolbar-loader: 2
clipboard-loader:1
reader-loader:1
hover-for-topbar:3
topbar:5
**/
.PDFFindBar_component {
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.PDFFindBar_component.frozenTopbar {
  transform: translateY(40px);
}
.PDFFindBar_component button {
  margin-left: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
}
.PDFFindBar_component button.active-btn {
  background-color: #c2c3c5;
}
.PDFFindBar_component button.waste {
  display: none;
}
.PDFFindBar_component button.err-msg.dark {
  color: #ffa69e;
}
.PDFFindBar_component button.err-msg.light {
  color: #db5461;
}
.PDFFindBar_component .searchInput {
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 10px;
  width: 150px;
  text-align: left;
  height: 17px;
  border-radius: 4px;
}
.PDFFindBar_component img {
  height: 13px;
  width: 13px;
}
.PDFFindBar_component .findButtonImage {
  padding: 3px;
  top: 6px;
  position: relative;
  padding: 4px;
  border-radius: 50%;
  margin-right: 5px;
}
.PDFFindBar_component .closeFind {
  margin-right: 10px;
  padding: 3px;
  top: 4px;
  position: relative;
  border-radius: 50%;
}
.PDFFindBar_component .findNext {
  transform: rotate(0deg);
}
.PDFFindBar_component .findPrev {
  transform: rotate(180deg);
}
.PDFFindBar_component.light {
  background-color: #f2f2f7;
}
.PDFFindBar_component.light .searchInput {
  background-color: #D8D5FA;
}
.PDFFindBar_component.light button.non-button:hover {
  background-color: #f2f2f7;
}
.PDFFindBar_component.light .findButtonImage:hover {
  background-color: #BAB6F5;
}
.PDFFindBar_component.light .find-message {
  color: #34353f;
}
.PDFFindBar_component.light .err-msg {
  color: #db5461;
}
.PDFFindBar_component.dark {
  background-color: #34353f;
}
.PDFFindBar_component.dark .searchInput {
  background-color: #444758;
  color: white;
}
.PDFFindBar_component.dark button.non-button:hover {
  background-color: #34353f;
}
.PDFFindBar_component.dark ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.589);
}
.PDFFindBar_component.dark :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.589);
}
.PDFFindBar_component.dark ::placeholder {
  color: rgba(255, 255, 255, 0.589);
}
.PDFFindBar_component.dark .findButtonImage:hover {
  background-color: #4e4e58;
}
.PDFFindBar_component.dark .find-message {
  color: #f2f2f7;
}
.PDFFindBar_component.dark .err-msg {
  color: #ffa69e;
}/*# sourceMappingURL=PdfFinder.css.map */