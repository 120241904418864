@import '../../styles/variables';

.feedback-icon{
    border-radius: 50%;
    padding: 4px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border: 1px solid $desklampButtonBg;

    &.light{
        background-color: $textboxColor;
    }
    &.dark{
        background-color: $darkHover;
    }
    &.loaded{
        cursor: pointer;
    }
    &.loading{
        opacity: 50%;
    }
}

.frill-feedback-dropdown{
    // display: none;
    position: absolute;
    height:calc(100% - 40px); 
    width:420px; 
    right: 0px;
    top:40px; 
    z-index: 101;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transform: translateX(600px);
    transition: 0.5s;


}

.frill-feedback-dropdown.active{
    right: 15px; 
    transform: translateX(15px);


   
}

