@import "../../styles/variables";



.dropbtn-icon{
    height:15px;
    width:15px;
    float: right; 
    padding-top: 7px;
    padding-left: 5px;
 }

  .dropbtn-icon{
    & :hover{
      &.dark{
        background-color: $darkHover;
      }
      &.light{
        background-color: $lightHover;
      }
    }
  }
  
  
  
  /* container of all the dropdown options (Hidden by Default) */
  
  .editor-tool {
    background: $readerBgColor;
    filter:  var(--app-theme);
    padding: 5px;
    padding-left:20px;
    padding-right:20px;
    

    &.light{
      background-color: $readerBgColor;
      color:$darkReaderBgColor;
      &:hover{
        background-color: $lightHover;
      }
    }

    &.dark{
      background-color: $darkReaderBgColor;
      color:$readerBgColor;
      &:hover{
        background-color: $darkHover;
      }
    }
}

.dropdown {
    position: relative;
    display: flex;
    justify-content: center;
    font-family: 'PT_Sans';
    user-select: none;
    height: inherit;
    margin-right: 5px;
    margin-left: 5px;
  }

.dropdown h1.editor-tool, .dropdown h2.editor-tool, .dropdown h3.editor-tool, .dropdown h4.editor-tool, .dropdown h5.editor-tool{
  margin: 0;
}
